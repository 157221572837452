
    import { Vue, Options } from 'vue-class-component';
    import CardGrid from '@/lib/CardGrid/card_grid.vue';
    import service from "@/services/api";
    import NewLyricModal from '@/lib/Modals/new_lyric_modal.vue';
    import store from "@/stores";
    import Analytics from "@/services/analytics";
    import SidebySideLyricModal from '@/lib/Modals/sidebyside_lyric_modal.vue';
    import { ref } from 'vue';
    
    @Options({
        components: {
            CardGrid,
            NewLyricModal,
            SidebySideLyricModal
        },
    }) 
    export default class LyricsManager extends Vue {
        
    // translations
    public get actions_add_lyric () { return this.$t('actions.add_lyric') }

    showModal = false
    showSideBySideModal = false
    items = [];
    title = '';
    lyricSelected: any = {};
    sideBySideModal: any = ref(null)
    created() {
        Analytics.visit(this.$route);
    }

    async mounted() {
        this.items = JSON.parse(await service.fetchFromApi({ url: "adminmanager/getPendingLyrics" }));
        console.log(this.items)
        setTimeout(() => {
            store.isLoadingRoute = false;
            store.isLoading = false;
        }, 1000);
    }

    add_lyric() {
        this.showModal = true
    }
    onNewLyricModalClose() {
        this.showModal = false
    }
    async onItemSelected(event) {
        //this.$router.push({ path: `/lyrics/editor/${event.detail.id}`});
        // get the lyric verses and display
        this.lyricSelected = JSON.parse(await service.getFromApi("adminmanager/getPendingLyricById", 'id='+event.id ));
        console.log(this.lyricSelected)
        this.showSideBySideModal = true;
        this.sideBySideModal.parseVersesToTextarea(this.lyricSelected)
    }
    onSideBySideLyricModalClose() {
        this.showSideBySideModal = false
    }
}
