import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-724257b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white p-4 m-4 md:p-8 md:m-8 lg:p-24 lg:m-24 noselect" }
const _hoisted_2 = { class: "text-base md:text-lg mt-4" }
const _hoisted_3 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_4 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_5 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_6 = { class: "text-base md:text-lg mt-4" }
const _hoisted_7 = { class: "text-base md:text-sm" }
const _hoisted_8 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_9 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_10 = { class: "text-base md:text-sm" }
const _hoisted_11 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_12 = { class: "text-base md:text-lg mt-4" }
const _hoisted_13 = { class: "text-base md:text-lg mt-4" }
const _hoisted_14 = { class: "text-base md:text-lg mt-4" }
const _hoisted_15 = { class: "text-base md:text-lg mt-4" }
const _hoisted_16 = { class: "text-base md:text-lg mt-4" }
const _hoisted_17 = { class: "text-base md:text-sm mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclaimer = _resolveComponent("Disclaimer")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.section1_title), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.section1_pha1), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.section1_pha2), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.section1_pha3), 1),
      _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.section2_title), 1),
      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.section2_pha2), 1),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.section2_pha3), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.section2_pha4), 1),
      _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.section2_pha5), 1),
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.section2_pha6), 1),
      _createElementVNode("h1", _hoisted_12, _toDisplayString(_ctx.section3_title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section3_pha1), 1),
      _createElementVNode("h1", _hoisted_13, _toDisplayString(_ctx.section4_title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section4_pha1), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section4_pha2), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section4_pha3), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section4_pha4), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section4_pha5), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section4_pha6), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section4_pha7), 1),
      _createElementVNode("h1", _hoisted_14, _toDisplayString(_ctx.section5_title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section5_pha1), 1),
      _createElementVNode("h1", _hoisted_15, _toDisplayString(_ctx.section6_title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section6_pha1), 1),
      _createElementVNode("h1", _hoisted_16, _toDisplayString(_ctx.section7_title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section7_pha1), 1),
      _createElementVNode("h2", _hoisted_17, _toDisplayString(_ctx.section8_title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.section8_pha1), 1),
      _createVNode(_component_Disclaimer)
    ]),
    _createVNode(_component_Footer)
  ], 64))
}