
    import { Vue, Options } from 'vue-class-component';
	import store from "@/stores";
	import LogoCmp from '@/lib/SiteLayout/Logo/logo.vue';

	@Options({
        components: {
            LogoCmp
        },
    })
    export default class LoadingScreen extends Vue {

		public get isLoadingMessage () { return store.loaderMessage }

	}
