import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto about-section" }
const _hoisted_2 = { class: "bg-white p-4 m-4 md:p-8 md:m-8 lg:p-24 lg:m-24" }
const _hoisted_3 = { class: "flex flex-wrap flex-row items-center justify-center cursor-pointer" }
const _hoisted_4 = { class: "text-xs lg:text-base rounded-full p-2" }
const _hoisted_5 = { class: "flex justify-center items-center p-4" }
const _hoisted_6 = {
  class: "w-full max-w-xs flex flex-col py-5 px-8",
  action: ""
}
const _hoisted_7 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}
const _hoisted_8 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclaimer = _resolveComponent("Disclaimer")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.labels_individual), 1),
          _createElementVNode("div", {
            class: "text-xs lg:text-base rounded-full p-2",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.joinclass && _ctx.joinclass(...args)))
          }, _toDisplayString(_ctx.labels_code), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.labels_username), 1),
            _withDirectives(_createElementVNode("input", {
              class: "text-gray-700 shadow border rounded border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3",
              type: "text",
              placeholder: "Username",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.datasignin.username) = $event))
            }, null, 512), [
              [_vModelText, _ctx.datasignin.username]
            ]),
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.labels_password), 1),
            _withDirectives(_createElementVNode("input", {
              class: "text-gray-700 shadow border rounded border-gray-300 mb-3 py-1 px-3 focus:outline-none focus:shadow-outline",
              type: "password",
              placeholder: "********",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.datasignin.password) = $event))
            }, null, 512), [
              [_vModelText, _ctx.datasignin.password]
            ]),
            _createElementVNode("button", {
              class: "btn navbar-toggler link-medium mt-4 mb-2",
              onClick: _cache[3] || (_cache[3] = async ()=> await _ctx.signin())
            }, _toDisplayString(_ctx.labels_signin), 1),
            _createElementVNode("small", {
              class: "text-blue-600 hover:text-blue-800 font-bold cursor-pointer",
              href: "#",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.forgot && _ctx.forgot(...args)))
            }, _toDisplayString(_ctx.labels_forgotpassword), 1)
          ])
        ])
      ]),
      _createVNode(_component_Disclaimer)
    ]),
    _createVNode(_component_Footer)
  ], 64))
}