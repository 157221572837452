
    import { Options, Vue } from 'vue-class-component';
    import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
    import "swiper/swiper.scss";

    @Options({
        props: {
            items: Array
        },
        components: {
            Swiper, SwiperSlide
        },
        emits: ['selected']
    })
    export default class SliderCards extends Vue {

        items: any[] = [];

        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints={
            // when window width is >= 320px
            320: {
                slidesPerView: 3,
                spaceBetween: 8
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 4,
                spaceBetween: 10
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 5,
                spaceBetween: 10
            },
            1020: {
                slidesPerView: 7,
                spaceBetween: 10
            }
        }

        onChange (swiper) {
            console.log(".activeIndex: " + swiper.activeIndex);
            this.$emit('onupdate', swiper.activeIndex);
        } 

        goToLyric(item, i) {
            this.items.forEach(el => el.isSelected = false);
            item.isSelected = true;
            this.$emit('selected', i);
        }
    }
