
    import { Vue, Options } from 'vue-class-component';

    @Options({
        props: {
            results: Object
        },
        emits: ['typing', 'selected', 'onfocus']
    })
    export default class SearchBox extends Vue {
        
        results: any = {}
        showOptions: boolean = false
        searchValue: any = '';
        timer: any;
        isHovering = false;
        /* // translations
        public get labels_subject () { return this.$t('labels.subject') }
        public get labels_email () { return this.$t('labels.email') }
        public get labels_body () { return this.$t('labels.body') }
        public get actions_save () { return this.$t('actions.save') }
        public get messages_contact () { return this.$t('messages.contact_form') }
*/
        select(v) { 
            console.log(v)         
            if (v)
                this.$emit("selected", v);
        }
        debounce(v) {
            clearTimeout(this.timer);
            this.timer = setTimeout(async () => {
                this.searchValue = v;
                if (v)
                    this.$emit("typing", v);

            }, 750);
        }

        clickedOutside() {
            this.showOptions = false;
            this.$emit("onfocus", false);
        }
        goToBand(lyric) {
            console.log(lyric)
        }
        onFocus(f){
            if (f == true || (f == false && !this.isHovering)) {
                this.showOptions = f
                this.$emit("onfocus", f);
            }
        }
        hoveringSearchBox() {
            this.isHovering = true;
        }
    }
