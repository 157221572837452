// https://github.com/peterpolman/hidden-layers/blob/develop/src/store/index.ts
// https://github.com/smashingmagazine/SmashingBookcase/tree/SharedState
import apiService from "@/services/api";
import { reactive } from "vue";
import { TokenResponse } from "../models/TokenResponse";
import jwt_decode from "jwt-decode";
import { UserProfile } from "@/models/userProfile";
import EncUtils from '../services/encUtils';
import { DateTime } from 'luxon'

const isExpired = date =>
  DateTime.fromISO(date)
    .diffNow('minutes')
    .minutes <= 5; // expires in 5 minutes or less

class GlobalStorage {

    public isLoading: boolean = false;
    public isLoadingRoute: boolean = false;
    public loaderMessage: string = 'Loading...';
    public role: string = 'guest';
    public userData: UserProfile = new UserProfile();
    public securityData: TokenResponse = new TokenResponse();
    private rawData;
    public isSiteDown: boolean = true;

    constructor() {
        if (localStorage.ntmData) {
            this.rawData = localStorage.getItem('ntmData');

            if (this.rawData) {
                const decrypted = EncUtils.decryptUsingAES256(this.rawData);
                const data: TokenResponse = <TokenResponse>JSON.parse(decrypted);
                this.securityData = data;

                //console.log("INITIALIZED FROM LOCAL STORAGE " + this.rawData);
                //this.securityData.accessToken = data.accessToken;
            }
        }
    }
    public getEncryptedToken() {
        return EncUtils.encryptUsingAES256("Bearer " + this.securityData.accessToken);
    }
    public setNewToken(data) {
        this.rawData = data;
        this.securityData = data;
        localStorage.setItem("ntmData", JSON.stringify(this.securityData));
        this.decodeToken();
    }
  
    decodeToken() {
      const decoded: any = jwt_decode(this.securityData.accessToken);
        //console.log(decoded);
        if (decoded != null) {
            if (decoded["auth:groups"] && decoded["auth:groups"].length > 0) { // changed from cognito:groups to auth:groups
                if (decoded["auth:groups"].includes("subscribers")) {
                  //this.exp.setRole("subscriber");
                  this.role = "subscriber";
                } else if (decoded["auth:groups"].includes("contributors")) {
                  //this.exp.setRole("contributors");
                  this.role = "contributors";
                }
            } else {
              //this.exp.setRole("guest");
              this.role = "guest";
            }
        }
    }
    
    private saveStorage() {
        const encryptedQueryParams = EncUtils.encryptUsingAES256(JSON.stringify(this.securityData));
        localStorage.setItem("ntmData", encryptedQueryParams);
    }
    async getToken() {
        try {
            const tokens = <TokenResponse>JSON.parse(await apiService.getAuthToken({url: "security/guest"}));
  
            if (!tokens || !tokens.accessToken) {
                //console.log("NO TOKEN RETURNED BY API");
                return false;
            }
  
            this.securityData = tokens;
            this.saveStorage();
           /*  const encryptedQueryParams = EncUtils.encryptUsingAES256(JSON.stringify(this.securityData));
            localStorage.setItem("ntmData", encryptedQueryParams); */
            //this.token = tokens.data.accessToken;
            // save tokens to localStorage
            //this.exp.setData(tokens);
  
            this.decodeToken();
  
            return true;
        } catch(e) {
            //console.log("ERROR GETTING TOKEN..." + e);
            return false;
        }
    }
  
    async obtainToken() {
        let isTokenObtained = false;
  
        if (!this.securityData.accessToken) {
            //console.log("NO TOKEN");
            isTokenObtained = await this.getToken();
  
        } else {
            // check if already expired and get a new one
  
            if (!isExpired(this.securityData.expirationTime)) {
                //console.log("USING STORED TOKEN");
                isTokenObtained = true;
                this.decodeToken();
            } else {
                //console.log("TOKEN EXPIRED, GETTING A NEW ONE, using refresh " + this.securityData.refreshToken);
  
                if (!this.securityData.refreshToken) {
                    isTokenObtained = await this.getToken();
                } else {
                    isTokenObtained = await this.refreshToken();
                }
            }
        }
  
        return isTokenObtained;
    }
  
    async refreshToken() {
        //console.log("REFRESH:: " + refreshtoken);
        try {
            const tokens = await apiService.postToApi("security/refresh", {body: this.securityData.refreshToken});
  
            if (!tokens) {
                //console.log("REFRESH::NO TOKEN RETURNED BY API");
                return false;
            }
  
            // save tokens to localStorage
            //this.exp.setData(tokens);
            this.securityData = tokens.data;
            //localStorage.setItem("ntmData", JSON.stringify(this.securityData));
            this.saveStorage();

            this.decodeToken();
  
            return true;
        } catch(e) {
            //console.log("REFRESH::ERROR GETTING TOKEN..." + e);
            return false;
        }
    }
  
    async authenticationGuard(page)  {
        let isTokenObtained = false;
  
        // first time on the site, nothing on localstorage get token without refreshing
        isTokenObtained = await this.obtainToken();
  
        return isTokenObtained;
    }
  
    async loadHome() {
      try {
        //this.isBusy = true;
        const response = await apiService.getHome();
        if (response.status === 200) {
            //console.log(response);
          //if (response.data.length === 0) this.error = "No books returned";
          //this.books.splice(0, this.books.length, ...response.data.works);
        }
      } catch {
        //this.error = "Failed to load home";
      } finally {
        //this.isBusy = false;
      }
    }
  
    userIsWaitingNotifSiteOnline(pubIp){
        this.securityData.lastPubIp = pubIp;
        this.securityData.isUserWaiting = true;
        //localStorage.setItem("ntmData", JSON.stringify(this.securityData));
        this.saveStorage();
    }
    isUserWaiting() {
        return this.securityData.isUserWaiting;
    }
    /* geti18n(key): string {
        return t(key)
    } */
}
export default reactive(new GlobalStorage());
