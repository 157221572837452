import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35f4da02"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex-container",
  "class:full-mode": "{isFullMode}",
  "class:minimized-mode": "{!isFullMode}"
}
const _hoisted_2 = { class: "lyrics-section flex-item" }
const _hoisted_3 = { class: "audio-lyrics" }
const _hoisted_4 = {
  id: "ly-{i}",
  class: "lyric text-base sm:text-3xl md:text-4xl"
}
const _hoisted_5 = {
  key: 0,
  id: "ch-{lyric.id}-{character.id}",
  class: "character"
}
const _hoisted_6 = {
  key: 1,
  id: "ch-{lyric.id}-{character.id}",
  class: "character"
}
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id"]
const _hoisted_10 = {
  id: "translation-{lyric.id}",
  class: "trans-character lyric text-base sm:text-2xl md:text-3xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fg = _resolveComponent("fg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_ctx.lyrics && _ctx.lyrics.verses)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.lyrics.verses, (lyric) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: lyric.id
              }, [
                _createElementVNode("li", _hoisted_4, [
                  _createElementVNode("ul", null, [
                    (lyric.characters)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(lyric.characters, (character) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: character.id
                          }, [
                            (character.furigana)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (character.toComplete && character.toComplete == true)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                                        _createVNode(_component_fg, {
                                          id: 'fg-' + character.id,
                                          t: "**",
                                          class: "glowing"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(character.key), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["id"])
                                      ]))
                                    : (_openBlock(), _createElementBlock("li", _hoisted_6, [
                                        _createVNode(_component_fg, {
                                          id: 'fg-' + character.id,
                                          t: character.furigana
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(character.key), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["id", "t"])
                                      ]))
                                ], 64))
                              : (character.key == '')
                                ? (_openBlock(), _createElementBlock("li", {
                                    key: 1,
                                    id: 'ch-' + lyric.id + '-' + character.id,
                                    class: "character"
                                  }, " ", 8, _hoisted_7))
                                : (character.toComplete && character.toComplete == true)
                                  ? (_openBlock(), _createElementBlock("li", {
                                      key: 2,
                                      id: 'ch-' + lyric.id + '-' + character.id,
                                      class: "character"
                                    }, "**", 8, _hoisted_8))
                                  : (_openBlock(), _createElementBlock("li", {
                                      key: 3,
                                      id: 'ch-' + lyric.id + '-' + character.id,
                                      class: "character"
                                    }, _toDisplayString(character.key), 9, _hoisted_9))
                          ], 64))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("li", _hoisted_10, _toDisplayString(lyric.en), 1)
              ], 64))
            }), 128))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}