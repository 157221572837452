
    import { Vue, Options } from 'vue-class-component';

    interface options {
        id: number
        title: string
        key: string
        order: number
        totalKanji: number
        description: string
        extras: number
        level: string
        color: string
        thumbnail: string
    }

    @Options({
        prop: {
             options: Array
        },
        emits: ['onupdate']
    }) 
    export default class Slider extends Vue {

        // translations
        public get actions_karaoke () { return this.$t('actions.karaoke') }
        public get actions_veryeasy () { return this.$t('actions.veryeasy') }
        public get actions_easy () { return this.$t('actions.easy') }
        public get actions_difficult () { return this.$t('actions.difficult') }
        public get actions_hard () { return this.$t('actions.hard') }
        public get actions_challenging () { return this.$t('actions.challenging') }
        public get actions_veryhard () { return this.$t('actions.veryhard') }

        options: options[] = []
        currentSelection = 0; 

        prefs = ['webkit-slider-runnable-track', 'moz-range-track', 'ms-track'];

        mounted() {
            this.options = [
                { id: 0, title: this.actions_karaoke,     key: 'karaoke',  order: 6, totalKanji: 0, description: "", extras: 0, level: '',   color: '',         thumbnail: 'tic-tac-toe.png' },
                { id: 1, title: this.actions_veryeasy,    key: 'veryeasy',   order: 1, totalKanji: 0, description: "", extras: 0, level: 'kana', color: '',         thumbnail: 'tic-tac-toe.png' },
                { id: 2, title: this.actions_easy,        key: 'easy',     order: 2, totalKanji: 0, description: "", extras: 0, level: 'N5', color: '',         thumbnail: 'tic-tac-toe.png' },
                { id: 3, title: this.actions_difficult,   key: 'difficult',  order: 3, totalKanji: 0, description: "", extras: 0, level: 'N4', color: '',         thumbnail: 'tic-tac-toe.png' },
                { id: 4, title: this.actions_challenging,   key: 'challenging',  order: 4, totalKanji: 0, description: "", extras: 0, level: 'N3', color: '',         thumbnail: 'tic-tac-toe.png' },
                { id: 5, title: this.actions_hard,        key: 'hard',       order: 5, totalKanji: 0, description: "", extras: 0, level: 'N2', color: '',         thumbnail: 'tic-tac-toe.png' },
                { id: 6, title: this.actions_veryhard,    key: 'veryhard',    order: 6, totalKanji: 0, description: "", extras: 0, level: 'N1 + Jōyō', color: '',  thumbnail: 'tic-tac-toe.png' }
            ]
        }
        onChange () {
            this.$emit('onupdate', this.currentSelection);
        } 

        // Change input value on label click
        onLevelSelected (index) {  
            this.currentSelection = index;
        }
    }
