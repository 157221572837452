
    import { Vue, Options } from 'vue-class-component';

    @Options({
        prop: {
            correctTotal: Number,
            incorrectTotal: Number,
            total: Number,
            progress: Number
        },
        emits: ['onupdate']
    }) 
    export default class CountdownTimer extends Vue {


        correctTotal = 0; 
        incorrectTotal = 0;
        total = 0;
        progress = 0;

        mounted() {
         
        }
    }
