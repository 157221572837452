
	import { Vue, Options } from 'vue-class-component';
	import PublicHeader from './lib/SiteLayout/Header/public.vue';
	//import LoadingScreen from './lib/LoadingScreen/loading_screen.vue';
	import axios from "axios";
	import store from "@/stores";
	import Analytics from '@/services/analytics';
	
	const LOCAL_API_URL: string = process.env.VUE_APP_API_URL || '';

	@Options({
		components: {
			PublicHeader,
			//LoadingScreen
		},
	})
	export default class App extends Vue {

		public get isLoadingRoute () { return store.isLoadingRoute }

		created() {
			//axios.defaults.data.showLoader = true;

			// set the isLoading global state variable to show the spinner on api calls
			axios.interceptors.request.use(
				config => {
					/* if (config.showLoader) {
						store.dispatch('loader/pending');
					} */
					// only add the authorization token if is our backend api endpoint and we have a token
					if(config && config.headers && config.url?.indexOf(LOCAL_API_URL) !== -1 && store.securityData.accessToken) {
						//config.headers.authorization = "Bearer " + store.securityData.accessToken;
						config.headers.authorization = store.getEncryptedToken()
					}

					store.isLoading = true;

					//console.log("REQUEST PENDING " + JSON.stringify(config));
					return config;
				}, error => {
					/* if (error.config.showLoader) {
						store.dispatch('loader/done');
					} */
					store.isLoading = false;

					//console.log("ERROR REQUEST DONE");

					Analytics.exceptionEvt("axios request error");

					return Promise.reject(error);
				}
			);

			axios.interceptors.response.use(
				response => {
					/* if (response.config.showLoader) {
						store.dispatch('loader/done');
					} */
					store.isLoading = false;
					//console.log("RESPONSE DONE");

					return response;
				}, error => {
					//let response = error.response;

					/* if (response.config.showLoader) {
						store.dispatch('loader/done');
					} */
					store.isLoading = false;

					//console.log("ERROR RESPONSE DONE " + response);

					Analytics.exceptionEvt("axios response error");

					return Promise.reject(error);
				}
			)
		}

		mounted() {
			this.$router.push({ path: `/site-under-construction` });
		}
	}
