
    import { Options, Vue } from 'vue-class-component';
    
    @Options({
        props: {
            items: Array
        },
        emits: ['selected']
    })
    export default class CardGrid extends Vue {

        items: any[] = [];

        // translations
        public get label_no_results () { return this.$t('label.no_results') } // No hay resultados

        selectItem(item) {
            this.$emit('selected', item);
        }
    }
