import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component, route }) => [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: route.path
            }))
          ])
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }))
}