import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7df1cff6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "truncate col-span-2 text-center" }
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = { class: "step-num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wrapper absolute grid grid-cols-2 grid-rows-2 gap-1 opacity-0", {'transition-opacity duration-100 ease-in opacity-100': _ctx.showPopup}]),
    style: _normalizeStyle({'left': (_ctx.coords.left - 30) + 'px', 'top': _ctx.coords.bottom + 'px'})
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.meaning), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodes, (node, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: node.id,
        id: 'step-' + i,
        class: _normalizeClass('step step-' + (i+1)),
        onClick: (e) => _ctx.selectOption(node)
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(node), 1)
      ], 10, _hoisted_2))
    }), 128))
  ], 6))
}