
    import { Options, Vue } from 'vue-class-component';
    import LogoCmp from '@/lib/SiteLayout/Logo/logo.vue';
    import SigninModal from '@/lib/Modals/signin_modal.vue';
    import SignupModal from '@/lib/Modals/signup_modal.vue';
    //import VueFinalModal from 'vue-final-modal';
    import store from "@/stores";
    import { UserProfile } from "@/models/userProfile";
    import Analytics from "@/services/analytics";

    @Options({
        components: {
            LogoCmp,
            SigninModal,
            SignupModal,
            //VueFinalModal
        },
    })
    export default class PublicHeader extends Vue {
        role: string = 'guest';
        userInfo: UserProfile = new UserProfile();
        pagepath: string = '';
        theme = 'light';
        joinUrl: string = '';
        showSigninModal: boolean = false;
        showSignupModal: boolean = false;

        async mounted () {
            //this.buildJoinUrl();
        }
        async created() {
            this.role = store.role;

            // these comes from the jwt claims
            this.userInfo.thumbnailUrl = store.userData.thumbnailUrl;
        }

        // translations
        public get signinBtn () { return this.$t('actions.signin') }
        public get joinBtn () { return this.$t('actions.signup') }
        public get discoverBtn () { return this.$t('actions.search') }
        public get aboutBtn () { return this.$t('titles.about') }

        isSiteDown() {
            return store.isSiteDown;
        }
        confirm() {
            this.showSigninModal = false
        }
        cancel() {
            this.showSigninModal = false
        }

        buildJoinUrl() { 
            this.showSignupModal = true;

            const client_id = 'MgqOav6XM0L_MkuWIrd7SWjJ5b1bkp08VG6W8r0vDzVXyo26xe89tLrXN_bSAt1c';

            // redirect url where the user will land after the flow. That url is where the access code will be sent as a _GET parameter. This may be any url in your app that you can accept and process the access code and login
            // In this case, say, /patreon_login request uri. This doesnt need to be your final redirect uri. You can send your final redirect uri in your state vars to Patreon, receive it back, and then send your user to that final redirect uri
            const redirect_uri = "http://localhost:8080/redirect"; // url at your site which is going to receive users returning from Patreon confirmation

            // is the amount in cents that you locked your content or feature with. Say, if a feature or content requires $5 to access in your site/app, then you send 500 as min cents variable. Patreon will ask the user to pledge $5 or more.
            const min_cents = '500';

            // Scopes! You must request the scopes you need to have the access token.
            // In this case, we are requesting the user's identity (basic user info), user's email
            // For example, if you do not request email scope while logging the user in, later you wont be able to get user's email via /identity endpoint when fetching the user details
            // You can only have access to data identified with the scopes you asked. Read more at https://docs.patreon.com/#scopes

            // Lets request identity of the user, and email.

            const scope_parameters = '&scope=identity%20identity' + encodeURIComponent('[email]'); // &scope=identity%20identity%5Bemail%5D

            // Generate the unified flow url - this is different from oAuth login url. oAuth login url just processes oAuth login. 
            // Unified flow will do everything.

            let href = 'https://www.patreon.com/oauth2/become-patron?response_type=code&min_cents=' + min_cents + '&client_id=' + client_id + scope_parameters + '&redirect_uri=' + redirect_uri;

            // You can send an array of vars to Patreon and receive them back as they are. Ie, state vars to set the user state, app state or any other info which should be sent back and forth. 

            let state = { 
                'final_redirect': 'http://localhost:8080/dashboard' 
            }; // Replace http://mydomain.com/locked-content with the url of the content to be unlocked at your site, or whever you want the user to be directed to after returning from Patreon login/confirmation

            // Add any number of vars you need to this array by $state['key'] = variable value
            // Prepare state var. It must be json_encoded, base64_encoded and url encoded to be safe in regard to any odd chars. When you receive it back, decode it in reverse of the below order - urldecode, base64_decode, json_decode (as array)
            
            const jsonEncodedState = JSON.stringify( state );
            //console.log(jsonEncodedState); // {"final_redirect":"http:\/\/mydomain.com\/locked-content"}
            const base64EncodedState = btoa( jsonEncodedState );
            //console.log(base64EncodedState); // eyJmaW5hbF9yZWRpcmVjdCI6Imh0dHA6XC9cL215ZG9tYWluLmNvbVwvbG9ja2VkLWNvbnRlbnQifQ==
            let state_parameters = '&state=' + encodeURIComponent( base64EncodedState );
            //console.log(state_parameters); // eyJmaW5hbF9yZWRpcmVjdCI6Imh0dHA6XC9cL215ZG9tYWluLmNvbVwvbG9ja2VkLWNvbnRlbnQifQ%3D%3D

            // Append it to the url 
            href += state_parameters;

            // https://www.patreon.com/oauth2/become-patron?response_type=code&min_cents=500&client_id=MgqOav6XM0L_MkuWIrd7SWjJ5b1bkp08VG6W8r0vDzVXyo26xe89tLrXN_bSAt1c&scope=identity%20identity%5Bemail%5D&redirect_uri=http://mydomain.com/patreon_login&state=eyJmaW5hbF9yZWRpcmVjdCI6Imh0dHA6Ly9teWRvbWFpbi5jb20vbG9ja2VkLWNvbnRlbnQifQ%3D%3D
            //console.log(href); 

            this.joinUrl = href;
        }

        changeTheme(toggleTheme) {
            this.theme = toggleTheme;
        }

        onSignupModalClose() {
            console.log("CLOSING SIGNUP MODAL");
        }
        onSignupModalOpened() {
            console.log("SIGNUP MODAL OPENED");
        }
        join() {
            //Analytics.sendEvent("join", { method: 'patreon' })
            Analytics.sendEvent("signup", { })
            this.$router.push({ path: `/signup` });
        }
        login() {
            Analytics.sendEvent("signin", { })
            this.$router.push({ path: `/signin` });
        }
    }
