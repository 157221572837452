
    import { Vue, Options } from 'vue-class-component';
    import * as d3 from "d3";

    @Options({
        props: {
            nodes: Array,
            nodesOutside: Array
        },
        emits: ['completed']
    })
    export default class KanaKeyboard extends Vue {
        
        finalWord: string[] = [];
        nodes: any = [];
        nodesOutside: any = []

        syllables: any = [];
        vowels: any = []
        selectedModifierOption = 0;

        isDragging: boolean = false;
        currentNodeHovered: any;
        isDraggingFromOuter = false;
        startCoordinates = {x:0, y:0}; endCoordinates = {x:0, y:0}; currentSvgPath: any;
        arrowLeft: any; canvas: any; ctx: any;
        currentSyllableSelected: any;

        created () {
            this.syllables = this.nodesOutside
            this.vowels = this.nodes.reverse()
        }
        mounted() {    
            //window.addEventListener('resize', resize);
            this.canvas = document.querySelector('canvas');
            //canvas.style.border = '2px solid red';
            this.canvas.style.zIndex = "12";
            this.ctx = this.canvas.getContext('2d');

            this.currentSvgPath = d3.select("#node_lines").selectAll("path").node()
        }

        resize() {
            const width = window.innerWidth;
            const height = window.innerHeight;
            // largeHeader.style.height = height+'px';
            this.canvas.width = width;
            this.canvas.height = height;
        }
        addNewCurve() {
            var data = `M${this.startCoordinates.x} ${this.startCoordinates.y} C ${this.startCoordinates.x + 1} ${this.startCoordinates.y + 1}, ${this.endCoordinates.x - 6} ${this.endCoordinates.y - 6}, ${this.endCoordinates.x} ${this.endCoordinates.y}`;

            this.currentSvgPath = d3.select("#node_lines")
            .append("path")
            .attr("stroke", 'white') 
            .attr("stroke-width",'10')
            .attr("fill",'transparent')
            .attr("style", "stroke: #F7EDEE;stroke-width: 10px;fill: transparent;") 
            .attr("class",'added')
            .attr("d", data).node();
        }
        moveLine() {      
            var data = `M${this.startCoordinates.x} ${this.startCoordinates.y} C ${this.startCoordinates.x + 1} ${this.startCoordinates.y + 1}, ${this.endCoordinates.x - 6} ${this.endCoordinates.y - 6}, ${this.endCoordinates.x} ${this.endCoordinates.y}`;

            this.currentSvgPath.setAttribute("d", data);
        }
        selectNode(i, addToFinalWord = true, setOthersInactive = false) {
            if (d3.select("#step-"+i)) {
                const ele: d3.BaseType = d3.select("#step-"+i).node() as HTMLElement;
                ele.classList.add("step-selected");
                
                if (addToFinalWord)
                    this.finalWord.push(i); 

                if (setOthersInactive)
                    this.syllables.forEach(el => {
                        if (el.letter !== i)
                            d3.select("#step-"+el.letter).classed('step-inactive', true);
                    });
            }
        }

        hovering(e, nodeIndex: any, isOuter = false) {
            if (!nodeIndex) return;
            if (this.isDragging && isOuter) return;

            var letter = "";
            if (isOuter) {
                letter = nodeIndex.letter;
            } else {
                letter = nodeIndex;
            }

            this.currentNodeHovered = nodeIndex;

            if (this.isDragging && this.finalWord.indexOf(letter) === -1) { // dont repeat if already hovered
                // if we hovered this word while dragging
                this.selectNode(letter, true, false);

                // get the x,y coordinates to anchor the line
                let coords = this.getMousePosition(e);
                if (coords) {
                    this.startCoordinates = {x: coords.x, y: coords.y};

                    this.addNewCurve();
                }
            }
        }
        startDrag(e, isOuter = false) {         
            //e.stopPropagation();
            //this.currentSyllableSelected = nodeIndex;
            this.isDraggingFromOuter = isOuter;
            
            if (this.currentNodeHovered) {
                // show the inner circle alphabet with the current selected syllable
                //console.log("isOuter: " + isOuter)
                if (isOuter) {
                    // add
                    this.selectNode(this.currentNodeHovered.letter, false, true);

                } else {
                    // the first word is selected when clicked and not on hover
                    this.selectNode(this.currentNodeHovered);
                }
                
                document.addEventListener('mousemove', this.dragging); // this.eventDragging
                document.addEventListener('mouseup', this.endDrag); // this.eventEndDragging

                let coords = this.getMousePosition(e);
                if (coords) {
                    this.startCoordinates = {x: coords.x, y: coords.y};
                    this.addNewCurve();
                }
            }
        }
        dragging(e) {
            this.isDragging = true;

            let coords = this.getMousePosition(e);

            if (coords) {
                this.endCoordinates = {x: coords.x, y: coords.y};

                this.moveLine();
            }
        }
        endDrag() {
            this.isDragging = false;

            // only the ん can be added from the outer circle if its the only one selected
            if (this.isDraggingFromOuter && this.currentNodeHovered.letter == 'ん') {
                this.finalWord.push(this.currentNodeHovered.letter);
            }
           /*  let finalText = this.finalWord.map((r) => {
                return this.nodes[r];
            }); */

            /* this.finalWord.forEach(element => {
                d3.select("#step-"+element).classed('step-selected', false);
            }); */
            this.syllables.forEach(el => {
                d3.select("#step-"+el.letter).classed('step-selected', false);
                d3.select("#step-"+el.letter).classed('step-inactive', false);

                el.alphabet.forEach(vowel => {
                    d3.select("#step-"+vowel).classed('step-selected', false);
                });    
                if (el.tentenalphabet)
                    el.tentenalphabet.forEach(vowel => {
                        d3.select("#step-"+vowel).classed('step-selected', false);
                    });
                if (el.marualphabet)
                    el.marualphabet.forEach(vowel => {
                        d3.select("#step-"+vowel).classed('step-selected', false);
                    });
            });

            document.removeEventListener('mousemove', this.dragging);
            document.removeEventListener('mouseup', this.endDrag);

            // TODO: add drag events touch mobile

            // remove the node links
            d3.select("#node_lines").selectAll("path.added").remove()

            let word = this.finalWord.join("");
            
            this.$emit('completed', word);

            this.finalWord = [];
            this.currentNodeHovered = null;
            //console.log(word)
            this.vowels = this.nodes.reverse() // reset to default vowels 
            //console.log(this.nodes)
        }

        getMousePosition(evt) {
            if (!d3.select("#node_lines") || !d3.select("#node_lines").node()) return;

            const ele = d3.select("#node_lines").node()! as SVGGraphicsElement;

            var CTM = ele.getScreenCTM();
            if (!CTM) {
                return {
                    x: 0,
                    y: 0
                };
            }
            return {
                x: (evt.clientX - CTM.e) / CTM.a,
                y: (evt.clientY - CTM.f) / CTM.d
            };
        }
        selectOption(id: number) {
            // reset
            if (this.selectedModifierOption == id)
                this.selectedModifierOption = 0
            else
                this.selectedModifierOption = id
        }
    }
