import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-semibold text-base sm:text-2xl md:text-3xl mb-4" }
const _hoisted_2 = { class: "mb-8" }
const _hoisted_3 = {
  class: "w-full max-w-xs flex flex-col py-5 px-8",
  action: ""
}
const _hoisted_4 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}
const _hoisted_5 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}
const _hoisted_6 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title_contact), 1),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.messages_contact), 1)
    ]),
    _createElementVNode("form", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.labels_subject) + " *: ", 1),
      _withDirectives(_createElementVNode("input", {
        class: "text-gray-700 shadow border rounded border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3",
        type: "text",
        placeholder: "",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.subject) = $event))
      }, null, 512), [
        [_vModelText, _ctx.data.subject]
      ]),
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.labels_email) + ": ", 1),
      _withDirectives(_createElementVNode("input", {
        class: "text-gray-700 shadow border rounded border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3",
        type: "text",
        placeholder: "",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.email) = $event))
      }, null, 512), [
        [_vModelText, _ctx.data.email]
      ]),
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.labels_body) + " *: ", 1),
      _withDirectives(_createElementVNode("input", {
        class: "text-gray-700 shadow border rounded border-gray-300 mb-3 py-1 px-3 focus:outline-none focus:shadow-outline",
        type: "text",
        placeholder: "",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.body) = $event))
      }, null, 512), [
        [_vModelText, _ctx.data.body]
      ]),
      _createElementVNode("button", {
        class: "btn navbar-toggler btn-medium",
        type: "button",
        onClick: _cache[3] || (_cache[3] = async (e) => {await _ctx.sendForm()})
      }, _toDisplayString(_ctx.actions_send), 1)
    ])
  ], 64))
}