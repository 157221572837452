
    import { Vue, Options } from 'vue-class-component';
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";

    @Options({
        components: {
            Disclaimer
        },
    }) 
    export default class WaitCoundown extends Vue {

        // translations
        public get messages_waiting_countdown () { return this.$t('messages.waiting_countdown') }
        public get actions_retry () { return this.$t('actions.retry') }
        public get messages_sponsor_title () { return this.$t('messages.sponsor_title') }
        public get sponsor_pha1 () { return this.$t('messages.sponsor_pha1') }
        public get sponsor_pha2 () { return this.$t('messages.sponsor_pha2') }
        public get sponsor_pha3 () { return this.$t('messages.sponsor_pha3') }
        public get sponsor_pha4 () { return this.$t('messages.sponsor_pha4') }

        created() {
            AnalyticsUtils.visit(this.$route);
        }
        updateTimer() {

        }
        goToDiscover() {

        }
    }
