
    import { Vue, Options } from 'vue-class-component';
    //import { Ref } from 'vue-property-decorator'
    import store from "@/stores";
    //import {TLFrame} from '@models/TLFrame';

    // TODO: add subscriptions
    //import timelineService from '@/services/timeline_store'; 
    import TimelineFrame from './frame.vue';
    import { Player, TLFrame } from '@/models/TLFrame';
    import EventBus from '@/services/pubsub'
    import { ref } from 'vue';
    
    @Options({
        components: {
            TimelineFrame
        },
        prop: {
            tracks: Array,
            defaultPoses: Array
        },
        emits: ['selected', 'onFrameMoving', 'timelineUpdate']
    }) 
    export default class Timeline extends Vue {
        
        // translations
        public get actions_go_to_lyric () { return this.$t('actions.go_to_lyric') }
        public get actions_delete_selected () { return this.$t('actions.delete_selected') }
        public get actions_move_right () { return this.$t('actions_move_right') }
        public get actions_move_left () { return this.$t('actions.move_left') }
        public get t2 () { return this.$t('about.t2') }

        created() {
            setTimeout(() => {
                store.isLoadingRoute = false;
            }, 1000);
        }

        BLOCK_ADDED = 'BLOCK_ADDED';
        BLOCK_REMOVED = 'BLOCK_REMOVED';
        isItemLoaded: boolean = false; // se agregan por defecto?
        animations: TLFrame[] = [];
        initialSelectedAnimation/* : TLFrame = null; */
        selectedAnimation = new TLFrame()/* : TLFrame = null; */
        timeText = "";
        zoom: string = '';
        zoomRange: number = 0;
        scale: number = 100;
        prevScale: number = 0;
        prevTime = 0;
        startTime = new Date().getTime();
        elapsed = 0.0;
        maxEndTime = 0;
        SNAP_TIME = 0.03;
        snapType: number = 0;
        isSnapActive: boolean = false;
        timeout = null;
        framesMoved: boolean = false;

        instances = []; events = [];
        timeSubscription;
        /* getTimeSubscription;
        windowResizeSubscription;
        cleanStateSubscription;
        tabSelectedSubscription; */

        /* @Ref() canvas!: any;
        @Ref() scroller!: any; // para leer y setear el scrollLeft
        @Ref() timeMark!: any;; // marca roja de tiempo */
        
        canvas: any = ref(null)
        scroller: any = ref(null)
        timeMark: any = ref(null)

        // tracks
        // export trackSize;
        tracks: any = [];
        defaultPoses: any = [];

        // SERVICE VARIABLES
        duration = 10000;
        player = new Player();
        selected= new TLFrame();
        //scale = 100; //260; // escala compartida evitar bloques perdidos al agregar nuevos
        _lastHandTrackSelected;
        _lastHeadTrackSelected;
        _lastTrunkTrackSelected;
        animationSelected
        previewTracks

        mounted() {    
            this.prevScale = this.scale;
            this.animations = [];
            //this.scale = timelineService.getScale();
            this.timeText = '0:00.00';
            this.zoom = '0%';
            this.zoomRange = 10;
            this.isItemLoaded = false;
            this.isSnapActive = false;
            this.framesMoved = false;

            console.log(this.selectAnimation)
           /*  this.timeSubscription = timelineService.timeChanged.subscribe(() => {
                //console.log("TIME CHANGING");
                this.updateTimeMark();
                this.updateTimeText(timelineService.getCurrentTime());
            }); */

        /*  addPoseSubscription = timelineService.addPoseChanged.subscribe((frame: TLFrame)=> {
                addAnimation(frame);
            }); */
            /* getTimeSubscription = timelineService.getTimeState().subscribe(()=> {
                updateTimeMark();
            });
            windowResizeSubscription = timelineService.getWindowsResizedState().subscribe(()=> {
                updateMarks();
            });
            tabSelectedSubscription = timelineService.getEditorTabSelectedState().subscribe((frame: TLFrame)=> {
                unselectAll();
                if (frame) {
                    selectAnimation(null, frame, frame ? frame.start : null);
                }
            });

            cleanStateSubscription = timelineService.getCleanState().subscribe((frame: TLFrame) => {
                cleanTimeline();
            }); */

            // at least 1 track
            if (!this.tracks || this.tracks.length == 0) {
                this.tracks.push( { name: "track1", thumbnail: "" } );
            } else {
                //addTracks(tracks);
            }

            this.updateMarks();
            //fitToView();
            //changeZoom();

            if (this.defaultPoses && this.defaultPoses.length > 0) {

                this.addTracks(this.defaultPoses);
            }
        }


        // SERVICES FUNCTIONS
        isPlaying = ()=> {
            return this.player.isPlaying;
        }
        getCurrentTime() {
            return this.player.currentTime;
        }
        playPlayer() {
            this.player.play();
            //playingChanged.set({isPlaying: true, poses: null});
        }
        stopPlayer() {
            this.player.pause();
            //playingChanged.set({isPlaying: false, poses: null});
        }
        setCurrentTime = (time) => {
            this.player.currentTime = time;
        }
        setTimeLineScale = (_scale) => {
            this.scale = _scale;
            //timelineScaled.set( scale );
            EventBus.emit('timelineScaled', { _scale })
        }
        selectAnimationService = (animation) =>{
            if ( this.selected === animation ) return;

            this.selected = animation;
            this.animationSelected = animation; //this.animationSelected.set( animation );

            // Guardar la ultima animacion seleccionada por track
            switch (animation.layer) {
            case 0: this._lastHandTrackSelected = animation; break;
            case 1: this._lastHeadTrackSelected = animation; break;
            case 2: this._lastTrunkTrackSelected = animation; break;
            }
        }
        setTime(time) {
            this.player.currentTime = Math.max( 0, time );
            //timeChanged.set( player.currentTime );
        }
        onPreviewTracks = (posesIdSelected) => {
            this.previewTracks = posesIdSelected; // this.previewTracks.set(posesIdSelected);
        }
        animationsMoving(e) {
            //animationMove.set(e);
            EventBus.emit('animationMove', { e })
        }
        getUniqueId = () => {
            return Math.random().toString(36).substring(2)
            + (new Date()).getTime().toString(36);
        }
        /* onAnimationMoved = (newArray) => {
            //animationMoved.set(newArray);
        } */
        /* addedAnimation = (newArray) => {
            // animationAdded.set(newArray);
        } */
        onChildFrameAdded = (frame) => {
            console.log(frame);
            
            //childFrameAdded.set(frame);
        }
        removeSelectedAnimations = (/* newArray */) => {
            this.selected = new TLFrame();
            //animationRemoved.set(newArray);
        }
        
        // PARENT EVENTS
        timeChanged() {
            this.updateTimeMark();
            this.updateTimeText(this.getCurrentTime());
        }
        

        
        // Calcular las transiciones al reproducir
        play() {
            this.resetDefaultTime();

            this.isPlaying() ? this.stopPlayer() : this.playPlayer();
            this.startTime = new Date().getTime();

            let maxEnd = Math.max.apply(Math, this.animations.map((o) => {return o.end;}));
            this.maxEndTime = (Math.round(maxEnd* 100) / 100);

            this.runner();
        }

        runner() {
            if(this.isPlaying()) {
                let time = new Date().getTime() - this.startTime;
                // let time = Date.now() - startTime; // TODO: test this other way acurracy
                this.elapsed = (time / 100) / 10;

                if (Math.round(this.elapsed) == this.elapsed) { this.elapsed += .0; }
                //timelineService.setCurrentTime(elapsed);
                this.updateTimeText(this.getCurrentTime());
                this.updateTimeMark();

                if ((Math.round(this.elapsed * 100) / 100) > this.maxEndTime) {
                    this.stopPlayer();
                    this.resetDefaultTime();
                    // timeMark.scrollIntoView({ behavior:"smooth" });
                    this.scroller.scrollLeft = 0;
                }

                setTimeout(this.runner, 30);
            }
        }

        resetDefaultTime() {
            this.setCurrentTime(0); // timelineService.player.currentTime = 0;
            this.updateTimeText(this.getCurrentTime());
            this.updateTimeMark();
        }

        posesMoving(/* e: any */) {
            //timelineService.animationsMoving(e); // ****
        }

        onResizeBegin(e: any){
            this.selectedAnimation = e;
            this.initialSelectedAnimation = Object.assign({}, e);
            this.selectAnimation(null, e);
        }

        onFrameResized(/* e: any */) {
            /* let sortedArray =  */this.getSortedArrays();
            //timelineService.onAnimationResized(sortedArray); // ****
            this.validatePosesPosition();
            this.initialSelectedAnimation = null;
        }

        calculatePositionsTransitions(layer: any): TLFrame[] {
            let trackLayer = this.animations.filter(c => c.layer == layer); // Obtener las poses del track actual
            //timelineService.sortByTimeStartAsc(trackLayer); // ordenar por milisegundos de inicio  // ****
            for (var i = 0; i <= trackLayer.length; i++) {
                if (trackLayer[i]) {
                    trackLayer[i].position = i;
                    trackLayer[i].transition = 0;
                    if (trackLayer[(i+1)] != null) {
                        trackLayer[i].transition = Math.abs(trackLayer[(i+1)].start) - (trackLayer[i].end);
                    }
                }
            }

            //timelineService.sortByTimeStartAsc(trackLayer);  // ****

            return trackLayer;
        }

        getSortedArrays(): any {
            let handTrack = this.calculatePositionsTransitions(0);
            let headTrack = this.calculatePositionsTransitions(1);
            let trunkTrack = this.calculatePositionsTransitions(2);
            let transitions = {};

            transitions = { handTrack, headTrack, trunkTrack };

            return transitions;
        }

        getViewport() {
            /* let offsetY = this.scroller.offsetY / this.scale;
            let offsetLeft = this.scroller.offsetLeft / this.scale;
            let offsetX = this.scroller.offsetX / this.scale;
            let startTime = this.scroller.scrollLeft / this.scale; */
        }

        fitToView() {
            // resetDefaultTime();
            // updateMarks();
            let maxEnd = Math.max.apply(Math, this.animations.map((o) => {return o.end;}));
            this.scale = 1000 / +maxEnd;
            this.changeZoom();
            // timeMark.scrollIntoView({behavior:"smooth"});
            // scroller.scrollLeft += 500;
            // timeMark.style.transform += "translateX(" + 100 + "px)";
        }

        changeZoom(deltaY?) {
            if (deltaY) {
                this.scale = Math.max( 2, +this.scale + ( +deltaY ) );
            }

            this.zoomRange = Math.round((+this.scale / 100) * 10);

            //console.log("scale " + scale + " // zoomRange: " + zoomRange);
            // El zoom va a tener un minimo de 5% maximo de 100%
            if (this.zoomRange > 0 && this.zoomRange < 101) {
                this.zoom = this.zoomRange + '%';
                let result = ( +this.scroller.scrollLeft * +this.scale ) / +this.prevScale;

                //console.log("scroller.scrollLeft " + scroller.scrollLeft + " // scale: " + scale + " // prevScale: " + prevScale);
                //console.log("result " + result);

                this.scroller.scrollLeft = result; // +'px'mover el scroll al inicio

                this.updateMarks();
                this.updateTimeMark();

                this.prevScale = this.scale;
                this.setTimeLineScale( this.scale ); // timelineService.setTimeLineScale( scale );
            }
        }

        zoomToScale() {
            //console.log("CHANGING ZOOM " + scale);
            //scale = zoom;
            this.changeZoom();
        }

        // cambiar la escala de la linea de tiempo con Ctrl + rueda mouse
        onMouseWheel(e) {
            if ( e.altKey === true ) {
                this.changeZoom(e.deltaY);
            }
        }

        // TODO: bug this is altering the timemarks
        onScroll(/* e */) {
            this.updateMarks();
            this.updateTimeMark();
        }

        selectAll() {
            for(let o of this.animations) {
                o.isSelected = true;
            }
        }
        unselectAll() {
            for(let o of this.animations) {
                o.isSelected = false;
            }
        }

        unselectAllExcept(anim: TLFrame) {
            for(let o of this.animations) {
                if(o != anim){
                    o.isSelected = false;
                }
            }
            this.selectedAnimation = anim;

            //console.log(JSON.stringify(animations));
        }

        // ---- EVENTS
        selectAnimation(e, animationSelected, selectedTime?) {

            if ( e && e.detail.ctrlKey === false ) {
                this.unselectAll();
            }
            animationSelected.isSelected = true;
            this.selectAnimationService( animationSelected );
            this.selectedAnimation = animationSelected;

            if (selectedTime || selectedTime == 0) {
                this.setTime(selectedTime);
                let tracks = this.getPreviewTracks(selectedTime);
                this.onPreviewTracks(tracks);
            }

            //console.log(JSON.stringify(animations));
        }

        getPoseIdFromTrack(layer, currentTime) {
            let trackFrames = this.animations.filter(c => c.layer == layer && c.start <= currentTime && c.end >= currentTime);
            let id = '';

            if (!trackFrames || trackFrames.length == 0) {
                trackFrames = this.animations.filter(c => c.layer == layer && c.start > currentTime);
            }
            if (trackFrames && trackFrames.length > 0) {
                //timelineService.sortByPositionAsc(trackFrames); // TODO: double check this
                id = trackFrames[0].id;
            }

            return id;
        }

        // Gets the frames/poses of the current time
        getPreviewTracks(currentTime): any {
            let handTrackId = this.getPoseIdFromTrack(0, currentTime);
            let headTrackId = this.getPoseIdFromTrack(1, currentTime);
            let trunkTrackId = this.getPoseIdFromTrack(2, currentTime);
            let transitions = { handTrackId, headTrackId, trunkTrackId };

            return transitions;
        }

        selectPrevious() {
            let previousFrames = this.animations.filter(c => c.layer == this.selectedAnimation.layer && c.position < this.selectedAnimation.position);

            if (previousFrames && previousFrames.length > 0) {
                //timelineService.sortByPosition(previousFrames); // TODO: double check this
                let max = previousFrames[0];
                this.unselectAll();
                this.selectAnimation(null, max, max.start);
            }
        }
        selectNext() {
            let nextFrames = this.animations.filter(c => c.layer == this.selectedAnimation.layer && c.position > this.selectedAnimation.position);

            if (nextFrames && nextFrames.length > 0) {
                //timelineService.sortByPositionAsc(nextFrames); // TODO: double check this
                let max = nextFrames[0];
                this.unselectAll();
                this.selectAnimation(null, max, max.start);
            }
        }

        click(e, animationSelected: TLFrame) {
            this.selectedAnimation = animationSelected;
            this.initialSelectedAnimation = Object.assign({}, animationSelected);
            animationSelected.previousStart = this.initialSelectedAnimation.start;
            animationSelected.previousEnd = this.initialSelectedAnimation.end;

            let offsetX = e.offsetX;
            if (e.target != this) {
                offsetX = e.target.offsetLeft + e.offsetX;
            }
            let start = ( offsetX ) / this.scale;

            this.selectAnimation(e, animationSelected, start);

            //dispatch('selected', animationSelected);
        }
        focusCurrentFrame() {
            this.$emit('selected', this.selectedAnimation);
        }

        _mouseMoveclickHandler
        _mouseUpclickHandler
        /* _mouseMoveclickHandler = mousemove;
        _mouseUpclickHandler = mouseup; */

        mousedown(e, animationSelected: TLFrame) { 
            this.selectedAnimation = animationSelected;
            //console.log(JSON.stringify(animationSelected));
            this.initialSelectedAnimation = Object.assign({}, animationSelected);
            animationSelected.previousStart = this.initialSelectedAnimation.start;
            animationSelected.previousEnd = this.initialSelectedAnimation.end;
            this.selectAnimation(e, animationSelected);
            this._mouseMoveclickHandler = document.addEventListener('mousemove', this.mousemove);
            this._mouseUpclickHandler = document.addEventListener('mouseup', this.mouseup);
        }
        mousemove(e) {
            this.framesMoved = true;
            this.animationsMoving(e);

            //console.log(JSON.stringify(selectedAnimation));
            const objMoving = { id: this.selectedAnimation.position, start: this.selectedAnimation.start, end: this.selectedAnimation.end };
            this.$emit('onFrameMoving', objMoving);
            // Detects when the user stops moving
            /* if (timeout !== undefined) clearTimeout(timeout);
                timeout = setTimeout(()=> {
                if (isSnapActive) {
                    snapLeft(selectedAnimation, true);
                    snapRight(selectedAnimation, true);
                }
            }, 100); */
        }
        mouseup(/* e */) {
            document.removeEventListener('mousemove', this._mouseMoveclickHandler);
            document.removeEventListener('mouseup', this._mouseUpclickHandler);

            this.validatePosesPosition();

            // only trigger the event if the frames were moved
            if (this.framesMoved) {
                /* let sortedArray =  */this.getSortedArrays();
                //this.onAnimationMoved(sortedArray);
            }

            this.framesMoved = false;
        }

        frameResized(e, animationSelected) {
            console.log(e)
            this.selectedAnimation = animationSelected;
            const objMoving = { id: this.selectedAnimation.position, start: this.selectedAnimation.start, end: this.selectedAnimation.end };
            this.$emit('onFrameMoving', objMoving);
        
        }

        resizeleftend(/* e */) {
            if (this.isSnapActive) {
                //this.snapLeft(e);
            }
        }
        resizerightend(/* e */) {
            if (this.isSnapActive) {
                //this.snapRight(e);
            }
        }

        validatePosesPosition() {
            let selectedFrames = this.animations.filter(c => c.isSelected == true); // 1. Filtrar todos los seleccionados

            for (let frameSelected of selectedFrames) { // 2. Validar 1x1 posicion actual
                // Si cuando el frame se suelta queda en una posicion incorrecta devolverla a la anterior
                let currentLayer = this.animations.filter(c => c.layer == frameSelected.layer && c.id != frameSelected.id); //

                for (let frame of currentLayer) {  // 3. Validar con demas frames diferente de el mismo en el mismo track
                    if(  (frame.start <= frameSelected.start && frame.end >= frameSelected.end) // superpuesto total
                    || (frame.start < frameSelected.start && frame.end > frameSelected.start) // superpuesto por la derecha
                    || (frame.start > frameSelected.start && frame.start < frameSelected.end) // superpuesto por la izquierda
                    || (frame.start == frameSelected.start && frame.end > frameSelected.start) // superpuesto por la derecha con mismo inicio
                    || (frame.start > frameSelected.start && frame.end == frameSelected.end) // superpuesto por la izquierda con mismo final
                    || ( parseFloat(frame.start.toFixed(2)) == parseFloat(frameSelected.start.toFixed(2)) && parseFloat(frame.start.toFixed(2)) == parseFloat(frameSelected.end.toFixed(2)) )
                    ) {
                    frameSelected.start = frameSelected.previousStart;
                    frameSelected.end = frameSelected.previousEnd;
                    this.setTimeLineScale( this.scale );
                    }
                }
            }
        }

        isFrameInsideOther(frameToValidate: TLFrame) {
            let currentLayer = this.animations.filter(c => c.layer == frameToValidate.layer && c.id != frameToValidate.id);

            for (let frame of currentLayer) {
                if ( (frame.start <= frameToValidate.start && frame.end >= frameToValidate.end)
                || (frame.start < frameToValidate.start && frame.end > frameToValidate.start)
                || (frame.start > frameToValidate.start && frame.start < frameToValidate.end) )
                    return true;
            }

            return false;
        }

        // Agregar pose al hacer doble click
        dblClick(e) {
            let start = ( e.offsetX + this.scroller.scrollLeft ) / this.scale ;
            var end = start + 0.2;
            var layer = Math.floor( e.offsetY / 64 );
            if (layer > 3) layer = 3;
            
            let currentLayer = this.animations.filter(c => c.layer == layer);
            const uniqueId = this.getUniqueId();
            
            let maxPosition = 0;
            if (currentLayer && currentLayer.length > 0) {
                maxPosition = Math.max.apply(Math, currentLayer.map(function(o){return o.position;}))
                if(!currentLayer || !maxPosition) maxPosition = 0;
            }

            let newAnimation = new TLFrame({id: uniqueId, position: (+maxPosition + 1), name: 'Pose', start, end, transition: -1, layer, path: ''});
            newAnimation.isPose = true;
            newAnimation.isResizable = false;

            // No puede estar dentro de otro frame / posicion incorrecta
            if(!this.isFrameInsideOther(newAnimation)) {
                this.addAnimation(newAnimation);
                newAnimation.isSelected = true;
                this.selectAnimationService( newAnimation );
            }

            this.setTime(start);
        }

        addAnimation(newAnimation: TLFrame, triggerEvent: boolean=true) {
            
            //instances[1].addEventListener('mousedown', mousedown);

            this.animations.push(newAnimation);
            //this.animations = this.animations; TODO: why?



            // Recalcular todas las posiciones y transiciones de cada pose para enviarlo al editor
            // debido a que se puede agregar en medio de cualquier frame
            if (triggerEvent) {
                /* let layer =  */this.calculatePositionsTransitions(newAnimation.layer);
                //this.addedAnimation(layer);
            }

            newAnimation.isSelected = true;
            this.unselectAllExcept(newAnimation);
        }

        addChildFrame(newAnimation: TLFrame, triggerEvent: boolean=true) {
            this.animations.push(newAnimation);

            // No recalcular transiciones
            if (triggerEvent) {
                this.onChildFrameAdded(newAnimation);
            }

            newAnimation.isSelected = true;
            this.unselectAllExcept(newAnimation);
        }

        addAnimations(newAnimations: TLFrame[]) {
            newAnimations.forEach(newAnimation => {
                newAnimation.isSelected = true;
                this.animations.push(newAnimation);
                /* let layer =  */this.calculatePositionsTransitions(newAnimation.layer);
            });
        }

        deleteByAnimation(animation: TLFrame){
            for (let i = this.animations.length - 1; i >= 0; i--) {
                if (this.animations[i].id == animation.id) {
                    this.animations.splice(i, 1);
                }
            }

            /* let sortedArray =  */this.getSortedArrays();
            // this.removeSelectedAnimations(sortedArray); // TODO: check
        }

        deleteAnimation() {
            for (let i = this.animations.length - 1; i >= 0; i--) { // para que el index no se vea afectado
                if (this.animations[i].isSelected && this.animations[i].isDeletable) {
                    this.animations.splice(i, 1);
                }
            }

            /* let sortedArray =  */this.getSortedArrays();
            //this.removeSelectedAnimations(sortedArray); // TODO: check
        }

        moreCurrentToRight() {

        }
        moreCurrentToLeft() {

        }
        getDuplicatesByLayer(layer): any[] {
            let selected = this.animations.filter(c => c.isSelected && c.layer == layer);
            let newArray: TLFrame[] = [];

            let currentLayer = this.animations.filter(c => c.layer == layer);
            let maxPosition = Math.max.apply(Math, currentLayer.map((o) => {return o.position;}));
            let maxEnd = Math.max.apply(Math, currentLayer.map((o) => {return o.end;}));
            maxEnd += 0.2;

            selected.forEach(frameSelected => {
                const newPoseId = this.getUniqueId();
                maxPosition += 1;

                let duplicate = new TLFrame({id: newPoseId, position: +maxPosition, name: frameSelected.name, start: -1, end: -1, transition: -1, layer: frameSelected.layer, path: frameSelected.imagepath});
                duplicate.dupFromId = frameSelected.id;
                duplicate.isPhrase = frameSelected.isPhrase;
                duplicate.isPose = frameSelected.isPose;

                duplicate.start = maxEnd;
                duplicate.end = duplicate.start + frameSelected.duration;
                duplicate.duration = frameSelected.duration;
                maxEnd = duplicate.end;
                maxEnd += 0.2;

                newArray.push(duplicate);
            });

            return newArray;
        }

        updateTimeMark() {
            if (this.timeMark) {
                this.timeMark.style.left = ((+this.getCurrentTime() * +this.scale) - +this.scroller.scrollLeft) + 'px'; // 48 + 
            }
        }

        updateTimeText( value ) {
            if (value) {
                var minutes = Math.floor( value / 60 );
                var seconds = value % 60;
                var padding = seconds < 10 ? '0' : '';
                this.timeText = (minutes + ':' + padding + seconds.toFixed( 2 ));
            }
        }

        canvasMousedown(e) {
            //console.log("CANVAS MOUSE DOWN");
            let start = ( e.offsetX + this.scroller.scrollLeft ) / this.scale ;
            this.setTime(start);
            
            this.updateTimeMark();

            /* let tracks = getPreviewTracks(start);
            timelineService.onPreviewTracks(tracks); */
        }

        // metodo para dibujar el canvas de segundos y milisegundos
        updateMarks() {
            //console.log("scroller.clientWidth: " + scroller.clientWidth);
            if(!this.scroller) {
                //console.log("scroller"); console.log(scroller);
                return;
            } 
            this.canvas.width = this.scroller.clientWidth;

            var context = this.canvas.getContext( '2d', { alpha: false } );
            context.fillStyle = '#f1f1f1';
            context.fillRect( 0, 0, this.canvas.width, this.canvas.height );

            context.strokeStyle = '#2052A4';
            context.beginPath();
            context.translate( - this.scroller.scrollLeft, 0 );

            var duration = this.duration;
            var width = duration * this.scale;
            var scale4 = this.scale / 4; // 4

            //console.log("updateMarks.scale4: " + scale4 + " / width: " + width + " duration " + duration);

            for ( var i = 0.5; i <= width; i += this.scale ) {
                context.moveTo( i + ( scale4 * 0 ), 18 ); context.lineTo( i + ( scale4 * 0 ), 26 );
                if ( this.scale > 16 ) context.moveTo( i + ( scale4 * 1 ), 22 ), context.lineTo( i + ( scale4 * 1 ), 26 );
                if ( this.scale >  8 ) context.moveTo( i + ( scale4 * 2 ), 22 ), context.lineTo( i + ( scale4 * 2 ), 26 );
                if ( this.scale > 16 ) context.moveTo( i + ( scale4 * 3 ), 22 ), context.lineTo( i + ( scale4 * 3 ), 26 );
                if ( this.scale > 20 ) context.moveTo( i + ( scale4 * 4 ), 22 ), context.lineTo( i + ( scale4 * 4 ), 26 );
                if ( this.scale > 20 ) context.moveTo( i + ( scale4 * 5 ), 22 ), context.lineTo( i + ( scale4 * 5 ), 26 );

                //console.log("updateMarks.scale: " + scale);
            }

            context.stroke();
            context.font = '11px Barlow';
            context.fillStyle = '#2052A4'
            context.textAlign = 'center';

            var step = Math.max( 1, Math.floor( 64 / this.scale ) );
            for ( var j = 0; j < duration; j += step ) {
                var minute = Math.floor( j / 60 );
                var second = Math.floor( j % 60 );
                var text = ( minute > 0 ? minute + ':' : '' ) + ( '0' + second ).slice( - 2 );

                context.fillText( text, j * this.scale, 13 );
            }
        }

        addTracks(tracks) {

            tracks.forEach((frame, idx, /* array */) => {              
                const uniqueId = this.getUniqueId();    
                let defaultFrame = new TLFrame({id: uniqueId, position: idx, name: frame.name, start: frame.start, end: frame.end, transition: -1, layer: 0, path: ''});
                defaultFrame.isMovable = frame.isMovable;
                defaultFrame.isDeletable = frame.isDeletable;
                defaultFrame.isPose = frame.isPose;
                defaultFrame.isResizableLeft = frame.isResizableLeft;
                defaultFrame.isSelected = frame.isSelected;
                defaultFrame.line = frame.line;

                this.addAnimation(defaultFrame, true);               
            });
        }
    }
