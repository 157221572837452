import { elementScrollIntoView  } from "seamless-scroll-polyfill";

class Helpers {

  private enableLogs: boolean = false;
  consoleStylesInfo = [
    'color: black',
    'background: yellow',
    'font-size: 20px',
    'border: 1px solid red',
    'border-radius: 5px',
    'padding: 10px',
  ].join(';'); 

  consoleStylesWarning = [
    'color: black',
    'background: orange',
    'font-size: 25px',
    'border: 1px solid red',
    'padding: 10px',
  ].join(';'); 

  constructor() {
    
  }

  lgi(t) {
    this.lg(t, this.consoleStylesInfo)
  }
  lgw(t) {
    this.lg(t, this.consoleStylesWarning, 'w')
  }
  private lg(t, style, type = 'i') {
    if (!this.enableLogs) return;

    if(type == 'i')
      console.log('%c%s', style, t);
    if(type == 'w')
      console.warn('%c%s', style, t);
  }

  convertToFixed(mynum: number) {
    const mynumstr = mynum.toString();
    const parsed = parseFloat(mynumstr).toFixed(2);
    //console.log("parsed: " + parsed);
    return parsed;
  }

  // get document coordinates of the element
  getCoords(elemId: string): any {
    const elem = document.getElementById(elemId);
    if (!elem) return

    const box = elem.getBoundingClientRect();

    return {
      top: box.top + window.pageYOffset,
      right: box.right + window.pageXOffset,
      bottom: box.bottom + window.pageYOffset,
      left: box.left + window.pageXOffset
    };
  }

  scrollTo(id) {
    if (document.querySelector(id)) {
      elementScrollIntoView(document.querySelector(id)!, {
          behavior: "smooth",
          block: "start", // vertical (start, center, end, or nearest)
          inline: "center", // horizontal (start, center, end, or nearest)
      });
    }
  }

  randomNumberBetween(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  debounce(fn, delay) {
    let timeout
  
    return (...args) => {
      if (timeout) {
        clearTimeout(timeout)
      }
  
      timeout = setTimeout(() => {
        fn(...args)
      }, delay)
    }
  }
  
  isUrl(url: string): boolean {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1;
  }
  getVideoIdFromUrl(url: string): string {
      if (!this.isUrl(url)) return ''
      const regex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&]*).*/; // 186:78  error  Unnecessary escape character: \&  no-useless-escape \&\?
      const match = url.match(regex)
      if (match && match[1].length == 11) {
          return match[1];
      } else {
          //error
          return ''
      }
  }
  isValidEmail(email: string): boolean {
    const regex = /\S+@\S+\.\S+/;
    const match = regex.test(email)
    return match;
  }
}

export default new Helpers();
