
    import { Vue, Options } from 'vue-class-component';
    import * as wanakana from 'wanakana';
    import anime from 'animejs/lib/anime.es.js';

    @Options({
        prop: {
            lyrics: Object
        }
    })
    export default class AudioLyricPlayer extends Vue {

        currentLine = 0; assignedId = 1;
        isPlaying = false; isChangingVerse = false; isFullMode = false;

        lyrics: any = '';
        autoPlay: boolean = false;

        isLyricSetup = false;
        timeline;
        
        public play() {
            this.startTimer();
        }

        created() {
            if (!this.isLyricSetup) {
                console.log(this.lyrics);
                this.setUpLyrics();
                this.isLyricSetup = true;
            }

            this.initTimeline();

            //this.currentLang.setIsLoading(false);
        }

        setUpLyrics() {
            if (!this.lyrics || !this.lyrics.verses || this.lyrics.verses.length == 0) {
                return;
            }

            this.assignedId = 1;
            let totalMs = 0;
            // calculate furigana above each kanji if needed
            this.lyrics.verses.forEach((lyric: any) => {

                totalMs = 0;

                // clone references to build complete lyrics
                let refObj = lyric.refId ? this.lyrics.verses.find(x => x.id == lyric.refId) : undefined;
                if (refObj) {

                    let refObjClone = JSON.parse(JSON.stringify( refObj ));
                    
                    lyric.text = refObjClone.text;
                    lyric.romaji = refObjClone.romaji;
                    lyric.spec = refObjClone.spec;          
                }
                
                let characters = lyric.text.split("");       
                lyric.characters = [];
                
                if (characters && characters.length > 0) {
                        
                    characters.forEach((cha, i) => {                                                      
                        let specObj = lyric.spec ? lyric.spec.find(x => x.pos == i) : undefined;
                        let furigana = "";
                        let delay = undefined;
                        let isToComplete = false;

                        if (specObj) {
                            if (specObj.furigana) {
                                furigana = specObj.furigana;
                            } 
                            
                            if (specObj.delayms) {
                                delay = specObj.delayms;
                            }

                            // match the questions: find if the current character is a kanji and if its on the toComplete array
                            /* let questionObj = toComplete.find(x => x.kanji == specObj.kanji );
                            if (questionObj) {
                                //console.log("FOUND KANJI TO COMPLETE: " + JSON.stringify(questionObj));
                                isToComplete = true;
                            } */
                        }
                        if (lyric.showRomaji) {
                            if (wanakana.isHiragana(cha) || wanakana.isKatakana(cha)){
                                furigana = wanakana.toRomaji(cha);
                            }
                        }

                        let characterinfo = {id: this.assignedId, key: cha, furigana: furigana, delay: delay, toComplete: isToComplete};

                        lyric.characters.push(characterinfo);

                        this.assignedId = this.assignedId + 1;

                        totalMs = totalMs + (delay ? delay : 200);
                    });
                }

                lyric.totalMs = totalMs;
                lyric.diff = totalMs - (lyric.end - lyric.start);
            });
        }

        initTimeline() {
            if (!this.lyrics || !this.lyrics.verses || this.lyrics.verses.length == 0) {
                return;
            }

            this.timeline = anime.timeline({
                loop: false,
                autoplay: false,
                /* update: function(anim) {
                    //console.log("TIMELINE PROGRESS: " + anim.progress);
                }, */
                easing: 'easeInOutCirc',
            });
            

            this.lyrics.verses.forEach((lyric, verseId, verseArray) => {     
                        
                lyric.characters.forEach((character, idx, array) => {              
                
                    let delay = character.delay ? character.delay : 200;

                    // TODO: add the total difference in seconds between this and next verse in case there is a big pause
                    if (verseId !== verseArray.length - 1 && (idx === array.length - 1)) { // if its not the last verse and we are in the last character
                        let nextVerse = this.lyrics.verses[verseId + 1]; // TODO: check if this is the last one
                        let secondsBeforeNextVerse = +nextVerse.start - +lyric.end;
                        //console.log("verseId " + secondsBeforeNextVerse + " seconds before next verse starting at: " + nextVerse.start + " current ends " + lyric.end);
                        if (secondsBeforeNextVerse > 1) {
                            delay = delay + secondsBeforeNextVerse;
                            console.log("ADDED TO DELAY " + secondsBeforeNextVerse + " TOTAL " + delay);
                        }
                    }

                    this.timeline
                    .add({
                        targets: "#ch-" + lyric.id + "-" + character.id,
                        color: [ "rgb(190, 188, 188)", "rgb(158, 154, 154)", "#009688" ],
                        duration: delay,
                        /* begin: function(anim) {
                            if (!isPlaying) {
                                console.log("+++++++++++++ AVOIDED TO CONTINUE +++++++++++++");
                                timeline.pause();
                            }
                        }, */
                        complete: function() {
                            //console.log("READ " + character.key);
                            this.currentLine = verseId;

                            if (idx === array.length - 1) { 
                                //console.log("LAST ELEMENT OF VERSE " + verseId);
                                
                                // we wait for user input outside this component
                                //dispatch("onReadingLineComplete", verseId);
                                this.timeline.pause();

                            }
                        },
                        
                    });

                });
            });

            // never reached
            /* timeline.finished.then(()=>{
                console.log("///// LYRIC END /////");
                dispatch('onGameFinished', results);
            }); */
        }

        playLine() {
            let currentLineObj = this.lyrics.verses.find(x => x.id == this.currentLine);
            let startSeconds = currentLineObj.start / 1000;
            console.log("STARTING AGAIN FROM LINE " + currentLineObj.id + " ms: " + currentLineObj.start + " second: " + startSeconds);

            this.timeline.seek(currentLineObj.start);

            this.isChangingVerse = false;
            this.isPlaying = true;
            this.timeline.play();
        }

        startTimer() {
            if (!this.isPlaying) {
                this.isPlaying = true;
                this.playSound(this.lyrics.audioId);
                this.timeline.play(); 
            } else {
                this.isPlaying = false;
                this.timeline.pause();
            }
        }

        /* function goToPreviousLine() {
            isChangingVerse = true;
            currentLine--;
            timeline.pause();
            playLine();
        } */

        playSound(url) {
            var a = new Audio(url);
            a.play();
        }

        beforeUnmount() {
            try {
                //player.destroy();

                this.timeline.pause();
            } catch(e){
                console.log("ERROR: " + e);
            }
        }

    }

