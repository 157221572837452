
    import { Vue } from 'vue-class-component';
    import { countries } from '@/lib/definitions';
    import service from "@/services/api";
    import AnalyticsUtils from "@/services/analytics";

    export default class Profile extends Vue {
        
        // translations
        public get actions_save () { return this.$t('actions.save') }
        public get labels_firstname () { return this.$t('labels.firstname') }
        public get labels_lastname () { return this.$t('labels.lastname') }
        public get labels_email () { return this.$t('labels.email') }
        public get labels_username () { return this.$t('labels.username') }
        public get labels_country () { return this.$t('labels.country') }

        public get countries () { return countries }

        data: any = {firstname:'', lastname:'', username: '', email: '', country: '', };

        created() {
            AnalyticsUtils.visit(this.$route);
        }

        async mounted() {         
            const userInfo = JSON.parse(await service.fetchFromApi({url: "security/getProfile"}));

            if (userInfo) {
                console.log(userInfo)
                this.data.firstname = userInfo.firstname;
                this.data.lastname = userInfo.lastname;
                this.data.username = userInfo.username;
                this.data.email = userInfo.email;
                this.data.country = userInfo.country;
            }
            //currentLang.setIsLoading(false);
        }

        async save() {
            //currentLang.setIsLoading(true);

            await service.putToApi("security/user", { body: this.data });

            // show dialog
            //currentLang.setIsLoading(false);
        }
    }
