
    import { Vue, Options } from 'vue-class-component';
    import Footer from "@/lib/SiteLayout/Footer/footer.vue";
    import store from "@/stores";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";

    @Options({
        components: {
            Footer, Disclaimer
        },
    }) 
    export default class Terms extends Vue {
        
        // translations
        public get version () { return this.$t('terms[0]') }
        public get section1_title () { return this.$t('terms[1]') }

        public get section2_title () { return this.$t('terms[2]') }
        public get section2_pha1 () { return this.$t('terms[3]') }
        public get section2_pha2 () { return this.$t('terms[4]') }
        public get section2_pha3 () { return this.$t('terms[5]') }
        public get section2_pha4 () { return this.$t('terms[6]') }

        public get section3_title () { return this.$t('terms[7]') }
        public get section3_pha1 () { return this.$t('terms[8]') }
        public get section3_pha2 () { return this.$t('terms[9]') }
        public get section3_pha3 () { return this.$t('terms[10]') }
        public get section3_pha4 () { return this.$t('terms[11]') }

        public get section4_title () { return this.$t('terms[12]') }
        public get section4_pha1 () { return this.$t('terms[13]') }
        public get section4_pha2 () { return this.$t('terms[14]') }
        public get section4_pha3 () { return this.$t('terms[15]') }
        public get section4_pha4 () { return this.$t('terms[16]') }

        public get section5_title () { return this.$t('terms[17]') }
        public get section5_pha1 () { return this.$t('terms[18]') }
        public get section5_pha2 () { return this.$t('terms[19]') }

        public get section6_title () { return this.$t('terms[20]') }
        public get section6_pha1 () { return this.$t('terms[21]') }
        public get section6_pha2 () { return this.$t('terms[22]') }
        //public get section6_pha3 () { return this.$t('terms[]') }

        public get section7_title () { return this.$t('terms[23]') }
        public get section7_pha1 () { return this.$t('terms[24]') }
        public get section7_pha2 () { return this.$t('terms[25]') }
        public get section7_pha3 () { return this.$t('terms[26]') }
        public get section7_pha4 () { return this.$t('terms[27]') }

        public get section8_title () { return this.$t('terms[28]') }
        public get section8_pha1 () { return this.$t('terms[29]') }
        public get section8_pha2 () { return this.$t('terms[30]') }
        public get section8_pha3 () { return this.$t('terms[31]') }

        public get section9_title () { return this.$t('terms[32]') }
        public get section9_pha1 () { return this.$t('terms[33]') }
        public get section9_pha2 () { return this.$t('terms[34]') }
        public get section9_pha3 () { return this.$t('terms[35]') }

        public get section10_title () { return this.$t('terms[36]') }
        public get section10_pha1 () { return this.$t('terms[37]') }

        public get section11_title () { return this.$t('terms[38]') }
        public get section11_pha1 () { return this.$t('terms[39]') }
        public get section11_pha2 () { return this.$t('terms[40]') }

        public get section12_title () { return this.$t('terms[41]') }
        public get section12_pha1 () { return this.$t('terms[42]') }

        public get section13_title () { return this.$t('terms[43]') }
        public get section13_pha1 () { return this.$t('terms[44]') }
        public get section13_pha2 () { return this.$t('terms[45]') }
        public get section13_pha3 () { return this.$t('terms[46]') }
        public get section13_pha4 () { return this.$t('terms[47]') }
        public get section13_pha5 () { return this.$t('terms[48]') }

        public get section14_title () { return this.$t('terms[49]') }
        public get section14_pha1 () { return this.$t('terms[50]') }

        public get section15_title () { return this.$t('terms[51]') }
        public get section15_pha1 () { return this.$t('terms[52]') }

        public get section16_title () { return this.$t('terms[53]') }
        public get section16_pha1 () { return this.$t('terms[54]') }
        public get section16_pha2 () { return this.$t('terms[55]') }
        public get section16_pha3 () { return this.$t('terms[56]') }

        public get section17_title () { return this.$t('terms[57]') }
        public get section17_pha1 () { return this.$t('terms[58]') }
        public get section17_pha2 () { return this.$t('terms[59]') }
        public get section17_pha3 () { return this.$t('terms[60]') }

        public get section18_title () { return this.$t('terms[61]') }
        public get section18_pha1 () { return this.$t('terms[62]') }
        public get section18_pha2 () { return this.$t('terms[63]') }

        public get section19_title () { return this.$t('terms[64]') }
        public get section19_pha1 () { return this.$t('terms[65]') }

        created() {
            AnalyticsUtils.visit(this.$route);
        }
        
        mounted() {
            setTimeout(() => {
                store.isLoadingRoute = false;
            }, 1000);
        }
    }
