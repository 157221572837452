
    import { Vue, Options } from 'vue-class-component';
    import service from "@/services/api";
    
    interface UserRegistration {
        password: string
        username: string
        captcha: string
    }

    @Options({
        name: 'signin-modal',
        inheritAttrs: false,
        emits: ['confirm', 'cancel']
    }) 
    export default class SigninModal extends Vue {
        
        message: string = '';
        isSigningIn = false;
        signindata: UserRegistration = {
            password: '', 
            username: '',
            captcha: ''
        };

        // translations
        public get actions_signin () { return this.$t('actions.signin') }
        public get actions_cancel () { return this.$t('actions.cancel') }
        public get labels_username () { return this.$t('labels.username') }
        public get labels_password () { return this.$t('labels.password') }
        public get labels_forgotpassword () { return this.$t('labels.forgotpassword') }
        public get labels_signin () { return this.$t('labels.signin') }


        async signin() {
            if (!this.signindata.username || !this.signindata.password) {
                return;
            }

            this.isSigningIn = true;
            var result = await service.postToApi("security/signin", { body: this.signindata});
            console.log(result);
            this.isSigningIn = false;

            if(result != null) {
                if(result.data.success != false) {
                    /* exp.setData(result);
                    prefetch("/home").then(()=> {
                        goto("/home").then(()=> {
                            console.log("goto NAVIGATING");
                        });
                    }); */
                } else if(result.data.success == false) {
                    this.message = result.data.message;
                }
            }
        }
    }

