import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mx-1 lg:-mx-4" }
const _hoisted_2 = { class: "range" }
const _hoisted_3 = { class: "range-labels" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "range",
        min: "1",
        max: "6",
        step: "1",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentSelection) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        class: "range blue"
      }, null, 544), [
        [_vModelText, _ctx.currentSelection]
      ])
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: option.id,
          onClick: (e) => _ctx.onLevelSelected(i),
          class: _normalizeClass({'active': _ctx.currentSelection >= i, 'selected': _ctx.currentSelection >= i})
        }, _toDisplayString(option.title), 11, _hoisted_4))
      }), 128))
    ])
  ]))
}