import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65faf514"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "resizeLeftRef",
  class: "resizable left"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "name cut-text" }
const _hoisted_4 = { class: "frame-name" }
const _hoisted_5 = { class: "frame-duration" }
const _hoisted_6 = {
  ref: "resizeRightRef",
  class: "resizable right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "dom",
    class: _normalizeClass(["block", {'selected': _ctx.frame.isSelected}]),
    style: _normalizeStyle({width: _ctx.width, left: _ctx.left, top: _ctx.top}),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.testEvent && _ctx.testEvent(...args))),
    onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, null, 512),
    (_ctx.frame.imagepath)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "preview",
          src: _ctx.frame.imagepath,
          width: "64px",
          alt: "."
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.frame.name), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(+_ctx.frame.start.toFixed(2)) + "-" + _toDisplayString(+_ctx.frame.end.toFixed(2)) + " | " + _toDisplayString(_ctx.frame.duration) + "s", 1)
    ]),
    _createElementVNode("div", _hoisted_6, null, 512)
  ], 38))
}