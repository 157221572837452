import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41a7e3e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "fill-effect-stroke" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "button-see-more fill-effect noselect",
    title: _ctx.percentage + '%'
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.letter), 1),
    _createElementVNode("div", {
      class: "fill-effect-fill",
      style: _normalizeStyle({'clip-path': 'inset(' + (100 - _ctx.percentage) + '% 0 -30px 0)'})
    }, _toDisplayString(_ctx.letter), 5)
  ], 8, _hoisted_1))
}