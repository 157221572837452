import { createApp } from 'vue'
import App from './App.vue'
import "normalize.css"
import './assets/tailwind.css'
import './index.scss'
import './assets/styles/dashboard.scss';
import router from './router/index'
import i18n from './i18n'
/* import Ads from 'vue-google-adsense'
import Adsense from 'vue-google-adsense/dist/Adsense.min.js' */
import vfmPlugin from 'vue-final-modal'
import { VueReCaptcha } from "vue-recaptcha-v3";
import ScriptX from 'vue-scriptx';
import VueGtag from "vue-gtag";
//import AnalyticsUtils from "@/services/analytics";

const GAmeasumentId: string = process.env.VUE_APP_GTAG4_ID || '';

const app = createApp(App);

app.use(VueReCaptcha, { siteKey: "6Lc0wkEbAAAAAErGwgrpXWscZbNZGO4mwplEWTE3" });
app.use(ScriptX)
app.use(i18n);
app.use(router)
app.use(require('vue-script2'))
/* app.use(Ads.Adsense)
app.use(Adsense) */
app.use(vfmPlugin)

// https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
app.use(VueGtag, {
  config: { 
    id: GAmeasumentId, 
    params: {
      send_page_view: false // single page applications, If you send manual pageviews without disabling pageview measurement, you may end up with pageviews counted twice.
    }
  }
});

/* app.config.errorHandler = (err) => {
  // handle error 
  AnalyticsUtils.exceptionEvt(err)
  console.log("GLOBAL ERROR CAPTURED: " + err)
} */

app.mount('#app')
