
import { Vue, Options } from 'vue-class-component';
import LyricsEditorTimeline from '@/lib/LyricsEditor/index.vue';
import store from "@/stores";
import service from "@/services/api";
import Analytics from "@/services/analytics";

@Options({
    components: {
        LyricsEditorTimeline
    }
})
export default class LyricsEditorIndex extends Vue {

    isLoading = true;
    lyricsdata = { id: ''}

    created() {
        Analytics.visit(this.$route);
    }

    async mounted() {
        store.isLoadingRoute = false;

        const slug = this.$route.params.slug;
        this.lyricsdata = JSON.parse(await service.fetchFromApi({ url: "dashboard/lyricbyid/" + slug }));

        console.log(this.lyricsdata)
    }

    async onSave(lyric) {
        console.log(lyric.detail);
        let cloneLyric = JSON.parse(JSON.stringify(lyric.detail));

        // clean the characters array from each verse
        if (cloneLyric) {
            //currentLang.setIsLoading(true);

            cloneLyric.verses.forEach(verse => {
                verse.characters = [];
            });

            await service.putToApi("dashboard/lyric", { body: cloneLyric });

            // show dialog
            //currentLang.setIsLoading(false);
        }

    }
    isEditorReady() {
        this.isLoading = false;
    }
}
