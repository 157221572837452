// https://matteo-gabriele.gitbook.io/vue-gtag/
import { event, pageview, time } from 'vue-gtag'
import Helpers from '@/services/helpers';

class AnalyticsUtils {
  
  sendEvent(evtname, data) {
    Helpers.lgi("sendEvent:::: ")
    event(evtname, data)
  }

  exceptionEvt(err) {
    Helpers.lgi("exceptionEvt:::: " + err)
    event('exception', { description: err, 'fatal': false })
  }

  visit(page) {
    Helpers.lgi("visit:::: " + page)
    pageview(page);
  }

  loadtime(ms){
    Helpers.lgi("loadtime:::: " + ms)
    time({ name: "load", value: ms, 'event_category' : 'home draco model'});
  }
}

export default new AnalyticsUtils();
