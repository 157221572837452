import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto about-section" }
const _hoisted_2 = { class: "bg-white p-4 m-4 md:p-8 md:m-8 lg:p-24 lg:m-24" }
const _hoisted_3 = {
  class: "w-full max-w-xs flex flex-col py-5 px-8",
  action: ""
}
const _hoisted_4 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}
const _hoisted_5 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclaimer = _resolveComponent("Disclaimer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("form", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.labels_email), 1),
          _withDirectives(_createElementVNode("input", {
            class: "text-gray-700 shadow border rounded border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3",
            type: "text",
            placeholder: "Email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.datarecover.email) = $event)),
            disabled: ""
          }, null, 512), [
            [_vModelText, _ctx.datarecover.email]
          ]),
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.labels_code), 1),
          _withDirectives(_createElementVNode("input", {
            class: "text-gray-700 shadow border rounded border-gray-300 mb-3 py-1 px-3 focus:outline-none focus:shadow-outline",
            type: "number",
            placeholder: "Code",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.datarecover.code) = $event))
          }, null, 512), [
            [_vModelText, _ctx.datarecover.code]
          ]),
          _createElementVNode("button", {
            class: "btn navbar-toggler link-medium mt-4 mb-2",
            onClick: _cache[2] || (_cache[2] = async ()=> await _ctx.recover())
          }, _toDisplayString(_ctx.actions_generate), 1)
        ])
      ])
    ]),
    _createVNode(_component_Disclaimer)
  ], 64))
}