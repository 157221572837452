
    import { Vue, Options } from 'vue-class-component';
    import CardGrid from '@/lib/CardGrid/card_grid.vue';
    import service from "@/services/api";
    import NewLyricModal from '@/lib/Modals/new_lyric_modal.vue';
    import store from "@/stores";
    import Analytics from "@/services/analytics";
    
    @Options({
        components: {
            CardGrid,
            NewLyricModal
        },
    }) 
    export default class DashboardContributions extends Vue {
        
    // translations
    public get actions_add_lyric () { return this.$t('actions.add_lyric') }

    showModal = false
    items = [];
    title = '';

    created() {
        Analytics.visit(this.$route);
    }

    async mounted() {
        this.items = JSON.parse(await service.fetchFromApi({ url: "dashboard/getContributions" }));

        setTimeout(() => {
            store.isLoadingRoute = false;
            store.isLoading = false;
        }, 1000);
    }

    add_lyric() {
        this.showModal = true
    }

    onItemSelected(event) {
        this.$router.push({ path: `/lyrics/editor/${event.detail.id}`});
    }
}
