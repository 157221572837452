
    import { Vue } from 'vue-class-component';
    import store from "@/stores";
    import Analytics from "@/services/analytics";
    
    export default class DashboardFavorites extends Vue {
        
      created() {
          Analytics.visit(this.$route);
      }
      
      async mounted() {

          // this.items = JSON.parse(await service.fetchFromApi({ url: "dashboard/getHistory" }));

          setTimeout(() => {
              store.isLoadingRoute = false;
              store.isLoading = false;
          }, 1000);
      }

    }
