import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-page flex h-screen justify-center items-center" }
const _hoisted_2 = { class: "object-center" }
const _hoisted_3 = { class: "text-base md:text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoCmp = _resolveComponent("LogoCmp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LogoCmp),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.isLoadingMessage), 1)
    ])
  ]))
}