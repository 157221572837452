import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewLyricModal = _resolveComponent("NewLyricModal")!
  const _component_SidebySideLyricModal = _resolveComponent("SidebySideLyricModal")!
  const _component_CardGrid = _resolveComponent("CardGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "btn navbar-toggler btn-medium",
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add_lyric && _ctx.add_lyric(...args)))
    }, _toDisplayString(_ctx.actions_add_lyric), 1),
    _createVNode(_component_NewLyricModal, {
      showModal: _ctx.showModal,
      onClose: _ctx.onNewLyricModalClose
    }, null, 8, ["showModal", "onClose"]),
    _createVNode(_component_SidebySideLyricModal, {
      ref: "sideBySideModal",
      verses: _ctx.lyricSelected,
      showModal: _ctx.showSideBySideModal,
      onClose: _ctx.onSideBySideLyricModalClose
    }, null, 8, ["verses", "showModal", "onClose"]),
    (_ctx.items && _ctx.items.length > 0)
      ? (_openBlock(), _createBlock(_component_CardGrid, {
          key: 0,
          items: _ctx.items,
          onSelected: _ctx.onItemSelected
        }, null, 8, ["items", "onSelected"]))
      : _createCommentVNode("", true)
  ], 64))
}