
    import { Options, Vue } from 'vue-class-component';

    @Options({
        props: {
            letter: String,
            percentage: Number
        },
        emits: ['selected']
    })
    export default class PercentageLetter extends Vue {

        letter: string = ''
        percentage: number = 0

    }
