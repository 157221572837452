
    import { Vue, Options } from 'vue-class-component';
    import SliderLevelCards from '@/lib/Slider/level_cards.vue';
    import AnalyticsUtils from "@/services/analytics";
    import service from "@/services/api";
    
    @Options({
        props: {
            showModal: Boolean
        },
        components: {
            SliderLevelCards
        },
        emits: ['saved']
    }) 
    export default class UserLevelModal extends Vue {
         
        selectedLevel: string = '';
        isLoading: boolean = false;
        showModal: boolean = false;
        hard_topic: string = '';
        /* line0 = ref<InstanceType<typeof AudioLyricPlayer>>() */

        // translations
        public get title () { return this.$t('titles.current_level') }
        public get level_modal_subtitle () { return this.$t('titles.level_modal_subtitle') }
        public get actions_continue () { return this.$t('actions.continue') }

        async created() {

            
        }
        
        async onSelectedLevel(level) {
            this.selectedLevel = level

            const levelTitle = (level == 1 ? 'beginner' : (level == 2 ? 'intermediate' : 'advanced'));
            AnalyticsUtils.sendEvent("starting_userlevel", { userlevel: levelTitle });
        }

        async save() {
            this.$emit("saved");

            let publicIp: string = '';
            var pubip = await service.getClientApi();
            if (pubip) {
                publicIp = pubip.data.ip;
                console.log("PUBLIC IP: " + publicIp);
            }

            JSON.parse(await service.postToApi("search/lyrics", { body: { level: this.selectedLevel, feedback: this.hard_topic, publicIp } } ))
        }
    }
