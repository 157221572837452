
    import { Vue, Options } from 'vue-class-component';
    import Slider from "@/lib/Slider/index.vue";
    import SliderCards from "@/lib/Slider/cards.vue";
    import service from "@/services/api";
    import store from "@/stores";
    //import SongLevelSummaryModal from '@/lib/Modals/song_level_summary_modal.vue';
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import PercentageLetter from '@/lib/PercentageLetter/index.vue';
    import AnalyticsUtils from "@/services/analytics";

    interface dataStructure {
        songTitle: string 
        bandName: string
        thumbnailAbsoluteUrl: string
        totalKanjisN1: number
        totalKanjisN2: number
        totalKanjisN3: number
        totalKanjisN4: number
        totalKanjisN5: number
        totalKanjisOther: number
        kanjisN1: any
        kanjisN2: any
        kanjisN3: any
        kanjisN4: any
        kanjisN5: any
        kanasUnique: any
        kanjisOther: any,
        sessionId: any
        lastPlayed: string
    }
    @Options({
        components: {
            Slider, SliderCards, /* SongLevelSummaryModal, */ Disclaimer, PercentageLetter
        },
    }) 
    export default class SelectMode extends Vue {

        options: dataStructure = { 
            songTitle: '', 
            bandName: '', 
            thumbnailAbsoluteUrl: '',
            totalKanjisN1: 0, 
            totalKanjisN2: 0, 
            totalKanjisN3: 0, 
            totalKanjisN4: 0, 
            totalKanjisN5: 0, 
            totalKanjisOther: 0, 
            kanjisN1: [], 
            kanjisN2: [], 
            kanjisN3: [], 
            kanjisN4: [], 
            kanjisN5: [], 
            kanasUnique: [],
            kanjisOther: [],
            sessionId: '',
            lastPlayed: ''
        };
        defaultLevels: any = [];
        selection: number = 0;
        totalKanji = 0;
        totalKana = 0;
        kanjiList: any = [];
        kanaList: any = [];
        isLoadingLyric = false;
        scenarios: any = [];
        playerType: string = '';
        openSummary: boolean = false;
        reviewItemsList: any = [];

        // translations
        public get actions_start () { return this.$t('actions.start') }
        public get actions_select_level () { return this.$t('actions.select_level') }
        public get title_review_items () { return this.$t('titles.review_items') }
        public get label_last_played () { return this.$t('labels.last_played') }
        public get label_first_time () { return this.$t('labels.first_time') }

        async created() {
            AnalyticsUtils.visit(this.$route);

            this.isLoadingLyric = true;
            
            const slug = this.$route.params.lyricid;
            if (!slug) 
                this.$router.push({ path: `/lyrics`}); // go back to pick a song

            this.options = JSON.parse(await service.fetchFromApi({url: "lyrics/getLevels/" + slug}));
            console.log(this.options);
            
            this.defaultLevels = [
                { id: 0, title: 'Karaoke',                      key: 'karaoke',  order: 6, totalKanji: 0, description: "", extras: 0, level: '',   color: '', thumbnail: 'tic-tac-toe.png', isSelected: true },
                { id: 1, title: this.$t('actions.veryeasy'),    key: 'veryeasy',   order: 1, totalKanji: 0, description: "", extras: 0, level: 'Kana', color: '', thumbnail: 'tic-tac-toe.png', isSelected: false },
                { id: 2, title: this.$t('actions.easy'),        key: 'easy',     order: 2, totalKanji: this.options.totalKanjisN5, description: "", extras: 0, level: 'N5', color: '', thumbnail: 'tic-tac-toe.png', isSelected: false },
                { id: 3, title: this.$t('actions.difficult'),   key: 'difficult',  order: 3, totalKanji:  this.options.totalKanjisN4, description: "", extras: 0, level: 'N4', color: '', thumbnail: 'tic-tac-toe.png', isSelected: false },
                { id: 4, title: this.$t('actions.challenging'), key: 'challenging',  order: 3, totalKanji: this.options.totalKanjisN3, description: "", extras: 0, level: 'N3', color: '', thumbnail: 'tic-tac-toe.png', isSelected: false },
                { id: 5, title: this.$t('actions.hard'),        key: 'hard',       order: 4, totalKanji: this.options.totalKanjisN2, description: "", extras: 0, level: 'N2', color: '', thumbnail: 'tic-tac-toe.png', isSelected: false },
                { id: 6, title: this.$t('actions.veryhard'),    key: 'veryhard',    order: 5, totalKanji: this.options.totalKanjisN1 + this.options.totalKanjisOther, description: "", extras: 0, level: 'N1', levelextra:'+ Jōyō', color: '', thumbnail: 'tic-tac-toe.png', isSelected: false },
            ];

            this.scenarios = [
                { id: 0, title: 'Background Video', thumbnail: 'videobackground.PNG' },
                { id: 1, title: 'Tunnel', thumbnail: 'tunnel_loop.PNG' }
            ];

            this.updateSlider();

            // TODO: set the default scenario for this song, if its a regular user the video background
            
            this.isLoadingLyric = false;
        }

        mounted() {
            setTimeout(() => {
                store.isLoadingRoute = false;
            }, 2000);
        }

        onsliderupdate(e: number) {
            this.selection = e;
            this.updateSlider();
        }
        updateSlider() {
            this.kanjiList = [];

            // reset variables
            this.totalKanji = 0;
            this.totalKana = 0;
            this.kanjiList = [];
            this.kanaList = [];

            console.log("selection: " + this.selection);
            if (this.selection == 0) {

                this.reviewItemsList = [];

            } else if (this.selection == 1) { // veryeasy (Beginner)

                // KANA ONLY
                this.totalKanji = 0;
                this.totalKana = this.options.kanasUnique.length;
                this.reviewItemsList = this.options.kanasUnique.map((e: any)=>{
                    return {item: e.kana, percentage: 0};
                });

                
            } else if (this.selection == 2) { // easy

                this.totalKanji = this.options.totalKanjisN5;
                this.kanjiList = this.options.kanjisN5;
                
                this.reviewItemsList = this.kanjiList.map((e: any)=>{
                    return {item: e, percentage: 45};
                });

            } else if (this.selection == 3) { // Difficult 

                this.totalKanji = this.options.totalKanjisN4;
                this.kanjiList = this.options.kanjisN4;
                
                this.reviewItemsList = this.kanjiList.map((e: any)=>{
                    return {item: e, percentage: 45};
                });

            } else if (this.selection == 4) { // challenging 

                this.totalKanji = this.options.totalKanjisN3;
                this.kanjiList = this.options.kanjisN3;

                this.reviewItemsList = this.kanjiList.map((e: any)=>{
                    return {item: e, percentage: 45};
                });

            } else if (this.selection == 5) { // Hard

                this.totalKanji = this.options.totalKanjisN2;
                this.kanjiList = this.options.kanjisN2;

                this.reviewItemsList = this.kanjiList.map((e: any)=>{
                    return {item: e, percentage: 45};
                });

            } else if (this.selection == 6) {  // veryhard

                this.totalKanji = this.options.totalKanjisN1 + this.options.totalKanjisOther;
                this.kanjiList = [this.options.kanjisN1, ...this.options.kanjisOther];

                this.reviewItemsList = this.kanjiList.map((e: any)=>{
                    return {item: e, percentage: 45};
                });

            }

            console.log("this.totalKanji: " + this.totalKanji + " this.kanjiList: " + this.kanjiList);
        }

        // select the scene type
        onItemSelected(event) {
            this.selection = event.detail.key;
            console.log(event);      
        }

        confirmSelection() {
            if (!this.selection) {
                this.selection = 0;
            }
            this.isLoadingLyric = true;
            var key = this.defaultLevels[this.selection].key;

            // by default auto play on youtube background video player
            this.playerType = 'youtube';
            
            this.$router.push({ path: `/lyrics/${this.playerType}/${this.options.sessionId}/${key}`});
        }    
    }
