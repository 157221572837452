import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicHeader = _resolveComponent("PublicHeader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PublicHeader),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: route.path
              }))
            ])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ], 64))
}