import { Vue, Options } from 'vue-class-component';
//import { Ref } from 'vue-property-decorator'
import * as wanakana from 'wanakana';
import YouTube from 'vue3-youtube'
import gameUtils from "@/stores/gameUtils";
import { ref } from 'vue';

@Options({
    components: {
        YouTube
    },
    props: {
        lyrics: Object,
        autoPlay: Boolean
    },
    emits: ['successanswer', 'nextoptions', 'gamefinished', 'loading', 'doneloading', 'onSave', 'onReady', 'itemposition'] // , 'versePlay'
})
export default class LyricsPlayer extends Vue {

    //public toComplete: any = [];
    public lyrics;
    public autoPlay: boolean = false;
    public assignedId: number = 1;
    //public currentLine: number = 0; 
    //public currentCharacter: number = 1; 
    isChangingVerse: boolean = false; 
    timeline;
    isVideoComplete: boolean = false; 
    isPlaying: boolean = false; 
    isBuffering: boolean = false; 
    errorThrown: boolean = false;
    playOnlyCurrentVerse = false;
    //currentInputPosition: number = 0;
    isTimelineReady: boolean = false;

    //@Ref() player!: any
    player: any = ref(null)

    // translations
    public get labels_duration () { return this.$t('labels.duration') }
    public get labels_minutes () { return this.$t('labels.minutes') }
    public get labels_song_title () { return this.$t('labels.song_title') }
    public get labels_band_name () { return this.$t('labels.band_name') }
    public get labels_year () { return this.$t('labels.year') }
    public get actions_play () { return this.$t('actions.play') }
    public get actions_pause () { return this.$t('actions.pause') }
    public get actions_go_next_verse () { return this.$t('actions.go_next_verse') }
    public get actions_go_prev_verse () { return this.$t('actions.go_prev_verse') }
    public get actions_save () { return this.$t('actions.save') }
    public get actions_goback () { return this.$t('actions.goback') }
    public get actions_repeat_verse () { return this.$t('actions.repeat') }
    public get actions_skip () { return this.$t('actions.skip') }

    mounted() {

    }
   
    playLine() {
        const currentLineObj = this.lyrics.verses.find(x => x.id == gameUtils.currentLine);
        const startSeconds = currentLineObj.start / 1000;
        console.log("STARTING AGAIN FROM LINE " + currentLineObj.id + " ms: " + currentLineObj.start + " second: " + startSeconds);

        this.player.seekTo(startSeconds);
        //this.player.playVideo();
        this.playAndFadeInVolume();

        if (this.timeline)
            this.timeline.seek(currentLineObj.start);

        this.isChangingVerse = false;
        this.isPlaying = true;

        if (this.timeline)
            this.timeline.play();
    }

    playPreviousLine() {
        this.isChangingVerse = true;
        gameUtils.currentLine--;

        if (this.timeline)
            this.timeline.pause();
        
        this.playLine();
    }
    replayCurrentLine() {
        this.isChangingVerse = true;
        
        if (this.timeline)
            this.timeline.pause();
        
        this.playLine();
    }
    playNextLine() {
        this.isChangingVerse = true;

        gameUtils.currentLine++;

        if (this.timeline)
            this.timeline.pause();
        
        this.playLine();
    }

    resumeTimer() {
        this.isPlaying = true;
        this.playAndFadeInVolume();
    }
    stopTimer() {
        this.isPlaying = false;
        this.player.pauseVideo();

        if (this.timeline)
            this.timeline.pause();
    }   
    startTimer() {
        if (!this.isPlaying) {
            this.isPlaying = true;
            this.playAndFadeInVolume(); 
            //this.$emit('startPlaying', true);
        } else {
            this.isPlaying = false;
            this.timeline.pause();
            this.pauseAndFadeOutVolume();
        }
    }
    togglePlay(){
        switch(this.isPlaying) 
        {
            case true:
                this.stop();
                break;
            case false:
                this.play();
                break;
        }
    }
    play() {
        if (!this.isPlaying) {
            this.isPlaying = true;

            this.playAndFadeInVolume(); 
        }
    }
    stop() {
        if (this.isPlaying) {
            this.isPlaying = false;
            
            this.timeline.pause();
            this.pauseAndFadeOutVolume();
        } 
    }
    setTime(time) {
        this.player.currentTime = Math.max( 0, time );
        // timeChanged.set( player.currentTime );
    }
    getPlayerVolume() {
        return this.player ? this.player.getVolume() : 0
    }
    playAndFadeInVolume() {
        this.player.setVolume(100);
        this.player.playVideo();
    }
    pauseAndFadeOutVolume() {
        const interval = setInterval(() => { 
            if (this.player.getVolume() > 0) {
                this.changeVolume(0)
            } else {
                this.player.pauseVideo();
                clearInterval(interval);
            }
        }, 10 );
    }
    changeVolume(i: number) {
        if(i > 0) {
            this.player.setVolume(this.player.getVolume() + 1);
        } else {
            this.player.setVolume(this.player.getVolume() - 1);
        }
    }

    updateCharacterStyle(currentInputPosition: number, isCorrect = false, isSkipped: boolean = false) {
        if (!currentInputPosition) return;
        if (document.getElementById("ch-" + currentInputPosition) != null) {
            document.getElementById("ch-" + currentInputPosition)!.classList.remove("glowing");
            if (isCorrect) {
                document.getElementById("ch-" + currentInputPosition)!.classList.remove("incorrect"); // if this is the 2nd, 3rd... try
                document.getElementById("ch-" + currentInputPosition)!.classList.add("correct");
            } else if (!isCorrect) {
                document.getElementById("ch-" + currentInputPosition)!.classList.add("incorrect");
                // once is answered correctly we dont need to remove the correct class unless we restart the line (feature not supported yet)
            } else if (isSkipped) {
                document.getElementById("ch-" + currentInputPosition)!.classList.remove("incorrect");
                document.getElementById("ch-" + currentInputPosition)!.classList.add("skipped");
            }
        }
    }

    restartCompletion = () => {
        if (gameUtils.toCompleteQueue && gameUtils.toCompleteQueue.length > 0) {
            gameUtils.toCompleteQueue.forEach(el => {
                el.isComplete = false;
                el.isSkipped = false;
                el.numberOfRepetitions = 0;
            });
        } else {
            gameUtils.toCompleteQueue = [];
        }
    }

    findAndSetReferenceVerse(lyric) {
        if (lyric.refId) {
            const refObj = this.lyrics.verses.find(x => x.id == lyric.refId);
            if (refObj) {
                const refObjClone = JSON.parse(JSON.stringify( refObj ));
                
                lyric.text = refObjClone.text;
                lyric.romaji = refObjClone.romaji;
                lyric.furigana = refObjClone.furigana;
                lyric.es = refObjClone.es;
                lyric.en = refObjClone.en;
                //lyric.spec = refObjClone.spec;       
            }
        }
    }
    setUpLyrics() {
        this.restartCompletion();

        this.assignedId = 1;
        let totalMs = 0;
        // calculate furigana above each kanji if needed
        this.lyrics.verses.forEach((lyric: any) => {

            totalMs = 0;

            // clone references to build complete lyrics
            this.findAndSetReferenceVerse(lyric);

            const characters = lyric.text.split("");
            lyric.characters = [];

            if (characters && characters.length > 0) {

                characters.forEach((cha, i) => {                      
                    //let specObj = lyric.spec ? lyric.spec.find(x => x.pos == i) : undefined;
                    //let specObj = lyric.spec.find(x => x.pos == i);
                    //console.log(cha + " >> " + i + " characters (" + characters.length + ") lyric.furigana (" + lyric.furigana.length + ") lyric.delay (" + lyric.delay.length + ") >>> " + (characters.length == lyric.furigana.length && lyric.furigana.length == lyric.delay.length));
                    const furigana = lyric.furigana[i];
                    let delay = lyric.delay[i];
                    let kana = '';
                    let isToComplete = false;

                    if (wanakana.isHiragana(cha) || wanakana.isKatakana(cha)) {
                        // there could be KANAS to complete (beginner level)
                        const questionObj = gameUtils.toCompleteQueue.find(x => x.kana == cha );
                        if (questionObj) {
                            //questionObj.line = l;
                            //console.log("FOUND KANA TO COMPLETE: " + JSON.stringify(questionObj));
                            isToComplete = true;
                            // the kana wont come in the specs array
                            kana = questionObj.kana;
                        }
                    } else {  
                        // KANJI                  
                        const questionObj = gameUtils.toCompleteQueue.find(x => x.kanji == cha /* && x.line == l*/ );
                        if (questionObj) {
                            //console.log("FOUND KANJI TO COMPLETE: " + JSON.stringify(questionObj));
                            isToComplete = true;
                        }
                    }
                
                    if(!delay) { // GOOD?
                        delay = 300;
                    }

                    const characterinfo = {id: this.assignedId, key: cha, furigana: furigana, kana: kana, delay: delay, toComplete: isToComplete};

                    lyric.characters.push(characterinfo);

                    this.assignedId = this.assignedId + 1;

                    totalMs = totalMs + (delay ? delay : 300);
                });
            }

            lyric.totalMs = totalMs;
            lyric.diff = totalMs - (lyric.end - lyric.start);
        });
    }
}