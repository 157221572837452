
    import anime from 'animejs/lib/anime.es.js';
    import LyricsPlayer from './lyrics_player';
    import Helpers from '@/services/helpers';
    import gameUtils from "@/stores/gameUtils";
    import EventBus from '@/services/pubsub'

    export default class LyricPlayer extends LyricsPlayer {         
        isWaitingInput: boolean = false;
        needSyncLyricsAndVideo: boolean = false; // if the video plays the 3 seconds of next verse and user havent anwered we need to go back and start again from current line
        isYTApiReady: boolean = false;
        autoPlay: boolean = true;
        loadingPercentage: number = 0;
        totalDelay: number = 0;
        isLyricSetup: boolean = false;    
        playerVars: any = {
            'autoplay': 1,
            'playsinline': 1,
            'controls': 1,
            'disablekb': 1,
            'fs': 0,
            'rel': 0,
            'cc_load_policy': 0,
            'iv_load_policy': 3,
            'modestbranding': 0,
            'enablejsapi': 1
        }

        // translations
        public get labels_name () { return this.$t('labels.name') }
        public get currentline () { return gameUtils.currentLine }

        async mounted() {
            anime.suspendWhenDocumentHidden = false; // continue animating even if user is in another tab

            // any input method emits this
            /* EventBus.addEventListener('onInputAnswer', (ch: any) => {
                Helpers.lgi("onInputAnswer:::" + ch);
                this.displayInputAnswer(ch);
            }); */
            EventBus.addEventListener('rightAnswer', (evt: any) => {
                Helpers.lgi("rightAnswer::: " + evt.data.answer);
                this.displayInputAnswer(evt.data.answer, evt.data.id);
                this.handleCorrectAnswer(evt.data.id);
            });
            EventBus.addEventListener('wrongAnswer', (evt: any) => {
                Helpers.lgi("wrongAnswer:::");
                this.displayInputAnswer(evt.data.answer, evt.data.id);
                this.updateCharacterStyle(evt.data.id);
            });
            EventBus.addEventListener('skippedAnswer', (evt: any) => {
                Helpers.lgi("skippedAnswer:::");
                this.updateCharacterStyle(evt.data.id);
            });

            this.initTimeline();

            var checkYT = setInterval(() => {           
                if (this.player) {
                    try {
                        const state = this.player.getPlayerState()

                        if (this.isYTApiReady || state == 5) {
                            
                            this.isYTApiReady = true;
                            clearInterval(checkYT);
                            this.onReady();
                        }
                    } catch {
                        this.isYTApiReady = false;
                    }
                }             
            }, 100);
        }

        onReady() {
            setInterval(() => {    
                //Helpers.lgi("CALLING ON READY PLAYER")
                this.isYTApiReady = true;
                if (this.autoPlay && !this.isPlaying) {
                    console.log("AUTOPLAYING... this.isPlaying? " + this.isPlaying + " // player.getPlayerState(): " + this.player.getPlayerState());
                    this.startTimer();
                }
            }, 200);
        }

        // 100 – The video requested was not found, 150/101 – The owner of the requested video does not allow it to be played in embedded players
        onPlayerError(event) {
            console.log("onPlayerError" + event.data);
            this.errorThrown = true;
            /* if (event.data == 100) {

            } else if (event.data == 101 || event.data == 150) {

            } */
        }
        // -1 (unstarted), 0 (ended), 1 (playing), 2 (paused), 3 (buffering), 5 (video cued).
        // YT.PlayerState: ENDED, PLAYING, PAUSED, BUFFERING, CUED
        onPlayerStateChange(event) {
            if (event.data == 1 && !this.isVideoComplete) { // YT.PlayerState.PLAYING
              
                this.timeline.play();
                this.isBuffering = false;
            
            } else if (event.data == 2) { // YT.PlayerState.PAUSED
                console.log("VIDEO PAUSED!!");
                this.timeline.pause();

            } else if (event.data == 5) { // YT.PlayerState.BUFFERING
                console.log("BUFFERING!!");
                
                this.isBuffering = true;
                // TODO: handle scenario when video suddenly stops for buffering
                //stopTimer();

            } else if (event.data == 0) { // YT.PlayerState.ENDED
                console.log("VIDEO ENDED!!");
                this.isVideoComplete = true;
            }
        } 

        displayInputAnswer(kana, id) {
            // set input answered text to the current empty spot in the lyric
            if (document.getElementById("ch-" + id)) {
                document.getElementById("ch-" + id)!.innerHTML = kana;
            }
            if (document.getElementById("fg-" + id)) {
                document.getElementById("fg-" + id)!.setAttribute('t', kana);
            }
        }
        handleCorrectAnswer(characterId/* text, currentObj */ /* , toCompleteObj */) {  
            
            this.updateCharacterStyle(characterId, true);

            console.log("CORRECT ANSWER, playing video!!");

            // if nothing is left to answer and we are waiting for input continue
            //let anythingLeftObj = toComplete.find(x => x.line == currentLine && x.isComplete == false && x.isSkipped == false);
            //if (reachedEndOfLine(totalLineCharacters) && !isCurrentLineIncomplete()) {
            if (!gameUtils.isCurrentLineIncomplete()) {
                // all stopped, start and continue asnwering
                if (!this.needSyncLyricsAndVideo && !this.isPlaying) {
                    this.timeline.play();
                    this.playAndFadeInVolume();

                // responded when it is still playing so just continue like that (no action)
                } else if (!this.needSyncLyricsAndVideo && this.isPlaying) {
                    console.log("590 - !this.needSyncLyricsAndVideo && this.isPlaying");
                } else {
                    gameUtils.currentLine++; // start again from current new line because the user didnt respond on time (before the verse ended)
                    this.playLine();
                }
            } else {
                console.log("CURRENT LINE INCOMPLETE! listening for more input");
                // show next set of options in the puzzle/grid/cards etc
                //** var nextCharacter = this.toComplete.find(x => x.line == this.currentLine && x.isComplete == false && x.isSkipped == false);
                // TODO: get next set of options here??
                /* var toCompleteObjFound = this.toComplete.find(x => x.line == this.currentLine && x.isComplete == false );

                this.$emit('nextoptions', toCompleteObjFound); */
            }
        }
        
        currentCharArrayIndex: number = 0;

        initTimeline() {
            let isFirstVerse = true;
            let percentageIncrement = 100 / this.lyrics.verses.length;

            this.timeline = anime.timeline({
                loop: false,
                autoplay: false,
                easing: 'easeInOutCirc',
            });
            
            this.lyrics.verses.forEach((lyric, verseId) => {     
                            
                let secondsAfterPrevVerse = 0;
                if (isFirstVerse) {

                    // this is just a dummy waiting before the very first verse starts
                    this.timeline
                    .add({
                        target: "#dummy",
                        duration: 0,
                        //delay: +lyric.start
                    }, +lyric.start);

                    isFirstVerse = false;
                    
                    console.log("added delay before first verse " + lyric.start);

                } else if (verseId > 0) { // we are in the last character

                    // setting the spaces between at what time current start based on the previous ending
                    /* let prevVerse = lyrics.verses[verseId - 1]; // TODO: check if this is the last one
                    secondsAfterPrevVerse = +lyric.start - +prevVerse.end;
                    lyric.pauseBeforeNextVerse = secondsAfterPrevVerse; */ // this will be saved to the db

                    secondsAfterPrevVerse = lyric.pauseBeforeNextVerse;

                    //console.log("START PLAYING " + verseId + " " + secondsAfterPrevVerse + " AFTER PREVIOUS VERSE " + (verseId - 1) + "");
                }

                lyric.characters.forEach((character, idx, array) => {              
                    //Helpers.lgi("SETTINGUP CHARACTER::: " + character.c + " ==> "+ idx);

                    let delay = character.d ? character.d : 300;
                    if (idx > 0) {
                        secondsAfterPrevVerse = 0;
                    }

                    this.totalDelay = this.totalDelay + delay;

                    this.timeline
                    .add({
                        targets: "#ch-" + character.i,
                        color: [ "#879196", "#607d8b", "#455a65", "#e5e7e8" ],
                        scale: 1.1,
                        duration: delay,
                        begin: ()=> {
                            //console.log("PLAYING (" + verseId + ") --> ID: (" + character.id + ") " + character.key + " >>> delay: " + delay + "    video time >>> " + this.player.getCurrentTime() + " secondsAfterPrevVerse: " + secondsAfterPrevVerse);
                            if (!this.isPlaying) {
                                //console.log("+++++++++++++ AVOIDED TO CONTINUE +++++++++++++");
                                this.timeline.pause();
                            }
                        },
                        complete: ()=> {
                            gameUtils.currentLine = verseId;
                            Helpers.lgi("READ::: " + character.c + " // this.currentLine: "+ gameUtils.currentLine);
                            //this.$emit('versePlay', verseId);

                            // LOG the start timestamp when the toComplete character was READ and the user is able to respond
                            // dont start the time on already answered, only skipped

                            if (character.q && character.q == true) { // this ch is to complete
                                Helpers.lgw("WAITING TO COMPLETE:::: " + character.i);
                                const startTime = new Date().getTime()
                                character.startTime = startTime;

                                // we need to complete this character, emit the position for the keyboard to popup
                                var coords = Helpers.getCoords("ch-" + character.i);
                                gameUtils.updatePopupPosition({coords, id: character.i, startTime })

                                EventBus.emit("waitinganswer")
                            }

                            if (idx === array.length - 1) { 
                                Helpers.lgi("LAST ELEMENT OF VERSE " + verseId);
                                
                                // the lyrics stop but the video continues 3 more seconds
                                if (gameUtils.isCurrentLineIncomplete()) {
                                    this.timeline.pause();
                                    this.isWaitingInput = true;
                                    this.needSyncLyricsAndVideo = true;

                                    setTimeout((() => {
                                        // let the user think before stopping completley the video
                                        // at this point the user could have change lines manually so double check we didnt start over from another line
                                        if (gameUtils.isCurrentLineIncomplete()) {
                                            this.isWaitingInput = true;
                                            Helpers.lgw("PAUSING...");
                                            
                                            this.isPlaying = false;
                                            this.pauseAndFadeOutVolume();
                                        } else {
                                            this.isPlaying = true;
                                            this.isWaitingInput = false;
                                        }
                                    }), 2000);

                                } else {
                                    this.needSyncLyricsAndVideo = false;
                                    this.isPlaying = true;
                                    Helpers.lgw("CONTINUING TO LINE " + gameUtils.currentLine);

                                    Helpers.scrollTo("#ly-" + gameUtils.currentLine);
                                }
                            }
                        }
                        
                        // TODO: test from DB. 
                    }, '+=' + secondsAfterPrevVerse); // offset instead of adding empty delays to last character

                });

                this.loadingPercentage = this.loadingPercentage + percentageIncrement;
                //store.loaderMessage = 'Loading ' + this.loadingPercentage + '%...';
                //currentLang.setIsLoadingMessage('Generating lyrics ' + Math.trunc(this.loadingPercentage) + '%');
                this.$emit('loading', this.loadingPercentage);
            });

            this.$emit('doneloading', true);
            EventBus.emit('playerready');
            console.log("totalDelay " + this.totalDelay);

            this.timeline.finished.then(() => {
                console.log("///// LYRIC END /////");
                this.$emit('gamefinished');
            });
        }

        goToPreviousLine() {
            this.playPreviousLine();

            // of we go back one line that means we are repeating that line
            gameUtils.replayCurrentLine();
        }
        goToNextLine() {
            this.playNextLine();

            // set toCompleteObj.isSkipped == true; before going to next line
            gameUtils.skipCurrentToComplete();
        }
        repeatLine() {
            this.replayCurrentLine();
        }
        goBack() {
            this.destroyComponent()
            this.$router.push({ path: `/lyrics` });
        }
        unmount () {
            Helpers.lgw("UNMOUNT CALLED!!")
            this.destroyComponent()
        }
        destroyComponent(){
            try {
                this.player.pauseVideo();
                this.timeline.pause();

                this.player = null;
                this.timeline = null;
            } catch(e){
                console.log("ERROR " + e);
            }
        }
    }
