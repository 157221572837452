import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto about-section" }
const _hoisted_2 = { class: "bg-white p-4 m-4 md:p-8 md:m-8 lg:p-24 lg:m-24" }
const _hoisted_3 = { class: "font-semibold text-base md:text-3xl mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclaimer = _resolveComponent("Disclaimer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.labels_redirecting), 1)
      ])
    ]),
    _createVNode(_component_Disclaimer)
  ], 64))
}