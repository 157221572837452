import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05eae3e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "spinner mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedbackModal = _resolveComponent("FeedbackModal")!
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_LyricsPlayer = _resolveComponent("LyricsPlayer")!
  const _component_FourOptionsGrid = _resolveComponent("FourOptionsGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FeedbackModal, { showModal: _ctx.askForFeedback }, null, 8, ["showModal"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingScreen, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isLoadingLyric)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.lyricsdata && _ctx.lyricsdata.id)
      ? (_openBlock(), _createBlock(_component_LyricsPlayer, {
          key: 2,
          lyrics: _ctx.lyricsdata,
          autoPlay: true,
          onLoading: _cache[0] || (_cache[0] = async () => _ctx.onLoading),
          onDoneloading: _ctx.onDoneLoading,
          onSuccessanswer: _ctx.onSuccessAnswer,
          onNextoptions: _ctx.nextToComplete,
          onGamefinished: _cache[1] || (_cache[1] = async () => await _ctx.onGameFinished),
          onItemposition: _ctx.showPopupAtPosition
        }, null, 8, ["lyrics", "onDoneloading", "onSuccessanswer", "onNextoptions", "onItemposition"]))
      : _createCommentVNode("", true),
    (_ctx.level != 'just_play' && _ctx.nodes)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          (_ctx.inputType == 'grid')
            ? (_openBlock(), _createBlock(_component_FourOptionsGrid, {
                key: 0,
                onCompleted: _ctx.onPuzzleWordFormed
              }, null, 8, ["onCompleted"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}