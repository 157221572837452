
class BpmCalculator {

    // Defaults
	count = 0;
	tapStart = 0;
	tapPrevious = 0;
	autoReset = 15; //in seconds
	idleTime = 0;
	defaultText = "Tap";

    idleInterval
    bpmAvg
    init() {
        this.idleInterval = setInterval(this.idleIncrement, 1000);
    }

    resetTempo() {
		this.count = 0;
		/* $(".avg_bpm").text( defaultText );
		$(".in_bpm").hide(); */
	}

	// Get BPM
	getTempo(): number {
		const tapTimer = Date.now(); // $.now();
        let bpmText = 0
		if (this.count == 0) {
			this.tapStart = tapTimer;
			this.count = 1;

			//$(".avg_bpm").text("1st");
			//$(".in_bpm").show();
		} else {
			this.bpmAvg = 60000 * this.count / (tapTimer - this.tapStart);

			if(this.bpmAvg > 220)
                this.bpmAvg = 220;

			if(this.bpmAvg < 40)
                this.bpmAvg = 40;

			//$(".avg_bpm").text( Math.round(this.bpmAvg) );
            bpmText = Math.round(this.bpmAvg);
			this.count++;
		}

		this.tapPrevious = tapTimer;

        return bpmText
	}

	// Auto-reset
	idleIncrement() {
		this.idleTime = this.idleTime + 1;
		if (this.idleTime == this.autoReset)
            this.resetTempo();
	}
}

export default new BpmCalculator();
