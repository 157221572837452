
    import { Vue, Options } from 'vue-class-component';
    import service from "@/services/api";
    //import store from "@/stores";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";
    import Footer from "@/lib/SiteLayout/Footer/footer.vue";
    import helpers from '@/services/helpers';

    interface signupData {
        firstname: string
        email: string
        username: string
        password: string
        captcha: string
    }

    @Options({
        components: {
            Disclaimer, Footer
        },
    }) 
    export default class Signup extends Vue {

        datasignup: signupData = { firstname: '',  email: '', username: '', password: '', captcha: '' }

        // translations
        public get labels_firstname () { return this.$t('labels.firstname') }
        public get labels_email () { return this.$t('labels.email') }
        public get actions_goback () { return this.$t('actions.goback') }
        public get actions_continue () { return this.$t('actions.continue') }
        public get titles_create_account () { return this.$t('titles.create_account') }
        public get actions_signup () { return this.$t('actions.signup') }
        public get labels_username () { return this.$t('labels.username') }
        public get labels_password () { return this.$t('labels.password') }

        public get check_pass_required () { return this.$t('checks.pass_required') }
        public get check_pass_length () { return this.$t('checks.pass_length') }
        public get check_email_invalid () { return this.$t('checks.email_invalid') }
        public get check_email_required () { return this.$t('checks.email_required') }
        public get check_email_length () { return this.$t('checks.email_length') }
        public get check_username_required () { return this.$t('checks.username_required') }
        public get check_username_exists () { return this.$t('checks.username_exists') }

        public get isFormValid (): boolean { return this.datasignup.email !== '' && this.formValidations.email === '' && this.datasignup.username !== '' && this.formValidations.username === '' && this.datasignup.password !== '' && this.formValidations.password === '' && this.isUsernameUnique }

        isUsernameUnique: boolean = false;
        timerUsername;
        formValidations: any = { email: "", username: '', password: '' }

        async created() {
            AnalyticsUtils.visit(this.$route);
                   
        }
        checkEmail() {
            if (!this.datasignup.email)
                this.formValidations.email = this.check_email_required;
            else if (!helpers.isValidEmail(this.datasignup.email))
                this.formValidations.email = this.check_email_invalid;
            else if (this.datasignup.email.length < 3 || this.datasignup.email.length > 50)
                this.formValidations.email = this.check_email_length;
            else
                this.formValidations.email = ''; 
        }
        checkPassword() {        
            if (!this.datasignup.password)
                this.formValidations.password = this.check_pass_required;
            else
                this.formValidations.password = '';
        }
        async checkUsername() {
            clearTimeout(this.timerUsername);
            this.timerUsername = setTimeout(async () => {
                this.formValidations.username = ''; 
                
                this.isUsernameUnique = JSON.parse(await service.fetchFromApi({url: "security/checkUsername/" + this.datasignup.username})).success;

                if (!this.datasignup.username)
                    this.formValidations.username = this.check_username_required;
                else if (this.isUsernameUnique === false)
                    this.formValidations.username = this.check_username_exists;
            }, 500);
        }

        async signup() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')
            console.log("RECAPTCHA TOKEN: " + JSON.stringify(token));
            this.datasignup.captcha = token;
            //await postToApi("security/signup", { body: this.datasignup});
            await service.postToApi("security/signup", { body: this.datasignup } )
            
            /* grecaptcha.ready(()=> {
                grecaptcha.execute('6Lc0wkEbAAAAAErGwgrpXWscZbNZGO4mwplEWTE3', {action: 'submit'})
                .then(async (token)=> {
                    // Add your logic to submit to your backend server here.
                    console.log("RECAPTCHA TOKEN: " + JSON.stringify(token));
                    this.datasignup.captcha = token;
                    //await postToApi("security/signup", { body: this.datasignup});
                    await service.postToApi("security/signup", { body: this.datasignup } )
                    //this.isSigningUp = false;
                });
            });  */
        }
    }
