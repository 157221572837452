
    import { Options } from 'vue-class-component';
    import store from "@/stores";
    import { elementScrollIntoView  } from "seamless-scroll-polyfill";
    import * as wanakana from 'wanakana';
    import anime from 'animejs/lib/anime.es';
    import Timeline from '@/lib/Timeline/index.vue';
    import LyricsPlayer from '../LyricsPlayer/lyrics_player';
    import Helpers from '@/services/helpers';
    import gameUtils from "@/stores/gameUtils";
    
    @Options({
        components: {
            Timeline
        },
        props: {
            lyrics: Object
        },
    }) 
    export default class LyricsEditorTimeline extends LyricsPlayer  {

        isLoading = true;
        hasStarted = false; 
        firstLetter = false;
        text = ""; romaji = ""; hiragana = ""; katakana = "";
        isYTApiReady = false;
        isLyricSetup = false;
        videoDuration = 0; 
        playbackRate = 1;
        recentChanges: boolean = false;

        playerVars: any = {
            'autoplay': 0,
            'playsinline': 1,
            'controls': 1,
            'disablekb': 1,
            'fs': 0,
            'rel': 0,
            'cc_load_policy': 0,
            'iv_load_policy': 3,
            'modestbranding': 0,
            'enablejsapi': 1
        }
        isEditing = false; 
        isEditingFurigana = false;

        // by default there is 1 on the timeline
        timelineVerses: any = [];

        // translations
        public get labels_youtube_video_id () { return this.$t('labels.youtube_video_id') }
        public get labels_verse_total () { return this.$t('labels.verse_total') }
        public get actions_send_revision () { return this.$t('actions.send_revision') }
        
        addDefaultVerse() {
            this.lyrics.verses.push({text: "", romaji: "", en: "", es: "", characters: []});
        }
        created() {        
            if (!this.isLyricSetup) {
                console.log(this.lyrics);

                this.isLyricSetup = true;

                if (!this.lyrics || !this.lyrics.verses) {
                    this.addDefaultVerse();
                }

                this.setUpLyrics();
                this.initTimeline(); // the first time
            }
            
            store.isLoadingRoute = false;
        }

        mounted() {
           
        }

        onReady() {
            this.isYTApiReady = true;
            this.$emit('onReady', true);
            this.runner();
        }

        // lyrics can change anytime so we call this method multiple times
        setUpLyrics() {
            this.assignedId = 1;
            let characters_total = 0;
            let totalMs = 0;

            // calculate furigana above each kanji if needed
            this.lyrics.verses.forEach((lyric: any, l) => {

                totalMs = 0;
                
                // clone references to build complete lyrics
                this.findAndSetReferenceVerse(lyric);
                
                let characters = lyric.text.split("");       
                lyric.characters = [];
                
                if (characters && characters.length > 0) {
                    characters.forEach((cha, i) => {                               
                            
                        //let specObj = lyric.spec ? lyric.spec.find(x => x.pos == i) : undefined;
                        
                        if (!lyric.furigana || lyric.furigana.length == 0) {
                            lyric.furigana = [];
                            characters.forEach((/* c,i */) => {
                                lyric.furigana.push("");
                            });
                        }
                        if (!lyric.delay || lyric.delay.length == 0) {
                            lyric.delay = [];
                            const totalAllowed = +lyric.end - +lyric.start;
                            const durationPerCharacter = +totalAllowed / +characters.length;
                            characters.forEach((/* c,i */) => {
                                lyric.delay.push(+durationPerCharacter);
                            });
                        }

                        //console.log(cha + " >> " + i + " characters (" + characters.length + ") lyric.furigana (" + lyric.furigana.length + ") lyric.delay (" + lyric.delay.length + ") >>> " + (characters.length == lyric.furigana.length && lyric.furigana.length == lyric.delay.length));

                        let furigana = lyric.furigana[i];
                        let delay = lyric.delay[i];
                        let isToComplete = false;               

                        if(!delay) {
                            delay = 100;
                        }

                        let characterinfo = {id: this.assignedId, key: cha, furigana: furigana, delay: delay, toComplete: isToComplete};

                        lyric.characters.push(characterinfo);

                        this.assignedId = this.assignedId + 1;

                        totalMs = totalMs + (delay ? delay : 300);
                    });
                    //console.log(lyric);
                }

                lyric.totalMs = totalMs;
                lyric.diff = totalMs - (lyric.end - lyric.start);

                // ADD EACH VERSE TO THE TIMELINE
                let timelineTrack = {
                    id: l,
                    line: l,
                    name: lyric.text,
                    isMovable: true,
                    isDeletable: true,
                    isPose: true,
                    isResizableLeft: true,
                    isSelected: false,
                    start: (lyric.start / 1000),
                    end: (lyric.end / 1000)
                };
                this.timelineVerses.push(timelineTrack);

                characters_total = characters_total + lyric.characters.length;
            });

            this.lyrics.characters_total = characters_total;
        }
        // 4. The API will call this function when the video player is ready.
        onPlayerReady(/* event */) {
            console.log("editor.onPlayerReady");
            //event.target.playVideo();
            //startTimer();
            this.videoDuration = this.player.getDuration();
            this.lyrics.durationInSeconds = this.videoDuration;
        }
        // 100 – The video requested was not found, 150/101 – The owner of the requested video does not allow it to be played in embedded players
        onPlayerError(/* event */) {
            this.errorThrown = true;
            /* if (event.data == 100) {

            } else if (event.data == 101 || event.data == 150) {

            } */
        }
        /* onPlayerStateChange(event) {
            //console.log("PLAYER STATE: " + JSON.stringify(event));
            if (event.data == 1 && !this.isVideoComplete) { // YT.PlayerState.PLAYING
     
                    // when it is first started, sync video start with lyrics loop
                    //readLoop();
                    
                    this.timeline.play();
                    //readForLoop()
            // }

                this.isBuffering = false;
            
            } else if (event.data == 2) { // YT.PlayerState.PAUSED
                console.log("VIDEO PAUSED!!");
                this.timeline.pause();
            } else if (event.data == 5) { // YT.PlayerState.BUFFERING
                console.log("BUFFERING!!");
                
                this.isBuffering = true;
                // TODO: handle scenario when video suddenly stops for buffering
                //stopTimer();

            } else if (event.data == 0) { // YT.PlayerState.ENDED
                console.log("VIDEO ENDED!!");
                this.isVideoComplete = true;

            }
        } */
        // -1 (unstarted), 0 (ended), 1 (playing), 2 (paused), 3 (buffering), 5 (video cued).
        // YT.PlayerState: ENDED, PLAYING, PAUSED, BUFFERING, CUED
        onPlayerStateChange(event) {
            //console.log(event);
            if (event.data == 1 && !this.isVideoComplete) {
                //setTimeout(stopVideo, 6000);
                //done = true;

                /* if (!isBuffering)
                {
                    // if video is loading while it was playing, (got interrupted)
                    resumeTimer();
                }
                else
                {
                    console.log("isBuffering"); */
                    // when it is first started, sync video start with lyrics loop
                    //readLoop();
                    
                    //timeline.play(); // ****
                    //readForLoop()
            // }

                this.timeline.play();

                this.isPlaying = true;
                this.isBuffering = false;
            
            } else if (event.data == 2) {
                console.log("VIDEO PAUSED!!");
                //stopTimer();
                //timeline.pause();
                this.isPlaying = false;
                this.timeline.pause();
            } else if (event.data == 3) {
                console.log("BUFFERING!!");
                this.isPlaying = false;
                this.isBuffering = true;
                // TODO: handle scenario when video suddenly stops for buffering
                //stopTimer();

            } else if (event.data == 4) {
                console.log("VIDEO ENDED!!");
                this.isVideoComplete = true;
                this.isPlaying = false;
            }
        }

        onDocumentKeyDown( event ) {
            if ( this.firstLetter ) {
                this.firstLetter = false;
                this.text = "";
                this.romaji = "";
            }

            const keyCode = event.keyCode;

            // backspace
            if ( keyCode == 8 ) {
                event.preventDefault();
                console.log("onDocumentKeyDown:keyCode == 8");

                this.romaji = this.romaji.substring( 0, this.romaji.length - 1 );
                this.text = this.text.substring( 0, this.text.length - 1 );
                this.text = wanakana.toHiragana(this.romaji);
                
                console.log(this.text);

                // ** it doesnt make sense to check here, if the user typed the right answer will never be able to go back and re-enter text

                /* if (isEditingFurigana) {
                    document.getElementById("fg-" + currentInputPosition).setAttribute('t', text);;
                } else { */
                    event.target.value = this.text;
                //}

                return false;
            }
        }
        onDocumentKeyPress( event ) {
            const keyCode = event.which;

            // backspace
            if ( keyCode == 8 ) {
                event.preventDefault();		
                console.log("onDocumentKeyPress:keyCode == 8");
            } else if ( keyCode == 13 ) {
                console.log("onDocumentKeyPress:keyCode == 13");
                this.firstLetter = true;
            } else {
                
                const ch = String.fromCharCode( keyCode );
                
                this.romaji += ch;
                this.text += ch;				
                
                this.hiragana = wanakana.toHiragana(this.romaji);
                this.katakana = wanakana.toKatakana(this.romaji);

                console.log(this.hiragana);

                /* let toCompleteObj = toComplete.find(x => x.line == currentLine && x.isComplete == false && x.isSkipped == false);
                console.log("ANSWERING " + JSON.stringify(toCompleteObj));
                if (toCompleteObj) {
                    let currentLineObj = lyrics.verses.find(x => x.id == currentLine);
                    let currentObj = currentLineObj.characters.find(x => x.key == toCompleteObj.kanji);
                    currentInputPosition = currentObj.id; */
                    /* if (isEditingFurigana) {
                        document.getElementById("fg-" + currentInputPosition).setAttribute('t', hiragana);
                    } else { */
                        event.target.value = this.hiragana;
                    //}

                    //checkAnswer(hiragana, currentObj, toCompleteObj);

            /*  } else {
                    // everything was either answered or skipped
                    //resumeTimer();
                } */
            }
        }
        handlePaste (e, i) {
            console.log(e);
            var clipboardData, pastedData;

            // Stop data actually being pasted into div
            e.stopPropagation();
            e.preventDefault();

            // Get pasted data via clipboard API
            clipboardData = e.clipboardData/*  || window.clipboardData */;
            pastedData = clipboardData.getData('Text');
            
            // Do whatever with pasteddata
            //alert(pastedData);
            let characters = pastedData.split("");  
            console.log(characters);
            
            this.lyrics.verses[i].characters = [];

            let assignedId = 0;
            characters.forEach(char => {
                
                let characterinfo = {id: assignedId, key: char, furigana: "--", delay: 0, toComplete: false, isKanji: false};
                let isKanji = !wanakana.isKana(char);
                characterinfo.isKanji = isKanji;

                this.lyrics.verses[i].characters.push(characterinfo);

                assignedId = assignedId + 1;
            });
            
            this.lyrics.verses[i].text = pastedData;
            //lyrics.verses[i].characters = characters;
        }

        runner() {
            //console.log(player + " "+ timeline + " "+ isPlaying)
            if (this.player && this.timeline && this.isPlaying) {
                const ctime = this.player.getCurrentTime();

                if (ctime != null) {
                    let mili = ctime * 1000;
                    //console.log("TIMELINE " + mili);
                    this.timeline.seek(mili);
                }               
            }
            
            requestAnimationFrame( this.runner );
        }

        initTimeline() {
            //console.log(JSON.stringify(this.lyrics));
            let isFirstVerse = true;
            this.isTimelineReady = true;

            this.timeline = anime.timeline({
                loop: false,
                autoplay: false,
                update: (/* anim */) => {
                    // UPDATE TIMELINE TIME BASED ON THE ANIMATION PROGRESS PLAYING
                    //timelineService.setCurrentTime(anim.progress);
                    const videoCurrentTime  = this.player.getCurrentTime();
                    //console.log("TIMELINE PROGRESS: " + Math.round(anim.progress)+'% videoCurrentTime: ' + videoCurrentTime);

                    this.setTime(videoCurrentTime);
                    
                },
                easing: 'easeInOutCirc',
            });       

            this.lyrics.verses.forEach((lyric, verseId/* , verseArray */) => {      
                        
                let secondsAfterPrevVerse = 0;
                if (isFirstVerse) {

                    // this is just a dummy waiting before the very first verse starts
                    this.timeline
                    .add({
                        target: "#dummy",
                        duration: 0,
                    }, +lyric.start);

                    isFirstVerse = false;

                } else if (verseId > 0) { // we are in the last character

                    // setting the spaces between at what time current start based on the previous ending

                    let prevVerse = this.lyrics.verses[verseId - 1]; // TODO: check if this is the last one
                    secondsAfterPrevVerse = +lyric.start - +prevVerse.end;
                    lyric.pauseBeforeNextVerse = secondsAfterPrevVerse; // this will be saved to the db

                    //console.log("START PLAYING " + verseId + " " + secondsAfterPrevVerse + " AFTER PREVIOUS VERSE " + (verseId - 1) + "");
                }


                lyric.characters.forEach((character, idx, array) => {              
                
                    let delay = character.delay ? character.delay : 300;

                    // only offset on the first character (#0)
                    if (idx > 0) {
                        secondsAfterPrevVerse = 0;
                    }

                    this.addTimelineAnimation(character.id, verseId, delay, secondsAfterPrevVerse, (idx === array.length - 1), character.key);

                    /* this.timeline
                    .add({
                        targets: "#ch-" + character.id,
                        color: [ "rgb(190, 188, 188)", "rgb(158, 154, 154)", "#009688" ],
                        duration: delay,
                        begin: () => {
                            console.log("PLAYING (" + verseId + ") -->  " + character.key + " >>> delay: " + delay + "    video time >>> " + this.player.getCurrentTime());

                            if (!this.isPlaying) {
                                console.log("+++++++++++++ AVOIDED TO CONTINUE +++++++++++++");
                                this.timeline.pause();
                            }
                        },
                        complete: () => {
                            console.log("READ " + character.key);
                            this.currentLine = verseId;

                            if (idx === array.length - 1) { 
                                console.log("LAST ELEMENT OF VERSE " + verseId);
                                
                                // stay on the same verse until we are absolutley sure is synchronized
                                if (this.playOnlyCurrentVerse) {
                                    this.stopTimer();
                                }
                                //player.pauseVideo();
                                //isPlaying = false;
                            }
                        },                       
                    },  '+=' + secondsAfterPrevVerse); */
                });
            });

            this.timeline.finished.then(()=>{
                console.log("///// LYRIC END /////");
            });
        }
        addTimelineAnimation(characterid, verseId, delay, secondsAfterPrevVerse, isLastElementOfVerse: boolean, characterKey) {
            console.log("animation setup for characterid: " + characterid + " ("+characterKey+")")
            this.timeline
            .add({
                targets: "#ch-" + characterid,
                color: [ "rgb(190, 188, 188)", "rgb(158, 154, 154)", "#009688" ],
                duration: delay,
                begin: () => {
                    console.log("PLAYING (" + verseId + ") -->  " + characterKey + " >>> delay: " + delay + "    video time >>> " + this.player.getCurrentTime());

                    if (!this.isPlaying) {
                        console.log("+++++++++++++ AVOIDED TO CONTINUE +++++++++++++");
                        this.timeline.pause();
                    }
                },
                complete: () => {
                    console.log("READ " + characterKey);
                    gameUtils.currentLine = verseId;

                    if (isLastElementOfVerse) { 
                        console.log("LAST ELEMENT OF VERSE " + verseId);
                        
                        // stay on the same verse until we are absolutley sure is synchronized
                        if (this.playOnlyCurrentVerse) {
                            this.stopTimer();
                        }
                        //player.pauseVideo();
                        //isPlaying = false;
                    }
                },                   
            },  '+=' + secondsAfterPrevVerse);
        }
        updateTimelineAnimation(verse, characterId, newDelay) {
            //this.timeline.remove("#ch-" + characterId);
            //var character = verse.characters[characterId];

            // add the new animation with the delay property updated
            //this.addTimelineAnimation(characterId, verse.id, newDelay, verse.pauseBeforeNextVerse, (characterId === verse.characters.length - 1), character.key);
            
            this.timeline.set("#ch-" + characterId, { duration: newDelay });

            console.log("UPDATED ANIMATION FOR target: " + "#ch-" + characterId + " new delay: " + newDelay)

        }
        /* playLine() {
            let currentLineObj = this.lyrics.verses.find(x => x.id == this.currentLine);
            let startSeconds = currentLineObj.start / 1000;
            console.log("STARTING AGAIN FROM LINE " + currentLineObj.id + " ms: " + currentLineObj.start + " second: " + startSeconds);

            this.player.seekTo(startSeconds);
            this.player.playVideo();

            this.timeline.seek(currentLineObj.start);

            this.isChangingVerse = false;
            this.isPlaying = true;
            //timeline.play();
        } */
        
        playLineAt(e, lineIdx) {
            this.playOnlyCurrentVerse = true;
            gameUtils.currentLine = lineIdx;
            
            if (this.recentChanges){
                console.log("RECENT CHANGES DETECTED!!")
                this.initTimeline();
            }

            this.playLine();
            //this.startTimer() // setup again the whole timeline (i couldnt find a way to edit a specific animation)
        }
        
        startTimer() {
            this.playOnlyCurrentVerse = false;
            
            if (this.recentChanges){
                console.log("RECENT CHANGES DETECTED!!")
                this.initTimeline();
            }
            
            this.togglePlay();

            /* if (!this.isPlaying) {
                //currentLang.setIsLoading(true);
                this.hasStarted = true;
                this.isPlaying = true;

                if (this.timeline) {
                    this.timeline.pause();
                    this.timeline.seek(0);
                }
          
                

                this.player.playVideo();  
                //currentLang.setIsLoading(false);
            } else {
                this.isPlaying = false;
                this.player.pauseVideo();
                //timeline.pause();
            } */
        }

        /* goToPreviousLine() {
            this.isChangingVerse = true;
            this.currentLine--;
            this.timeline.pause();
            this.playLine();
        }
        goToNextLine() {
            this.isChangingVerse = true;
            this.currentLine++;
            this.timeline.pause();
            this.playLine();
        } */
        sendForRevision() {

        }
        save() {
            this.$emit('onSave', this.lyrics);
        }
        goBack() {

        }
        editVerse(e, i) {
            e.stopPropagation();
            e.preventDefault();

            if (e.offsetX > e.target.offsetLeft) {
                console.log("ELEMENT CLICKED");
            } else {
                console.log("PSEUDO CLICKED");
            }

            console.log("editVerse " + i);

            /* document.removeEventListener( 'keypress', onDocumentKeyPress );
            document.removeEventListener( 'keydown', onDocumentKeyDown );  */

            this.isEditing = true;
            this.isEditingFurigana = false;
            //currentInputPosition

            this.addDefaultVerse();
        }
        editFurigana(e, j) {
            e.stopPropagation();
            e.preventDefault();

            if (e.offsetX > e.target.offsetLeft) {
                console.log("ELEMENT CLICKED");
            } else {
                console.log("PSEUDO CLICKED");
            }

            console.log("editFurigana " + j);
         
            this.isEditingFurigana = true;
            //this.currentInputPosition = j; // TODO: fix this commented out recently
        }
        editing(e){
            const keyCode = e.which;
            const ch = String.fromCharCode( keyCode );
                
            this.romaji += ch;
            this.text += ch;				
            
            this.hiragana = wanakana.toHiragana(this.romaji);
            this.katakana = wanakana.toKatakana(this.romaji);

            console.log(this.hiragana);

            e.target.value = this.hiragana;
        }
        
        
        onTimelineVerseClick(animationSelected) {
            //console.log(animationSelected);
            elementScrollIntoView(document.querySelector("#ly-" + animationSelected.detail.line)!, {
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
        onFrameMoving(data) {

            console.log("onFrameMoving " + JSON.stringify(data.detail));

            const frame = data.detail;
            let movedVerse = this.lyrics.verses.find(x => x.id == frame.id);
            movedVerse.start = frame.start * 1000;
            movedVerse.end = frame.end * 1000;

            /* let timelineFrame = timelineVerses.find(x => x.id == frame.id);
            timelineFrame.start = frame.start;
            timelineFrame.end = frame.end; */

            //console.log("MOVED: " + JSON.stringify(movedVerse));
            

            /* if (lyrics.verses.length < movedVerse.id) { // if this is not the last verse on the timeline
                let nextVerse = lyrics.verses[movedVerse.id + 1]; // TODO: check if this is the last one
                if (nextVerse != null) {
                    const secondsBeforeNextVerse = +nextVerse.start - +movedVerse.end;
                    movedVerse.pauseBeforeNextVerse = secondsBeforeNextVerse;
                }
            } */
        }
        timelineUpdate() {

        }

        onVideoUrlUpdate() {
            
            //this.onYouTubeIframeAPIReady();

        }
        // calculate total seconds on current verse
    /*  function onDelayChange(lineNumber, delay, characterPos) {
            if ( !lineNumber || isNaN(lineNumber) ) {
                return;
            }

            // update spec array (persists reduce space, this will be setup later on function above when loading in the player)      
            // new specs
            if (!lyrics.verses[lineNumber].spec || lyrics.verses[lineNumber].spec.length == 0) {
                lyrics.verses[lineNumber].spec = [];
                
                var obj = { pos: characterPos, kanji: null, furigana: null, delayms: delay };

                lyrics.verses[lineNumber].spec.push(obj);
            } else {
                // update existent
                //var characterFound = lyrics.verses[lineNumber].characters

                var specFoundToUpdate = lyrics.verses[lineNumber].spec.find(x => x.pos == characterPos);
                specFoundToUpdate.delayms = delay;

            }

            // recalculate duration on current verse
            var totalMs = 0;
            // setup again the characters array for playing on editor only, we cant iterate spec as there may be characters not present
            lyrics.verses[lineNumber].characters.forEach(el => {
                
                totalMs = totalMs + (el.delay ? parseInt(el.delay) : 300);

            });

            lyrics.verses[lineNumber].totalMs = totalMs;

            // one change on one single verse will affect all the following verses on the timeline (it will push the others by the difference of miliseconds)
            var timelineFrame = timelineVerses.find(x => x.line);
            // start: (lyric.start / 1000),
            // end: (lyric.end / 1000)
            var totalDuration = 0;
            lyrics.verses.forEach(el => {

                // calculate verse duration
                el.characters.forEach(ch => {
                    
                    totalDuration = totalDuration + parseInt(ch.delay);

                });

                console.log("Verse (" + el.id + ") duration (" + totalDuration + ")");

                // set the new end to TIMELINE
                el.end = parseInt(el.start) + (totalDuration / 1000);

                timelineFrame.start = el.start;
                timelineFrame.end = el.end;

            });

        }
    */
        // duplicate test and delays over this verse when the dropdown was selected
        onReferenceVerseSelected(lineNumber) {
            var selectedRefId = this.lyrics.verses[lineNumber].refId;

            let refObj = this.lyrics.verses.find(x => x.id == selectedRefId);
            if (refObj) {

                let refObjClone = JSON.parse(JSON.stringify( refObj ));
                
                this.lyrics.verses[lineNumber].text = refObjClone.text;
                this.lyrics.verses[lineNumber].romaji = refObjClone.romaji;
                this.lyrics.verses[lineNumber].spec = refObjClone.spec;       
            }
        }

        onValueDragged(e, verse, chaPos, delay) {
            //delay = e; 
            console.log(e + " " +delay)
            const totalAllowedInVerse = +verse.end - +verse.start;

            console.log(this.lyrics)
            //const totalExcludingCurrentCharacter = +totalAllowedInVerse - +delay;   
            var currentTotal = verse.delay.reduce((prev, cur) => { // this total would contain extra miliseconds if any delay was increased, we want to reduce those to the other characters evenly               
                return prev + +cur;
            }, 0);
            
            //console.log("verse: " + verse.id + " totalMs " + verse.totalMs + " spec.pos: " + spec.pos + " old delay?: " + delay + " >>> totalAllowedInVerse: " + totalAllowedInVerse + " >>> totalExcludingCurrentCharacter: " + totalExcludingCurrentCharacter + " >>> currentTotal: " + currentTotal);
                        
            var diff = +totalAllowedInVerse - +currentTotal;
            
            verse.totalMs = Helpers.convertToFixed(currentTotal);
            
            console.log("total: " + currentTotal + " diff (extra ms): " + diff);

            // reduce the delay for all the other characters (equally) if the current delay increased
            if (diff < 0) {
                var totalCharacters = verse.characters.length - 1;
                //console.log("Old delay?: " + spec.delayms + " NEW: " + e.detail + " diff " + diff + " adjusting " + totalCharacters + " characters in the verse");
                var mili = Math.abs(diff / totalCharacters);

                verse.delay.forEach((spe, pos) => {
                    if (pos !== chaPos) 
                    {
                        console.log("Reducing delay by " + mili + " on " + pos + " currently " + spe + " to " + (spe - mili));
                        if (spe - mili < 0) {
                            spe = 0;
                        } else {
                            var parsed = Helpers.convertToFixed(+spe - +mili);
                            
                            verse.delay[pos] = parsed;
                            //spe = parsed;
                        }
                    }
                });

            } else {
                // if the new value for the current character is reducing we dont add the resultant to the others (but that means the verse is incomplete, not zynch)
            }

            //this.updateTimelineAnimation(verse, chaPos, delay);
            this.recentChanges = true
        }

        // the current total of each character delay should be the same as the end - start
        isVersedSynched(verse) {
            //console.log("current total: " + Math.trunc(verse.totalMs) + " allowed: " + Math.trunc(verse.end - verse.start) + " >> " + (Math.trunc(verse.totalMs) == Math.trunc(verse.end - verse.start)));
            if (Math.trunc(verse.totalMs) == Math.trunc(verse.end - verse.start)) 
            {
                return true;
            } 
            else if (verse.totalMs < (verse.end - verse.start) || verse.totalMs > (verse.end - verse.start)) 
            {
                return false;
            }

            return false;
        }
        editingFurigana(e, furigana) {
            this.recentChanges = true

            console.log(e + "  " + furigana)
            /* const isHira = wanakana.isHiragana(furigana);
            const isKata = wanakana.isKatakana(furigana); */

            /* if (isHira || isKata) {
                furigana = furigana;
            } else {
                furigana = "";
            } */
        }
        chanceVideoSpeed() {
            this.player.setPlaybackRate(this.playbackRate);
        }

        destroyed () {
            try {
                this.player.destroy();
                this.timeline.pause();
                this.player = null;
                this.timeline = null;
            } catch(e){
                console.log("ERROR " + e);
            }
        }
    }
