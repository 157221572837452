
    import { Vue, Options } from 'vue-class-component';
    //import service from "@/services/api";
    //import store from "@/stores";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";
    import Footer from "@/lib/SiteLayout/Footer/footer.vue";

    interface recoverData {
        email: string
        code: string
    }

    @Options({
        components: {
            Disclaimer, Footer
        },
    }) 
    export default class RecoverPassword extends Vue {

        datarecover: recoverData = { email: '', code: '' }

        // translations
        public get labels_code () { return this.$t('labels.code') }
        public get labels_email () { return this.$t('labels.email') }
        public get actions_generate () { return this.$t('labels.email') }

        async created() {
            AnalyticsUtils.visit(this.$route);
                   
            
        }

        async recover() {

        }
    }
