
    import { Vue, Options } from 'vue-class-component';
    import service from "@/services/api";
    //import store from "@/stores";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";
    import Footer from "@/lib/SiteLayout/Footer/footer.vue";

    interface signinData {
        username: string
        password: string
    }

    @Options({
        components: {
            Disclaimer, Footer
        },
    }) 
    export default class Signin extends Vue {

        datasignin: signinData = { username: '', password: '' }

        // translations
        public get message_redirecting () { return this.$t('message.redirecting') }
        public get actions_signin () { return this.$t('actions.signin') }
        public get actions_cancel () { return this.$t('actions.cancel') }
        public get labels_username () { return this.$t('labels.username') }
        public get labels_password () { return this.$t('labels.password') }
        public get labels_forgotpassword () { return this.$t('labels.forgotpassword') }
        public get labels_signin () { return this.$t('labels.signin') }

        public get labels_code () { return this.$t('labels.code') }
        public get labels_individual () { return this.$t('labels.individual') }

        async created() {
            AnalyticsUtils.visit(this.$route);
                   
            
        }

        async signin() {
            await service.postToApi("security/signin", { body: this.datasignin } )
        }

        joinclass() {
            this.$router.push({ path: `/join-class` });
        }

        forgot(){
            this.$router.push({ path: `/recover-password` });
        }
    }
