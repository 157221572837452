import { reactive } from "vue";
//import mitt from 'mitt';
import * as wanakana from 'wanakana';
import Helpers from '@/services/helpers';
import EventBus from '@/services/pubsub'

interface IToComplete {
  id: string
  line: number
  coordinates: any
  startTime: number
  endTime: number
  diffTime: number
  isComplete: boolean
  isSkipped: boolean
  kana: string
  kanji: string
  numberOfRepetitions: number
}

class GameUtils {

  currentLine: number = 0
  currentLevel: string = 'veryeasy'
  //emitter = mitt()

  // initialize by default with the list of all the toComplete items (updated later with coordinates and start time right after the user hear it)
  public toCompleteQueue: any[] = []

  constructor() {
    
  }

  isCurrentLineIncomplete() {
    const line = this.toCompleteQueue.find(x => x.line == this.currentLine);
    if (!line) return
    const isComplete = line.characters.some(x => x.isComplete == false && x.isSkipped == false);

    //const isComplete = this.toCompleteQueue.some(x => x.line == this.currentLine && x.isComplete == false && x.isSkipped == false);
    Helpers.lgi("GameUtils:::isCurrentLineIncomplete (" + this.currentLine + ")?? " + isComplete);
    return isComplete;
  }

  public setToComplete(queue) {
    this.toCompleteQueue = queue;
  }

  public updatePopupPosition(itemInfo: any) { 
    const line = this.toCompleteQueue.find(x => x.line == this.currentLine);
    if (!line) return
    const toComplete = line.characters.find(x => x.i == itemInfo.id);
    
    if (toComplete) {
      toComplete.coordinates = itemInfo.coords;
      toComplete.startTime = itemInfo.startTime;

      toComplete.tries.push({ startTime: itemInfo.startTime }); // each time the character is read is a try

      Helpers.lgi("GameUtils:::updatePopupPosition (" + JSON.stringify(itemInfo) + ")");
    }
    //this.toCompleteQueue.push(toComplete)
  }

  public getNextToComplete(): any {
    const line = this.toCompleteQueue.find(x => x.line == this.currentLine);
    if (!line) return
    const toComplete = line.characters.find(x => x.isComplete == false && x.isSkipped == false);

    return toComplete;
  }

  private rightAnswer(toComplete) {
    toComplete.isComplete = true;
    toComplete.isSkipped = false;

    // SET quality between 3 - 4
    Helpers.lgi("RIGHT response duration: " + toComplete.diffTime + " in " + toComplete.tries + " tries");
    if (toComplete.tries == 1 && toComplete.diffTime <= 2000)
      toComplete.quality = 5; // (perfect response) responded first try and quick (<=2 seconds)
    
    if (toComplete.tries == 1 && toComplete.diffTime <= 5000)
      toComplete.quality = 4; // (after a hesitation) 3-5 seconds? delayed response
    
    if ((toComplete.tries == 1 && toComplete.diffTime > 5000) || toComplete.tries == 2)
      toComplete.quality = 3; // (recalled, but it was difficult) +5 seconds delayed response or responded in the second try.

  }

  private wrongAnswer(toComplete) {
    // toComplete.isComplete = true;
    // toComplete.isSkipped = false;

    Helpers.lgi("WRONG response duration: " + toComplete.diffTime + " in " + toComplete.tries + " tries");

    // SET quality between 3 - 4
    
    if (toComplete.tries == 1)
      toComplete.quality = 2; // responded multiple times wrong and finally skipped or tried to guess in the third try (by discarding options out of the 4 wouldnt be a valid response) also responded quick but it was incorrect
    else
      toComplete.quality = 1; // +5 seconds trying to remember but still responded incorrectly
    
    // toComplete.quality = 0; // (complete blackout) Skip button

  }
  /* private getLastTry(toComplete) {
    if (!toComplete.tries || toComplete.tries.length == 0) return {startTime: 0, endTime: 0, diffTime: 0}
    let lastTry = toComplete.tries[toComplete.tries.length -1];

    return lastTry;
  } */
  public answer(answer, isRomaji: boolean = false) {
    Helpers.lgi("GameUtils:::answer (" + answer + ") isRomaji: " + isRomaji);

    const toComplete = this.getNextToComplete();

    if (toComplete) 
    {
      //EventBus.emit('onInputAnswer', { answer, id: toComplete.i }  ) // TODO: enable this in case we allow a keyboard input method so that we display when typing

      toComplete.tries = toComplete.tries + 1
      // for now just override the last try, later we could keep the history of tries
      toComplete.endTime = new Date().getTime();
      const time = toComplete.endTime - toComplete.startTime;
      toComplete.diffTime = time;

      // get last tries array and update the end time (pushed right after the character is played and popup showed up)
      
      /* let lastTry = this.getLastTry(toComplete);
      lastTry.endTime = new Date().getTime();
      const time = toComplete.endTime - toComplete.startTime;
      lastTry.diffTime = time; */

      let rightAnswer = ''
      if (this.currentLevel == 'veryeasy') // TODO: unify this?
      {
        rightAnswer = toComplete.t == 3 ? toComplete.f : toComplete.c;
      }
      else
      {
        rightAnswer = toComplete.t == 1 ? toComplete.f : toComplete.c;
      }

      if (rightAnswer == answer)
      {
        this.rightAnswer(toComplete);

        EventBus.emit('rightAnswer', { answer, id: toComplete.i } ) // listen to this from all the components to react
      } 
      else 
      {
        // wrong
        this.wrongAnswer(toComplete);

        EventBus.emit('wrongAnswer', { answer, id: toComplete.i }  ) 
      }
    }
  }

  skipCurrentToComplete(){
    const toComplete = this.getNextToComplete();
    if (toComplete) {
      toComplete.isSkipped = true;
      toComplete.quality = 0;

      EventBus.emit('skippedAnswer')
    }
  }
  replayCurrentLine(){
    const toComplete = this.getNextToComplete();
    if (toComplete)
      toComplete.numberOfRepetitions++;
  }
}

//export default new GameUtils();
export default reactive(new GameUtils());
