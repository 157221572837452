import CryptoJS from 'crypto-js';
const encKey: string = process.env.VUE_APP_EncryptKey;
const encryptIV: string = process.env.VUE_APP_EncryptIV;

class EncUtils {
  private key;
  private iv;
  constructor() {
    this.key = CryptoJS.enc.Utf8.parse(encKey);
    this.iv = CryptoJS.enc.Utf8.parse(encryptIV);
  }

  encryptUsingAES256(text): any {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  decryptUsingAES256(decString) {
    const decrypted = CryptoJS.AES.decrypt(decString, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

export default new EncUtils();
