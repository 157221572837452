
    import { Vue } from 'vue-class-component';

    export default class Footer extends Vue {
        currentYear = 2022;
        currentLang = "es";

        // translations
        public get label_privacy () { return this.$t('labels.privacy') }
        public get label_terms () { return this.$t('labels.terms') }

        goToTerms() {    
            this.$router.push({ path: `/terms` });
        }
        goToPrivacy() {
            this.$router.push({ path: `/privacy` });
        }
    }
