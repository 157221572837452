
    import { Vue, Options } from 'vue-class-component';
    import Footer from "@/lib/SiteLayout/Footer/footer.vue";
    import store from "@/stores";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";

    @Options({
        components: {
            Footer, Disclaimer
        },
    }) 
    export default class PrivacyTerms extends Vue {
        
        // translations
        // section 1

        public get section1_title (): any { return this.$t('privacy[0]') }
        public get section1_pha1 () { return this.$t('privacy[1]') }
        public get section1_pha2 () { return this.$t('privacy[2]') }
        public get section1_pha3 () { return this.$t('privacy[3]') }

        public get section2_title () { return this.$t('privacy[4]') }
        public get section2_pha1 () { return this.$t('privacy[5]') }
        public get section2_pha2 () { return this.$t('privacy[6]') }
        public get section2_pha3 () { return this.$t('privacy[7]') }
        public get section2_pha4 () { return this.$t('privacy[8]') }
        public get section2_pha5 () { return this.$t('privacy[9]') }
        public get section2_pha6 () { return this.$t('privacy[10]') }

        public get section3_title () { return this.$t('privacy[11]') }
        public get section3_pha1 () { return this.$t('privacy[12]') }

        public get section4_title () { return this.$t('privacy[13]') }
        public get section4_pha1 () { return this.$t('privacy[14]') }
        public get section4_pha2 () { return this.$t('privacy[15]') }
        public get section4_pha3 () { return this.$t('privacy[16]') }
        public get section4_pha4 () { return this.$t('privacy[17]') }
        public get section4_pha5 () { return this.$t('privacy[18]') }
        public get section4_pha6 () { return this.$t('privacy[19]') }
        public get section4_pha7 () { return this.$t('privacy[20]') }

        public get section5_title () { return this.$t('privacy[21]') }
        public get section5_pha1 () { return this.$t('privacy[22]') }

        public get section6_title () { return this.$t('privacy[23]') }
        public get section6_pha1 () { return this.$t('privacy[24]') }

        public get section7_title () { return this.$t('privacy[25]') }
        public get section7_pha1 () { return this.$t('privacy[26]') }

        public get section8_title () { return this.$t('privacy[27]') }
        public get section8_pha1 () { return this.$t('privacy[28]') }

        created() {
            AnalyticsUtils.visit(this.$route);
        }

        mounted() {
            setTimeout(() => {
                store.isLoadingRoute = false;
            }, 1000);
        }
    }
