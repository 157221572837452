
    import { Vue, Options } from 'vue-class-component';
    import anime from 'animejs/lib/anime.es.js';
    import { ref } from 'vue';
    //import { Ref } from 'vue-property-decorator'
    
    @Options({
        name: 'endlyricplay-modal',
    }) 
    export default class EndLyricsModal extends Vue {

        results: string = '';
        //papersElm;
        //@Ref() papersElm!: any
        papersElm: any = ref(null)

        PAPER_AMOUNT = 80
        COLORS = [
            '#ab47bc',
            '#5c6bc0',
            '#29b6f6',
            '#66bb6a',
            '#ffee58',
            '#ffa726',
            '#ef5350',
        ]

        // translations
        public get actions_ok () { return this.$t('actions.ok') }
        public get actions_cancel () { return this.$t('actions.cancel') }
        public get titles_congratulations () { return this.$t('titles.congratulations') }

        generatePapers(container) {
            const maxPaperSize = container.offsetWidth / 40
            const minPaperSize = maxPaperSize / 2

            for (let i = 0; i < this.PAPER_AMOUNT; i++) {
                const p = document.createElement('div')

                p.style.position = 'absolute'
                p.style.top = `-${maxPaperSize}px`
                p.style.left = `${anime.random(-5, container.offsetWidth + 5)}px`
                p.style.width = `${anime.random(minPaperSize, maxPaperSize)}px`
                p.style.height = `${anime.random(minPaperSize, maxPaperSize)}px`
                p.style.backgroundColor = this.COLORS[i % this.COLORS.length]

                container.appendChild(p)
                this.animatePaper(p, container)
            }
        }
        animatePaper(p, container) {
            anime({
                targets: p,
                delay: anime.random(0, 7000),
                duration: anime.random(5000, 7000),
                easing: 'linear',
                loop: true,
                translateX: `${anime.random(-20, 20)}px`,
                translateY: `${container.offsetHeight * 2 / 3}px`,
                skewX: `${anime.random(-45, 45)}deg`,
                skewY: `${anime.random(-45, 45)}deg`,
                rotate: `${anime.random(-1.5, 1.5)}turn`,
                opacity: [0.8, 0],
            })
        }

        mounted() {
            this.generatePapers(this.papersElm);
        }

        prev() {
            
        }
        next() {
            close();
        }
    }
