
    import { Vue, Options } from 'vue-class-component';
    import Footer from "@/lib/SiteLayout/Footer/footer.vue";
    import store from "@/stores";
    import Features from "./features.vue";
    import Disclaimer from "@/lib/SiteLayout/Disclaimer/index.vue";
    import Contact from '@/lib/SiteLayout/Contact/index.vue';
    import AnalyticsUtils from "@/services/analytics";

    @Options({
        components: {
            Footer, Features, Disclaimer, Contact
        },
    }) 
    export default class About extends Vue {

        // translations
        public get t1 () { return this.$t('about.t1') }
        public get p1 () { return this.$t('about.p1') }
        public get p1_2 () { return this.$t('about.p1_2') }
        public get p1_3 () { return this.$t('about.p1_3') }
        public get p1_4 () { return this.$t('about.p1_4') }
        public get p1_5 () { return this.$t('about.p1_5') }
        public get t2 () { return this.$t('about.t2') }
        public get p2 () { return this.$t('about.p2') }
        public get p2_1 () { return this.$t('about.p2_1') }
        public get p2_2 () { return this.$t('about.p2_2') }
        public get p2_3 () { return this.$t('about.p2_3') }
        public get p3 () { return this.$t('about.p3') }

        created() {
            AnalyticsUtils.visit(this.$route);
            setTimeout(() => {
                store.isLoadingRoute = false;
            }, 1000);
        }
    }
