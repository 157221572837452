import { createWebHistory, createRouter } from "vue-router";

// TEST
//import TestDummy from '@/components/testdummy.vue'

// ADMIN
import LyricsManager from '@/components/admin/lyrics_manager.vue'
import AdminIndex from '@/components/admin/index.vue'

// PUBLIC
import Home from '@/components/home.vue'
import About from '@/components/about.vue'
import Signup from '@/components/signup.vue'
import Signin from '@/components/signin.vue'
import RecoverPassword from '@/components/recover_password.vue'

import PrivacyTerms from '@/components/privacy.vue'
import Terms from '@/components/terms.vue'
import Redirect from '@/components/redirect.vue'
//import Features from '@/components/features.vue'
import backSoon from '@/components/backSoon.vue'
import UnderConstruction from '@/components/under_construction.vue'

// lyrics
//import DiscoverLyrics from '@/components/lyrics/discover.vue'
import DiscoverLyricsAlt from '@/components/lyrics/discover_alt.vue'
import SelectMode from '@/components/lyrics/select-mode.vue'
import LyricsLayout from '@/components/lyrics/_layout.vue'
import BackgroundVideo from '@/components/lyrics/players/background_video.vue'
import PlayLimitWait from '@/components/lyrics/play_limit_wait.vue'
import LyricsEditorIndex from '@/components/editor/index.vue'

// SUBSCRIBERS
import DashboardIndex from '@/components/dashboard/index.vue'
import DashboardProgress from '@/components/dashboard/progress.vue'
import DashboardHistory from '@/components/dashboard/history.vue'
import DashboardContributions from '@/components/dashboard/contributions.vue'
import DashboardFavorites from '@/components/dashboard/favorites.vue'

// ACCOUNT/PROFILE
import ProfileLayout from '@/components/your-account/layout.vue'
import Profile from '@/components/your-account/profile.vue'

import state from "@/stores";


const SUBSCRIBERS_ONLY_PAGES = ['home', '/home', 'home/progress', '/home/contributions', '/home/favorites', '/home/', '/lyrics/editor', 'my-account'];
const CONTRIBUTORS_ONLY_PAGES = ['home', '/home', '/home/contributions', '/lyrics/editor', 'my-account'];

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { 
            path: '/',
            component: Home,
            meta: { 
                title: 'Nitome | Welcome, start practicing now with your favorite Japanese songs and lyrics', 
                metaTags: [
                    {
                        name: 'description',
                        content: 'Japanese Lyrics'
                    },
                    {
                        property: 'og:description',
                        content: 'Japanese Lyrics'
                    }
                ], 
            }
        },
        { 
            path: '/signup', 
            component: Signup,
            meta: { title: 'Nitome | Signup', }
        },
        { 
            path: '/signin', 
            component: Signin,
            meta: { title: 'Nitome | Signin', }
        },
        { 
            path: '/join-class', 
            component: Signin,
            meta: { title: 'Nitome | Signin with a class code', }
        },
        { 
            path: '/recover-password', 
            component: RecoverPassword,
            meta: { title: 'Nitome | Recover password', }
        },

        { 
            path: '/lyrics', 
            component: LyricsLayout,
            meta: { title: 'Nitome | Home' },
            children: [
                {
                    path: 'search',
                    component: DiscoverLyricsAlt,
                    meta: { title: 'Nitome | Discover Japanese artist and bands to practice with the lyrics', requiresAuth: false },

                    /* TODO: STAY IN THE SAME PAGE AND REARANGE THE BACKGROUND ITEMS OR REDIRECT TO AN ADVANCED SEARCH LIKE A REGULAR WEBSITE? */
                    /* children: [
                        {
                            path: ':genre/:artist/:q',
                            component: DiscoverLyrics,
                            meta: { title: 'Nitome | Discover Japanese artist and bands to practice with the lyrics', requiresAuth: false }
                        },
                    ] */
                },
                {
                    path: 'select-mode/:lyricid',
                    component: SelectMode,
                    meta: { title: 'Nitome | Select the difficulty of the lyric to complete', 
                    metaTags: [
                        {
                          name: 'description',
                          content: 'Select any level you want to practice. Preview the review items for this lyric.'
                        },
                        {
                          property: 'og:description',
                          content: 'Select any level you want to practice. Preview the review items for this lyric.'
                        }
                    ],
                    requiresAuth: false }
                },
                {
                    path: 'youtube/:sessionid/:level',
                    component: BackgroundVideo,
                    meta: { title: 'Nitome | Playing lyrics', requiresAuth: true }
                },
                /*
                    MORE SCENARIOS/environments HERE?
                    {
                        path: ':scenario/:sessionid/:level',
                        component: BaseScenario,
                        meta: { title: 'Nitome | Learning lyrics', requiresAuth: true }
                    },
                */
                {
                    path: 'play-limit',
                    component: PlayLimitWait,
                    meta: { title: "Nitome | You've reached the maximum play limit", requiresAuth: false }
                },

                { 
                    path: 'editor/:slug', 
                    component: LyricsEditorIndex,
                    meta: { title: 'Nitome | Editor',  }
                },

                /* { // TODO: remove this
                    path: 'test', 
                    component: TestDummy,
                    meta: { title: 'Nitome | Terms' }
                }, */
                
                { path: '', component: DiscoverLyricsAlt }
            ]
        },
        { 
            path: '/my-account', 
            component: ProfileLayout,
            meta: { title: 'Nitome | My Account' },
            children: [
                {
                    path: 'profile',
                    component: Profile,
                    meta: { title: 'Nitome | Discover New Lyrics', requiresAuth: false }
                },
                /* {
                    path: '',
                    component: ,
                    meta: { title: 'Nitome | Select the difficulty', requiresAuth: false }
                },
                {
                    path: '',
                    component: ,
                    meta: { title: 'Nitome | Learning lyrics', requiresAuth: true }
                }, */
                { path: '', component: Profile }
            ]
        },
        { 
            path: '/about', 
            component: About,
            meta: { 
                title: 'Nitome | About us', 
                metaTags: [
                    {
                      name: 'description',
                      content: 'What is this website about'
                    },
                    {
                      property: 'og:description',
                      content: 'What is this website about'
                    }
                ],
                key: 'titles.about' }
        },
        { 
            path: '/privacy', 
            component: PrivacyTerms,
            meta: { title: 'Nitome | Privacy', }
        },
        { 
            path: '/terms', 
            component: Terms,
            meta: { title: 'Nitome | Terms', }
        },
        { 
            path: '/redirect', 
            component: Redirect,
            meta: { title: 'Nitome | Redirecting', }
        },
        /* { 
            path: '/features', 
            component: Features,
            meta: { title: 'Nitome | Features' }
        }, */
        { 
            path: '/be_back_soon', 
            name: 'be_back_soon',
            component: backSoon,
            meta: { title: 'Nitome | Be Back Soon', }
        },
        { 
            path: '/site-under-construction', 
            name: 'site-under-construction',
            component: UnderConstruction,
            meta: { title: 'Nitome | We are currently under construction but be back soon', }
        },

        { 
            path: '/dashboard', 
            component: DashboardIndex,
            meta: { title: 'Nitome | Home' },
            children: [
                {
                    path: 'progress',
                    component: DashboardProgress,
                    meta: { title: 'Nitome | Dashboard', requiresAuth: false }
                },
                {
                    path: 'history',
                    component: DashboardHistory,
                    meta: { title: 'Nitome | Editor', requiresAuth: false }
                },
                {
                    path: 'contributions',
                    component: DashboardContributions,
                    meta: { title: 'Nitome | Contributions', requiresAuth: true }
                },
                {
                    path: 'favorites',
                    component: DashboardFavorites,
                    meta: { title: "Nitome | Favorite Lyrics", requiresAuth: false }
                },
                { path: '', component: DashboardProgress }
            ]
        },

        { 
            path: '/admin', 
            component: AdminIndex,
            meta: { title: 'Nitome | Home' },
            children: [
                {
                    path: 'lyrics-manager',
                    component: LyricsManager,
                    meta: { title: 'Nitome | Manage Lyrics', requiresAuth: false }
                },
               
                { path: '', component: DashboardProgress }
            ]
        },
        /* {
            path: "*",
            component: Home,
        }, */
        //{ path: '/', redirect: '/' }
      //{ path: '/lyrics', component: Bar },
    ],
})


// TODO: https://github.com/vueuse/head
router.beforeEach(async (to: any, from: any, next) => {

    const isAuthenticated = await state.authenticationGuard(to.name);
    //console.log("isAuthenticated? " + isAuthenticated + " to: " + JSON.stringify(to));

    //next({ name: 'site-under-construction' }) // TODO: comment this out, testing purposes only or use before site goes live

    if (!isAuthenticated) { // TODO: this will always be true due to a token being requested for public access unless backend errors out
        if (to.name !== 'be_back_soon') {
            console.log("1. redirecting to ---> be_back_soon");
            next({ name: 'be_back_soon' })
        } else {
            console.log("2. redirecting to: " + to.name);
            next()
        }

    } else {
        // replacement for vuemeta
        document.title = to.meta.title
        //document.querySelector('meta[name="description"]').content = to.meta.metadesc;
        /* document.querySelector('meta[property="description"]')?.setAttribute("content", to.meta.metadesc);
        document.querySelector('meta[property="keywords"]')?.setAttribute("content", to.meta.metakeyw); */
        /* document.getElementsByTagName('meta')["keywords"].content = to.meta.metakeyw;
        document.getElementsByTagName('meta')["description"].content = to.meta.metadesc; */

        const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

        Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode?.removeChild(el));
        if (nearestWithMeta) {
            // Turn the meta tag definitions into actual elements in the head.
            nearestWithMeta.meta.metaTags.map(tagDef => {
                const tag = document.createElement('meta');

                Object.keys(tagDef).forEach(key => {
                    tag.setAttribute(key, tagDef[key]);
                });

                // We use this to track which meta tags we create so we don't interfere with other ones.
                tag.setAttribute('data-vue-router-controlled', '');

                return tag;
            })
            // Add the meta tags to the document head.
            .forEach(tag => document.head.appendChild(tag));
        }

        // i18n compatible
        //document.title = state.geti18n(to.meta.key)

        if (state.role === 'guest' && (SUBSCRIBERS_ONLY_PAGES.indexOf(to.name) > -1 || CONTRIBUTORS_ONLY_PAGES.indexOf(to.name) > -1))
        {
            console.log("3. guest role");
            //console.log("ROLE: **" + role + "** ACCESSING A SUBSCRIBER/CONTRIBUTOR ONLY PAGE " + to.name);
            next({ name: '/' })
        } 
        else if (state.role === 'contributor' && (SUBSCRIBERS_ONLY_PAGES.indexOf(to.name) > -1)) // contributor accessing a subscriber page
        {
            console.log("3. contributor role");
            //console.log("ROLE: **" + role + "** ACCESSING A SUBSCRIBER ONLY PAGE " + page.path);
            next({ name: '/' })
        } else {
            console.log("4. all good! redirecting to: " + to.name);
            next()
        }
    }
})

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    /* if (to.name) {
        // Start the route progress bar.
        //NProgress.start()
    } */

    //console.log("LOADING ROUTE " + JSON.stringify(to));
    state.isLoadingRoute = true;

    next()
})
  
/* router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    //NProgress.done()
})
 */

/* router.beforeEach((to, from, next) => {
    const currentUser = firebase.auth.currentUser;
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth && !currentUser) {
        next({ name: 'login', query: { redirect: to.path } });
    } else {
        next();
    }
}); */

export default router;