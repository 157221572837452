
    import { Vue, Options } from 'vue-class-component';
    import LyricsPlayer from '@/lib/LyricsPlayer/index.vue';
    import CountdownTimer from '@/lib/Timer/countdown.vue';
    import FourOptionsGrid from '@/lib/InputModes/four_options_grid.vue';
    import KanaKeyboard from '@/lib/InputModes/kana_keyboard.vue';
    import store from "@/stores";
    import service from "@/services/api";
    import gamestore from "@/stores/gameUtils";
    import Helpers from '@/services/helpers';
    import EndSessionModal from '@/lib/Modals/end_lyrics_play.vue';
    import AnalyticsUtils from "@/services/analytics";
    import LoadingScreen from '@/lib/LoadingScreen/loading_screen.vue';
    
    @Options({
        components: {
            LyricsPlayer, CountdownTimer, KanaKeyboard, FourOptionsGrid, EndSessionModal, LoadingScreen
        },
    })
    export default class BackgroundVideo extends Vue {

        isLoading: boolean = true;
        lyricsdata: any = {};
        showModal: boolean = false;
        level: any = '';
        isMounted: boolean = false;
        isLoadingLyric = true;
        correctAnswers = 0;
        incorrectAnswers = 0;
        totalAnswers = 0
        popupPosition = { left: -1000, bottom: -1000 }
        inputType: string = 'grid';
        sessionId: any = '';
        askForFeedback: boolean = true;

        // translations
         public get messages_loading_lyrics() { return this.$t('messages.loading_lyrics') }
        

        nodes: any[] = [
            "あ",
            "い",
            "う",
            "え",
            "お"
        ];     
        nodesOutside: any[] = [
            { letter: "y", tenten: "", alphabet: ["や", "", "ゆ", "", "よ"], diptonalphabet: ["ゃ", "", "ゅ", "", "ょ"] },
            { letter: "m", tenten: "", alphabet: ["ま", "み", "む", "め", "も"] },
            { letter: "w", tenten: "", alphabet: ["わ", "", "", "", "を"] },
            { letter: "r", tenten: "", alphabet: ["ら", "り", "る", "れ", "ろ"] },
            { letter: "ん", tenten: "", alphabet: ["な", "に", "ぬ", "ね", "の"],  },
            { letter: "k", tenten: "g", alphabet: ["か", "き", "く", "け", "こ"], tentenalphabet: ["が", "ぎ", "ぐ", "げ", "ご"] },
            { letter: "s", tenten: "z", alphabet: ["さ", "し", "す", "せ", "そ"], tentenalphabet: ["ざ", "じ", "ず", "ぜ", "ぞ"] },
            { letter: "t", tenten: "d", alphabet: ["た", "ち", "つ", "て", "と"], tentenalphabet: ["だ", "ぢ", "づ", "で", "ど"] },
            { letter: "h", tenten: "b", alphabet: ["は", "ひ", "ふ", "へ", "ほ"], tentenalphabet: ["ば", "び", "ぶ", "べ", "ぼ"], maru: "p", marualphabet: ["ぱ", "ぴ", "ぷ", "ぺ", "ぽ"] },
        ];

        async created() {
            AnalyticsUtils.visit(this.$route);
            
            store.loaderMessage = this.messages_loading_lyrics;

            this.sessionId = this.$route.params.sessionid;
            this.level = this.$route.params.level;
            
            if (!this.sessionId || !this.level) 
                this.$router.push({ path: `/lyrics`}); // go back to pick a song
            
            //gamestore.set
            this.isLoading = true;

            this.lyricsdata = JSON.parse(await service.fetchFromApi({url: "lyrics/getLyricBySession/" + this.sessionId + "/" + this.level}));
            Helpers.lgi(JSON.stringify(this.lyricsdata));
            this.isLoadingLyric = false;
            //this.totalAnswers = this.lyricsdata.toComplete.length

            if (this.lyricsdata) {
                          
                //this.restartCompletion();

                // find all the ones to complete out of the characters array of each verse line
                let toComplete: any = [];

                if (this.level !== 'karaoke') 
                {
                    this.lyricsdata.verses.forEach(verse => {
                        const characters = verse.characters.filter(x => x.q == true);

                        if (characters && characters.length > 0)
                        {
                            // defaults/restart
                            characters.forEach(ch => {
                                ch.isComplete = false;
                                ch.isSkipped = false;
                                ch.numberOfRepetitions = 0;

                                // updated later
                                ch.coordinates = {};
                                ch.startTime = 0;
                                ch.endTime = 0;
                                ch.diffTime = 0;
                                ch.tries = 0;
                                ch.quality = 0;
                            });

                            toComplete.push({ line: verse.id, characters })
                        }
                    });

                    gamestore.setToComplete(toComplete /* this.lyricsdata.toComplete */);
                }

                Helpers.lgi(JSON.stringify(toComplete));
                gamestore.currentLevel = this.level
                // initialize the first options to complete
                /* let nextObjFound;
                for (let index = 0; index < this.lyricsdata.toComplete.length; index++) {
                    const el = this.lyricsdata.toComplete[index];

                    if (el.isComplete == false && el.isSkipped == false) {
                        nextObjFound = el;
                        break;
                    }                   
                }
                //var toCompleteObjFound = this.toComplete.find(x => x.line == this.currentLine && x.isComplete == false && x.isSkipped == false );
                this.nextToComplete(nextObjFound); */
            }
            this.isLoading = false;
        }

        async mounted() {
            this.isMounted = true;
            this.showModal = true;

            /* if (lyricsdata.success != null && lyricsdata.success == false) {
                return { status: 302, redirect: 'lyrics/wait_countdown', props: {} }
            } */

            //modal.set(PuzzleModal);
            //this.getNextNodeOptions({type: 0, kanji: null, kana: "へや", line: 38});
        }

        showPopupAtPosition(idAndCoordinates) {
            this.popupPosition = idAndCoordinates

            gamestore.updatePopupPosition(idAndCoordinates);
        }
        restartCompletion() {
            if (this.lyricsdata.toComplete && this.lyricsdata.toComplete.length > 0) {
                this.lyricsdata.toComplete.forEach((el: any) => {
                    el.isComplete = false;
                    el.isSkipped = false;
                    el.numberOfRepetitions = 0;
                });
            } else {
                this.lyricsdata.toComplete = [];
            }

            this.lyricsdata.toComplete.sort((a, b) => (a.line > b.line) ? 1 : -1)
        }
        
        /*
         {type: 0, kanji: null, kana: "ん", line: 5}
        1: {type: 0, kanji: null, kana: "こ", line: 36}
        2: {type: 0, kanji: null, kana: "ざ", line: 38}
        3: {type: 0, kanji: null, kana: "さ", line: 38}
        4: {type: 0, kanji: null, kana: "ね", line: 39}
        5: {type: 0, kanji: null, kana: "お", line: 40}
        6: {type: 0, kanji: null, kana: "や", line: 40}
        7: {type: 0, kanji: null, kana: "ち", line: 42}
        8: {type: 0, kanji: null, kana: "せ", line: 42}
        9: {type: 0, kanji: null, kana: "へ", line: 1}
        10: {type: 0, kanji: null, kana: "へ", line: 41}
        11: {type: 0, kanji: null, kana: "だ", line: 3}
        12: {type: 0, kanji: null, kana: "だ", line: 8}
        */
        async onGameFinished(results) {
            Helpers.lgi(results);

            var result = { 
                playSessionId: this.sessionId,
                completedItems: gamestore.toCompleteQueue
            };

            await service.postToApi("lyrics/endPlaySession", { body: result } )

            if (results && results.length > 0) {
               /*  var rightAnswers = results.map(item => item.isComplete).reduce((prev, next) => prev + next);
                var wrongAnswers = 0;  */// skipped
                
                // show modal background depending on the success rate
                //modal.set(bind(EndLyricsGameModal, { rightAnswers, total: results.length }));
                this.showModal = true;
            }
        }

        onLoading(percentage) {
            Helpers.lgi("LOADING " + percentage);
            store.loaderMessage = 'Loading ' + Math.floor(percentage) + '%...';
        }

        onDoneLoading() {
            store.isLoadingRoute = false;
        }

        inputTypeSelected(inputTypeSelected: string) {
            this.inputType = inputTypeSelected;
        }
        // pass output from puzzle component to input player component
        onPuzzleWordFormed(word) {
            Helpers.lgi(word);
            //this.player.setUserInput(word);

            gamestore.answer(word);
        }
        
        onSuccessAnswer(word) {
            Helpers.lgi(word);
            this.correctAnswers++;
        }
        nextToComplete(character) {
            Helpers.lgi("---------- NEXT TO COMPLETE ----------");
            Helpers.lgi(character);

            //this.getNextNodeOptions(character);
            //this.shuffle(this.nodes);
        }

        confirmModal(){

        }
        closeModal(){

        }
    }
