
    import { Vue, Options } from 'vue-class-component';
    import apiService from "@/services/api";
    import BpmCalculator from '@/services/bpmCalculator';
    import helpers from '@/services/helpers';

    interface lyricModel {
        videoUrl: string
        songTitle: string
        bandName: string
        videoId: string
        verses: any[]
        publishedAt: string
        bpm: string
    }

    @Options({
        props: {
            showModal: Boolean,
            verses: Object
        },
        emits: ['close']
    }) 
    export default class SidebySideLyricModal extends Vue {

        registerdata: lyricModel = { songTitle: '', bandName: '', videoId: '', videoUrl: '', verses:[], publishedAt: '', bpm: '' };
        currentStep = 0;
        isLoadingVideoInfo: boolean = false;
        isSaving: boolean = false;
        videoExists: boolean = false;
        saved: boolean = false;
        verifyingVideoExists: boolean = false;
        lyric_text = '';
        timer;
        questionIndex
        showModal: boolean = false
        verses: any = [];
        kanjiVerses: string = '';
        romajiVerses: string = '';
        englishVerses: string = '';
        spanishVerses: string = '';
        indonesianVerses: string = '';
        portugueseVerses: string = '';
        koreanVerses: string = '';
        russianVerses: string = '';
        italianVerses: string = '';
        frenchVerses: string = '';
        germanVerses: string = '';
        hindiVerses: string = '';

        lines: any = [];
        viewMode: string = 'sidebyside'

        playerVars: any = {
            'autoplay': 1,
            'playsinline': 1,
            'controls': 1,
            'disablekb': 1,
            'fs': 0,
            'rel': 0,
            'cc_load_policy': 0,
            'iv_load_policy': 3,
            'modestbranding': 0,
            'enablejsapi': 1
        }

        // translations
        public get actions_add_lyric () { return this.$t('actions.add_lyric') }
        public get labels_song_title () { return this.$t('labels.song_title') }
        public get labels_band_name () { return this.$t('labels.band_name') }
        public get labels_youtube_video_url () { return this.$t('labels.youtube_video_url') }
        public get labels_youtube_video_id () { return this.$t('labels.youtube_video_id') }
        public get labels_add_lyric () { return this.$t('labels.add_lyric') }
        public get actions_cancel () { return this.$t('actions.cancel') }
        public get actions_continue () { return this.$t('actions.continue') }
        public get actions_save () { return this.$t('actions.save') }
        public get labels_input_youtube_url_placeholder () { return this.$t('labels.input_youtube_url_placeholder') }

        mounted() {
            BpmCalculator.init();
        }
    
        toggleViewMode() {
            if (this.viewMode == 'rows')
                this.viewMode = 'sidebyside'    
            else 
                this.viewMode = 'rows'
        }

        parseVersesToTextarea(lyric) {
            console.log(lyric)
            this.lines = lyric.versesList
            this.kanjiVerses = lyric.versesList.map((line) => line.text).join("\n");
            this.romajiVerses = lyric.versesList.map((line) => line.romaji).join("\n");
            this.englishVerses = lyric.versesList.map((line) => line.en).join("\n");

            // automatic
            this.spanishVerses = lyric.versesList.map((line) => line.es).join("\n");
            this.indonesianVerses = lyric.versesList.map((line) => line.indonesian).join("\n");
            this.portugueseVerses = lyric.versesList.map((line) => line.portuguese).join("\n");
            this.koreanVerses = lyric.versesList.map((line) => line.korean).join("\n");
            this.russianVerses = lyric.versesList.map((line) => line.russian).join("\n");
            this.italianVerses = lyric.versesList.map((line) => line.italian).join("\n");
            this.frenchVerses = lyric.versesList.map((line) => line.french).join("\n");
            this.germanVerses = lyric.versesList.map((line) => line.german).join("\n");
            this.hindiVerses = lyric.versesList.map((line) => line.hindi).join("\n");
        }
        cancel() {
            if (this.currentStep > 0) 
            {
                this.currentStep--;
            } 
            else 
            {
                this.$emit("close");
            }
        }
        debounce() {
            clearTimeout(this.timer);
            this.timer = setTimeout(async () => {
                await this.checkIfAlreadyExists();
            }, 500);
        }
        async checkIfAlreadyExists() {
            if (this.registerdata.songTitle && this.registerdata.bandName && this.registerdata.videoId) 
            {
                this.verifyingVideoExists = true;
                this.videoExists = await apiService.fetchFromApi({url: "lyrics/checkExists/" + this.registerdata.songTitle + "/" + this.registerdata.bandName + "/" + this.registerdata.videoId});
                this.verifyingVideoExists = false;
            }
        }
        async nextStep() {
            if (this.currentStep < 2) 
            {
                this.currentStep++;         
            }

            if (this.currentStep == 2) 
            {
                this.isSaving = true;
                await this.save();
            }
        }
        
        async getVideoInfo() {
            console.log(this.registerdata)
            this.isLoadingVideoInfo = true
            this.registerdata.videoId = helpers.getVideoIdFromUrl(this.registerdata.videoUrl);

            if (this.registerdata.videoId != '') {
                const info = JSON.parse(await apiService.getYoutubeVideoInfo(this.registerdata.videoId));
                console.log(info)
                // map data from the youtube api response
                this.registerdata.publishedAt = info.snippet.publishedAt
                this.registerdata.songTitle = info.snippet.title
                this.registerdata.bandName = info.snippet.tags[0] // just assuming is the first value (let the user edit to fix it just in case is not the right one)
            }
        }
        // create objects and refVerses
        parseLyricText() {
            let verses = this.lyric_text.trim().split("\n");
            let versesArr: any[] = [];
            let id = 0;
            verses.forEach(verse => {
                if (verse && verse != null && verse.trim() != '') 
                {
                    var ref = versesArr.find(x => x.text == verse.trim());

                    // repeated verse, find the refId
                    if (ref && ref != null) 
                    {
                        versesArr.push({ id: id, refId: ref.id, romaji: "", en: "", es: ""});
                    } 
                    else 
                    {
                        versesArr.push({ id: id, text: verse, romaji: "", en: "", es: ""});
                    }
                    id++;
                }
            });

            this.registerdata.verses = versesArr;
        }
        async save() {
            // get the new id and redirect to the editor
            var results = (await apiService.postToApi("dashboard/lyric/new", { body: this.registerdata} ));
            
            if (results && results.id)
            {   
                this.saved = true;
                this.$emit("close");

                this.$router.push({ path: `/lyrics/zynch/${results.id}` });
            }
        }

        tapBpm() {
            const bpm = BpmCalculator.getTempo();
            this.registerdata.bpm = bpm + ''
        }
    }
