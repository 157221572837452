/* class TLFrameBase {
  constructor(){

  }
} */
class TLFrame /* extends TLFrameBase */ {
    id: string = '';
    dupFromId: string = '';
    position: number = 0;
    name: string = '';
    start: number = 0;
    end: number= 0;
    line: number = 0;
    imagepath: string = '';
    duration: number = 0;
    transition: number = 0;
    layer: number = 0;
    isSelected: boolean;
    isMovable: boolean;
    isResizable: boolean;
    isResizableLeft: boolean;
    isDeletable: boolean;
  
    isPose: boolean;
    isPhrase: boolean;
  
    signs: TLFrame[] = [];
    width: string = '';
  
    previousStart: number = 0;
    previousEnd: number = 0;
  
    parentFrames: string[] = [];
  
    constructor({id = '', position = 0, name = '', start = 0, end = 0, transition = 0, layer = 0, path = ''} = {}) {
      //super();
      this.id = id;
      this.position = position;
      this.name = name;
      this.start = start;
      this.end = end;
      this.transition = transition;
      this.layer = layer;
      this.imagepath = path;
      this.isSelected = false;
      this.isMovable = true;
      this.isResizable = true;
      this.isResizableLeft = true;
      this.isDeletable = true;
  
      this.isPose = false;
      this.isPhrase = false;
  
      this.dupFromId = '';
      this.previousStart = start;
      this.previousEnd = end;
    }

    /* log () {
      console.log(`year: ${this.year}, month: ${this.month}, day: ${this.day}`);
    } */
  }
  
  class Player {
    _isPlaying: boolean = false;
    _currentTime = 0;
    _playbackRate = 1;
    _loop = [];
  
    get isPlaying(): boolean {
      return this._isPlaying;
    }
    get currentTime() {
      return this._currentTime;
    }
    set currentTime( value ) {
      this._currentTime = value;
    }
    get playbackRate() {
      return this._playbackRate;
    }
    set playbackRate( value ) {
      this._playbackRate = value;
    }
    setLoop ( value: any ) {
      this._loop = value;
    }
    play () {
      this._isPlaying = true;
    }
    pause () {
      this._isPlaying = false;
    }
    tick ( delta : any) {
      if ( this._isPlaying ) {
        this._currentTime += ( delta / 1000 ) * this._playbackRate;
      }
      if ( this._loop ) {
        if ( this._currentTime > this._loop[ 1 ] ) this._currentTime = this._loop[ 0 ];
      }
    }
  }
  
  export {
    Player,
    TLFrame,
    /* TLFrameBase */
  };
  