import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-065f2b79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white p-4 m-4 md:p-8 md:m-8 lg:p-24 lg:m-24 noselect" }
const _hoisted_2 = { class: "text-base md:text-lg mt-4" }
const _hoisted_3 = { class: "text-base md:text-lg mt-4" }
const _hoisted_4 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_5 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_6 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_7 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_8 = { class: "text-base md:text-lg mt-4" }
const _hoisted_9 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_10 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_11 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_12 = { class: "text-base md:text-lg mt-4" }
const _hoisted_13 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_14 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_15 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_16 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_17 = { class: "text-base md:text-lg mt-4" }
const _hoisted_18 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_19 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_20 = { class: "text-base md:text-lg mt-4" }
const _hoisted_21 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_22 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_23 = { class: "text-base md:text-lg mt-4" }
const _hoisted_24 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_25 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_26 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_27 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_28 = { class: "text-base md:text-lg mt-4" }
const _hoisted_29 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_30 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_31 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_32 = { class: "text-base md:text-lg mt-4" }
const _hoisted_33 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_34 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_35 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_36 = { class: "text-base md:text-lg mt-4" }
const _hoisted_37 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_38 = { class: "text-base md:text-lg mt-4" }
const _hoisted_39 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_40 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_41 = { class: "text-base md:text-lg mt-4" }
const _hoisted_42 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_43 = { class: "text-base md:text-lg mt-4" }
const _hoisted_44 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_45 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_46 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_47 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_48 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_49 = { class: "text-base md:text-lg mt-4" }
const _hoisted_50 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_51 = { class: "text-base md:text-lg mt-4" }
const _hoisted_52 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_53 = { class: "text-base md:text-lg mt-4" }
const _hoisted_54 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_55 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_56 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_57 = { class: "text-base md:text-lg mt-4" }
const _hoisted_58 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_59 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_60 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_61 = { class: "text-base md:text-lg mt-4" }
const _hoisted_62 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_63 = { class: "text-base sm:text-xs md:text-sm" }
const _hoisted_64 = { class: "text-base md:text-lg mt-4" }
const _hoisted_65 = { class: "text-base sm:text-xs md:text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclaimer = _resolveComponent("Disclaimer")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.section1_title), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.version) + " ", 1),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.section2_title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.section2_pha1), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.section2_pha2), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.section2_pha3), 1),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.section2_pha4), 1),
      _createElementVNode("h1", _hoisted_8, _toDisplayString(_ctx.section3_title), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.section3_pha2), 1),
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.section3_pha3), 1),
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.section3_pha4), 1),
      _createElementVNode("h1", _hoisted_12, _toDisplayString(_ctx.section4_title), 1),
      _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.section4_pha1), 1),
      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.section4_pha2), 1),
      _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.section4_pha3), 1),
      _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.section4_pha4), 1),
      _createElementVNode("h1", _hoisted_17, _toDisplayString(_ctx.section5_title), 1),
      _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.section5_pha1), 1),
      _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.section5_pha2), 1),
      _createElementVNode("h1", _hoisted_20, _toDisplayString(_ctx.section6_title), 1),
      _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.section6_pha1), 1),
      _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.section6_pha2), 1),
      _createElementVNode("h1", _hoisted_23, _toDisplayString(_ctx.section7_title), 1),
      _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.section7_pha1), 1),
      _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.section7_pha2), 1),
      _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.section7_pha3), 1),
      _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.section7_pha4), 1),
      _createElementVNode("h1", _hoisted_28, _toDisplayString(_ctx.section8_title), 1),
      _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.section8_pha1), 1),
      _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.section8_pha2), 1),
      _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.section8_pha3), 1),
      _createElementVNode("h1", _hoisted_32, _toDisplayString(_ctx.section9_title), 1),
      _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.section9_pha1), 1),
      _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.section9_pha2), 1),
      _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.section9_pha3), 1),
      _createElementVNode("h1", _hoisted_36, _toDisplayString(_ctx.section10_title), 1),
      _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.section10_pha1), 1),
      _createElementVNode("h1", _hoisted_38, _toDisplayString(_ctx.section11_title), 1),
      _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.section11_pha1), 1),
      _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.section11_pha2), 1),
      _createElementVNode("h1", _hoisted_41, _toDisplayString(_ctx.section12_title), 1),
      _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.section12_pha1), 1),
      _createElementVNode("h1", _hoisted_43, _toDisplayString(_ctx.section13_title), 1),
      _createElementVNode("p", _hoisted_44, _toDisplayString(_ctx.section13_pha1), 1),
      _createElementVNode("p", _hoisted_45, _toDisplayString(_ctx.section13_pha2), 1),
      _createElementVNode("p", _hoisted_46, _toDisplayString(_ctx.section13_pha3), 1),
      _createElementVNode("p", _hoisted_47, _toDisplayString(_ctx.section13_pha4), 1),
      _createElementVNode("p", _hoisted_48, _toDisplayString(_ctx.section13_pha5), 1),
      _createElementVNode("h1", _hoisted_49, _toDisplayString(_ctx.section14_title), 1),
      _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.section14_pha1), 1),
      _createElementVNode("h1", _hoisted_51, _toDisplayString(_ctx.section15_title), 1),
      _createElementVNode("p", _hoisted_52, _toDisplayString(_ctx.section15_pha1), 1),
      _createElementVNode("h1", _hoisted_53, _toDisplayString(_ctx.section16_title), 1),
      _createElementVNode("p", _hoisted_54, _toDisplayString(_ctx.section16_pha1), 1),
      _createElementVNode("p", _hoisted_55, _toDisplayString(_ctx.section16_pha2), 1),
      _createElementVNode("p", _hoisted_56, _toDisplayString(_ctx.section16_pha3), 1),
      _createElementVNode("h1", _hoisted_57, _toDisplayString(_ctx.section17_title), 1),
      _createElementVNode("p", _hoisted_58, _toDisplayString(_ctx.section17_pha1), 1),
      _createElementVNode("p", _hoisted_59, _toDisplayString(_ctx.section17_pha2), 1),
      _createElementVNode("p", _hoisted_60, _toDisplayString(_ctx.section17_pha3), 1),
      _createElementVNode("h1", _hoisted_61, _toDisplayString(_ctx.section18_title), 1),
      _createElementVNode("p", _hoisted_62, _toDisplayString(_ctx.section18_pha1), 1),
      _createElementVNode("p", _hoisted_63, _toDisplayString(_ctx.section18_pha2), 1),
      _createElementVNode("h1", _hoisted_64, _toDisplayString(_ctx.section19_title), 1),
      _createElementVNode("p", _hoisted_65, _toDisplayString(_ctx.section19_pha1), 1),
      _createVNode(_component_Disclaimer)
    ]),
    _createVNode(_component_Footer)
  ], 64))
}