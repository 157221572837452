
    import { Vue, Options } from 'vue-class-component';
    import apiService from "@/services/api";

    interface lyricModel {
        videoUrl: string
        songTitle: string
        bandName: string
        videoId: string
        verses: any[]
        publishedAt: string
    }

    @Options({
        props: {
            showModal: Boolean
        },
        emits: ['close']
    }) 
    export default class NewLyricModal extends Vue {

        registerdata: lyricModel = { songTitle: '', bandName: '', videoId: '', videoUrl: '', verses:[], publishedAt: '' };
        currentStep = 0;
        isLoadingVideoInfo: boolean = false;
        isSaving: boolean = false;
        videoExists: boolean = false;
        saved: boolean = false;
        verifyingVideoExists: boolean = false;
        lyric_text = '';
        timer;
        questionIndex
        showModal: boolean = false

        // translations
        public get actions_add_lyric () { return this.$t('actions.add_lyric') }
        public get labels_song_title () { return this.$t('labels.song_title') }
        public get labels_band_name () { return this.$t('labels.band_name') }
        public get labels_youtube_video_url () { return this.$t('labels.youtube_video_url') }
        public get labels_youtube_video_id () { return this.$t('labels.youtube_video_id') }
        public get labels_add_lyric () { return this.$t('labels.add_lyric') }
        public get actions_cancel () { return this.$t('actions.cancel') }
        public get actions_continue () { return this.$t('actions.continue') }
        public get actions_save () { return this.$t('actions.save') }
        public get labels_input_youtube_url_placeholder () { return this.$t('labels.input_youtube_url_placeholder') }

        mounted() {
        
        }
    
        cancel() {
            if (this.currentStep > 0) 
            {
                this.currentStep--;
            } 
            else 
            {
                //close();
                this.$emit("close");
            }
        }
        debounce() {
            clearTimeout(this.timer);
            this.timer = setTimeout(async () => {
                await this.checkIfAlreadyExists();
            }, 500);
        }
        async checkIfAlreadyExists() {
            if (this.registerdata.songTitle && this.registerdata.bandName && this.registerdata.videoId) 
            {
                this.verifyingVideoExists = true;
                this.videoExists = await apiService.fetchFromApi({url: "lyrics/checkExists/" + this.registerdata.songTitle + "/" + this.registerdata.bandName + "/" + this.registerdata.videoId});
                this.verifyingVideoExists = false;
            }
        }
        async nextStep() {
            if (this.currentStep < 2) 
            {
                this.currentStep++;         
            }

            if (this.currentStep == 2) 
            {
                this.isSaving = true;
                await this.save();
            }
        }
        isUrl(url: string): boolean {
            return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1;
        }
        getVideoIdFromUrl(url: string): string {
            if (!this.isUrl(this.registerdata.videoUrl)) return ''
            const regex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#]*).*/; // 186:78  error  Unnecessary escape character: \&  no-useless-escape \&\?
            var match = url.match(regex)
            if (match && match[1].length == 11) {
                return match[1];
            } else {
                //error
                return ''
            }
        }
        async getVideoInfo() {
            this.isLoadingVideoInfo = true
            this.registerdata.videoId = this.getVideoIdFromUrl(this.registerdata.videoUrl);

            if (this.registerdata.videoId != '') {
                const info = JSON.parse(await apiService.getYoutubeVideoInfo(this.registerdata.videoId));
                console.log(info)
                // map data from the youtube api response
                this.registerdata.publishedAt = info.snippet.publishedAt
                this.registerdata.songTitle = info.snippet.title
                this.registerdata.bandName = info.snippet.tags[0] // just assuming is the first value (let the user edit to fix it just in case is not the right one)
            }
        }
        // create objects and refVerses
        parseLyricText() {
            let verses = this.lyric_text.trim().split("\n");

            let versesArr: any[] = [];
            let id = 0;
            verses.forEach(verse => {
                if (verse && verse != null && verse.trim() != '') 
                {
                    var ref = versesArr.find(x => x.text == verse.trim());

                    // repeated verse, find the refId
                    if (ref && ref != null) 
                    {
                        versesArr.push({ id: id, refId: ref.id, romaji: "", en: "", es: ""});
                    } 
                    else 
                    {
                        versesArr.push({ id: id, text: verse, romaji: "", en: "", es: ""});
                    }
                    id++;
                }
            });

            this.registerdata.verses = versesArr;
        }
        async save() {
            // get the new id and redirect to the editor
            var results = (await apiService.postToApi("dashboard/lyric/new", { body: this.registerdata} ));
            
            if (results && results.id)
            {   
                this.saved = true;
                //close();
                this.$emit("close");

                this.$router.push({ path: `/lyrics/zynch/${results.id}` });

                /* prefetch("/lyrics/zynch/" + results.id).then(()=> {
                    goto("/lyrics/zynch/" + results.id).then(()=> {
                        console.log("goto NAVIGATING");
                    });
                }); */
            }
        }
    }
