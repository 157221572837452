import axios from "axios";
import { TokenResponse } from "../models/TokenResponse";
import EncUtils from './encUtils';

const baseUrl: string = process.env.VUE_APP_API_URL;// string = "https://localhost:44343/api/";
const getPubIpUrlService = "https://api.ipify.org?format=json";
const youtubeApiUrl: string = process.env.VUE_APP_YOUTUBE_API_URL;
const youtubeApiKey: string = process.env.VUE_APP_YOUTUBE_API_KEY;

export default {

  getHeaders() {
    return {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
    };
   },

  async getHome(): Promise<any> {
    const raw = (await axios.get<any>(`${baseUrl}lyrics/getHomeDashboard`)).data;

    return EncUtils.decryptUsingAES256(raw)
  },

  async getFromApi(url, queryparams: string): Promise<any> {
      const encryptedQueryParams = EncUtils.encryptUsingAES256(queryparams);

      const raw = (await axios.get<any>(`${baseUrl}${url}` + '?' + encryptedQueryParams, { headers: this.getHeaders() })).data;

      return EncUtils.decryptUsingAES256(raw)
  },

  async fetchFromApi({ url: _url } = { url: 'en' }): Promise<any> {
    const raw = (await axios.get<any>(`${baseUrl}${_url}`, { headers: this.getHeaders() })).data;
    
    return EncUtils.decryptUsingAES256(raw)
  },

  async postToApi(url, { body: _body }): Promise<any> {
      //if(!_url) return;
      console.log(url + ' ---> ' + JSON.stringify(_body))
      const encryptedBody = EncUtils.encryptUsingAES256(JSON.stringify(_body));
      const raw = (await axios.post(`${baseUrl}${url}`, encryptedBody, {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8'
          }
      })).data;

      const decryptedResponse = EncUtils.decryptUsingAES256(raw)
      console.log(decryptedResponse)
      return decryptedResponse;
  },

  async putToApi(_url, { body: _body }): Promise<any> {
    //if(!_url) return;
    return axios.put(`${baseUrl}${_url}`, _body);
  },
  async getAuthToken({ url: _url } = { url: 'en' }): Promise<any> {
      const raw = (await axios.get<TokenResponse>(`${baseUrl}${_url}`)).data;
      return EncUtils.decryptUsingAES256(raw)
  },

  async getClientApi(): Promise<any> {
    const headers = { 
      'Content-Type': 'application/json'
    };

    return axios.get<any>(getPubIpUrlService, {headers})
  },

  async getYoutubeVideoInfo(videoId): Promise<any> {
    const videoResults = (await axios.get<any>(`${youtubeApiUrl}&id=${videoId}&key=${youtubeApiKey}`)).data;

    if (!videoResults || !videoResults.items || videoResults.items.length == 0) return

    const video = videoResults.items[0]

    return video
  },
}