import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7573f65c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container rounded-3xl" }
const _hoisted_2 = {
  class: "questionBox",
  id: "popup",
  ref: "papersElm"
}
const _hoisted_3 = { class: "questionContainer" }
const _hoisted_4 = { class: "title is-6" }
const _hoisted_5 = { class: "titleContainer title" }
const _hoisted_6 = { class: "questionFooter" }
const _hoisted_7 = {
  class: "pagination",
  role: "navigation",
  "aria-label": "pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, _mergeProps(_ctx.$attrs, {
    classes: "flex justify-center items-center",
    "content-class": "relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900"
  }), {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_Transition, {
            "enter-active-class": "animated zoomIn",
            "leave-active-class": "animated zoomOut",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("header", null, [
                  _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.titles_congratulations), 1)
                ]),
                _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.results), 1),
                _createElementVNode("footer", _hoisted_6, [
                  _createElementVNode("nav", _hoisted_7, [
                    _createElementVNode("a", {
                      class: "btn btn-secondary",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args)))
                    }, _toDisplayString(_ctx.actions_cancel), 1),
                    _createElementVNode("a", {
                      class: "btn btn-primary",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
                    }, _toDisplayString(_ctx.actions_ok), 1)
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ], 512)
      ])
    ]),
    _: 1
  }, 16))
}