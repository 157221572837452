
    import { Vue, Options } from 'vue-class-component';
    import PublicHeader from '@/lib/SiteLayout/Header/public.vue';
    import Footer from '@/lib/SiteLayout/Footer/footer.vue';
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    
    @Options({
        components: {
            PublicHeader, Footer, Disclaimer
        },
    })
    export default class ProfileLayout extends Vue {
        
        // translations
        public get titles_profile () { return this.$t('titles.profile') }
        public get actions_signout () { return this.$t('actions.signout') }

        signout() {

        }
    }
