
    import { Vue, Options } from 'vue-class-component';
    //import { Ref } from 'vue-property-decorator'
    import EventBus from '@/services/pubsub'
    import { ref } from 'vue';

    @Options({
        prop: {
            frame: Object,
            scale: Number
        }
    })
    export default class TimelineFrame extends Vue {

        dom: any = ref(null)
        resizeLeftRef: any = ref(null)
        resizeRightRef: any = ref(null)

        /* @Ref() dom!: any
        @Ref() resizeLeftRef!: any
        @Ref() resizeRightRef!: any */

        resized; resizeBegin;
        selected;
        
        frame;
        resizeleftend;
        resizerightend;

        width = '';
        left = '';
        top = '';
        movementX = 0;
        scale: number = 100;
        minDuration: number = 0.05;

        timeSubscription;
        animationSubscription;
        timeout;
        isResized: boolean = false;

        //emitter = mitt()

        /* export let event;
        export function handle(e){
        } */

        mounted() {    
            //this.scale = this.timelineService.getScale();
            this.isResized = false;
            EventBus.addEventListener('timelineScaled', (_scale: any) => {
                if (_scale) {
                    //console.log("CHANGIN Scale ON FRAMES " + _scale);
                    this.scale = _scale;
                    this.update();
                }
            });
            EventBus.addEventListener('animationMove', (e: any) => {
                if (this.frame.isSelected && this.frame.isMovable) {
                    this.mousemove(e);
                }
            });
            /* this.timeSubscription = timelineService.timelineScaled.subscribe((_scale: any) => {
                if (_scale) {
                    //console.log("CHANGIN Scale ON FRAMES " + _scale);
                    this.scale = _scale;
                    this.update();
                }
            });

            this.animationSubscription = timelineService.animationMove.subscribe((e: any) => {
                if (this.frame.isSelected && this.frame.isMovable) {
                    this.mousemove(e);
                }
            }); */

            this.update();
            
            //this.addEventListener('mousedown', testEvent);
            if (this.frame.isResizable && this.resizeLeftRef && this.resizeRightRef) {
                this.resizeLeftRef.addEventListener('mousedown', this.mousedownW);
                this.resizeRightRef.addEventListener('mousedown', this.mousedownE);
            }

            // scroll hasta el elemento recien agregado o duplicado
            this.dom.scrollIntoView({behavior:"smooth"});
        }

        update() {
            this.left = ( +this.frame.start * +this.scale ) + 'px';
            this.top = ( this.frame.layer * 64 ) + 'px';
            this.width = ( ( +this.frame.end - +this.frame.start ) * +this.scale - 2 ) + 'px';

            //console.log("left: " + left + " // width: " + width);
            //dom.left = left +"px";
            //dom.top = top +"px";
            //dom.width = width +"px";

            this.frame.duration = +this.frame.end - +this.frame.start;

            //console.log("frame.start: " + frame.start + " // frame.end: " + frame.end + " // left: " + left + " // top: " + top);
        } 

        testEvent(e: any) {
            this.$emit('selected', e);
        }
        mousedown(e: any) {
            this.$emit('moused', e);
        }
        click(e: any) {
            e.stopPropagation();
            
            this.$emit('selected', e);
        }

        //_mouseMoveclickHandler = mousemove;

        // arrastrar bloques
        mousemove(e: any) {
            this.movementX = +e.movementX | +e.webkitMovementX | +e.mozMovementX | 0;
            this.frame.start = +this.frame.start + (+this.movementX / +this.scale);
            this.frame.end = +this.frame.end + (+this.movementX / +this.scale);

            if ( this.frame.start < 0 ) {
                var offset = - this.frame.start;
                this.frame.start += offset;
                this.frame.end += offset;
            }

            //console.log(JSON.stringify(frame));
            this.update();
        }

        // ----------- lEFT ---------------
        _mouseDownWclickHandler
        _mouseMoveWclickHandler
        _mouseUpWclickHandler
        /* _mouseDownWclickHandler = this.mousedownW;
        _mouseMoveWclickHandler = this.mousemoveW;
        _mouseUpWclickHandler = this.mouseupW; */

        mousedownW(e: any) {
            e.stopPropagation();
            this.movementX = 0;

            if (this.frame.isResizable && this.frame.isResizableLeft) {
                this._mouseDownWclickHandler = document.addEventListener('mousemove', this.mousemoveW);
                this._mouseUpWclickHandler = document.addEventListener('mouseup', this.mouseupW);
            }

            //resizeBegin.emit(frame);
            this.$emit('resizeBegin', this.frame);
        }

        mousemoveW(e: any) {
            this.isResized = true;
            this.movementX = e.movementX | e.webkitMovementX | e.mozMovementX | 0;
            let result = +this.movementX / +this.scale;
            let start = +this.frame.start + +result;

            // Detects when the user stops moving & emit event
            if (this.timeout !== undefined) clearTimeout(this.timeout);
            this.timeout = setTimeout(()=> {
                //resizeleftend.emit(frame);
                this.$emit('resizeleftend', this.frame);
            }, 100);
            
            // limite minimo de tamaño (0.1s) por la derecha
            if((this.frame.end - start) > this.minDuration){
                this.frame.start = start;
                this.update();
            }
        }

        mouseupW(/* e: any */) {
            if ( Math.abs( this.movementX ) < 2 ) {
                //timelineService.selectAnimation( this.frame );
                this.$emit('frameselected', this.frame); // TODO:
            }

            document.removeEventListener('mousemove', this._mouseDownWclickHandler);
            document.removeEventListener('mouseup', this._mouseUpWclickHandler);

            if (this.isResized) {
                // resized.emit(frame);
                this.$emit('resized', this.frame);
            }

            this.isResized = false;
        }

        // ----------- RIGHT ---------------
        _mouseDownEclickHandler
        _mouseMoveEclickHandler
        _mouseUpEclickHandler
       /*  _mouseDownEclickHandler = this.mousedownE;
        _mouseMoveEclickHandler = this.mousemoveE;
        _mouseUpEclickHandler = this.mouseupE; */

        mousedownE(e: any) {
            e.stopPropagation();
            this.movementX = 0;

            if (this.frame.isResizable) {
                this._mouseMoveEclickHandler = document.addEventListener('mousemove', this.mousemoveE);
                this._mouseUpEclickHandler = document.addEventListener('mouseup', this.mouseupE);
            }

            //resizeBegin.emit(frame);
            this.$emit('resizeBegin', this.frame);
        }

        mousemoveE(e: any) {
            this.isResized = true;
            this.movementX = +e.movementX | +e.webkitMovementX | +e.mozMovementX | 0;
            let result = +this.movementX / +this.scale;
            let end = +this.frame.end + +result;

            // Detects when the user stops moving & emit event
            if (this.timeout !== undefined) clearTimeout(this.timeout);
                this.timeout = setTimeout(()=> {
                    //resizerightend.emit(frame);
                    this.$emit('resizerightend', this.frame);
                }, 100);
                
            // limite minimo de tamaño (0.1s) por la derecha
            if ((end - this.frame.start) > this.minDuration) {
                this.frame.end = end;
                this.update();
            }
        }

        mouseupE(/* e: any */) {
            document.removeEventListener('mousemove', this._mouseMoveEclickHandler);
            document.removeEventListener('mouseup', this._mouseUpEclickHandler);

            if (this.isResized) {
                //resized.emit(frame);
                this.$emit('resized', this.frame);
            }

            this.isResized = false;
        }

        destroyed () {
            try {
                /* this.timeSubscription();
                this.animationSubscription();

                if (this.timeSubscription) {
                    this.timeSubscription.unsubscribe();
                }
                if (this.animationSubscription) {
                    this.animationSubscription.unsubscribe();
                } */

                //this.emitter.all.clear()
            } catch(e){
                console.log("ERROR " + e);
            }
        }
    }
