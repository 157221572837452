import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9050a85a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container centered" }
const _hoisted_2 = { class: "mb-5 mr-5 ml-5 text-2xl" }
const _hoisted_3 = { class: "w-full max-w-lg flex flex-col py-5 px-8 form" }
const _hoisted_4 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.sitedown), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.thanks_lmk), 1)
    ])
  ]))
}