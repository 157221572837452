import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LyricsEditorTimeline = _resolveComponent("LyricsEditorTimeline")!

  return (_ctx.lyricsdata && _ctx.lyricsdata.id != '')
    ? (_openBlock(), _createBlock(_component_LyricsEditorTimeline, {
        key: 0,
        lyrics: _ctx.lyricsdata,
        onOnReady: _ctx.isEditorReady,
        onOnSave: _cache[0] || (_cache[0] = async (e) => {await _ctx.onSave(e)})
      }, null, 8, ["lyrics", "onOnReady"]))
    : _createCommentVNode("", true)
}