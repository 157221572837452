export class TokenResponse {

    public accessToken: string = '';
    public expirationTime: string = '';
    public idToken: string = '';
    public issuedTime: string = '';
    public refreshToken: string = '';
    
    public isUserWaiting: boolean = false;
    public lastPubIp: string = '';

    constructor() {
      
    }
}

