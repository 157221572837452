
    import { Vue, Options } from 'vue-class-component';
    import { ref } from 'vue'
    import * as THREE from 'three';
    import { OutlineEffect } from 'three/examples/jsm/effects/OutlineEffect.js';
    import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
    import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
    import anime from 'animejs/lib/anime.es.js';
    import { isMobile, isTablet } from 'mobile-device-detect';
    import store from "@/stores";
    import AnalyticsUtils from "@/services/analytics";
    import LoadingScreen from '@/lib/LoadingScreen/loading_screen.vue';

    const color_default = 0xf4d1d5; // pink
    const color_pink2 = 0xFFDEF6; // purple
    const color_dark_pink = 0xDBC6DB;
    const color_green = 0x62BAAC;
    const color_yellow = 0xFFDF82;
    const color_red = 0xf0475d;

    @Options({
        components: {
            LoadingScreen
        },
    }) 
    export default class HelloWorld extends Vue {
        scene!: any; 
        camera:any; 
        renderer: any; 
        container: any = ref(null); 
        divContainer: any = ref(null); 
        effect: any; animationFrameId: any;
        isLoading: boolean = true; 
        isMounted: boolean = false; 
        isAnalyticsEnabled: boolean = false;
        totalSecondsLoading = 0; startLoadingSeconds = 0; endLoadingSeconds = 0;
        scrollY: any = 0;
        allowControl: any = true;
        touchStartY: any;
        _event: any = {
            y: 0,
            deltaY: 0
        };
        startCameraPosition: any = { x: -1351, y: 301, z: 1100 };
        maxHeight: any;
        percentage: number = 0;
        timeline!: any; timelineRotation: any;
        clock = new THREE.Clock();
        locations: any = [];
        activeSection = 0;
        //sensitivity = 0.02;

        mouse: any = new THREE.Vector2();
        target: any = new THREE.Vector2();
        windowHalf: any;
        textConfig: any = {
            fontName: "RoundedMplus", 
            fontWeight: "regular",
            height: 0.5,
            size: 8,
            hover: 30,
            curveSegments: 4,
            bevelThickness: 0.2,
            bevelSize: 0.35,
            bevelEnabled: false,
            font: undefined,
            material: undefined
        };

        // snow
        particleNum: number = 5000;
        maxRange: number = 1000;
        minRange: number = this.maxRange / 2;
        textureSize: number = 60.0;
        isSnowActive: boolean = false;

        // OBJECTS
        trees: any[] = []; islands: any = []; cameraPOV: any = []; particles: any = []; vertices: any = []; velocities: any = [];
        sun: any;
        spriteWave1: any; waveSprites: any = [];
        deviceSmall: boolean = false;
   
        // DEFAULTS
        defaultSeasonColor: THREE.Color = new THREE.Color(color_dark_pink); // spring
        currentCameraPosition: any = null; currentCameraRotation: any;

        // translations
        public get messagespha1 () { return this.$t('messages.pha1') }
        public get messagespha2 () { return this.$t('messages.pha2') }
        public get messagespha3 () { return this.$t('messages.pha3') }
        public get messagespha4 () { return this.$t('messages.pha4') }
        public get messagessubpha1 () { return this.$t('messages.subpha1') }
        public get messagessubpha2 () { return this.$t('messages.subpha2') }
        public get messagessubpha3 () { return this.$t('messages.subpha3') }
        public get messagessubpha4 () { return this.$t('messages.subpha4') }
        public get messages_startexploring () { return this.$t('messages.startexploring') }

        beforeCreate() {
            document.body.className = 'home';
        }
        
        created () {
            store.loaderMessage = 'Loading 0%';

            AnalyticsUtils.visit(this.$route);
            this.isLoading = true;
        }

        mounted() {
            this.init();
            this.initTimeline();
            this.animate();

            this.isMounted = true;
            this.allowControl = false;
            //this.animateCameraIntro();
            this.allowControl = true;
            /* var nyedva = document.getElementById('secondp');
            if (nyedva)
                nyedva.scrollIntoView(); // .scrollTop = 2900; */

            /*v ar evt = document.createEvent('MouseEvents');
            evt.initEvent('wheel', true, true); 
            evt.deltaY = +120; */

            //var evt = this._event;
            //evt.deltaY = e.wheelDeltaY || e.deltaY * -1;
            // reduce by half the delta amount otherwise it scroll too fast
             //this._event.deltaY += +820;
            //this._event.y += 972;

            /* var cEvent = new Event('mousewheel');
            cEvent.detail = 0;
            cEvent.wheelDeltaY = someWheelDeltaY; */

            /* var e = document.createEvent('WheelEvent');
            e. = 900; */

            /*  e.initMouseEvent('mousewheel', true, true, window, 120, 0, 0, 0, 0, 0, 0, 0, 0, 
            0, null);
            e.wheelDeltaY = 120;
            e.deltaY = 120;
            document.documentElement.dispatchEvent(e); */

            /* let wheelEvent = new WheelEvent('wheel', {
                deltaY: 900,
                deltaMode: 1
            }); */
           /*  const el = document.getElementById('divContainer')
            if (el) */
                //document.dispatchEvent(wheelEvent);
            //his.divContainer.scrollTop = 400;

            //document.dispatchEvent('mousewheel');

            //console.log("maxHeight: " + this.maxHeight)
            //this.scrollY = 972
            //this.simulateScroll()
        }

        animateCameraIntro() {
            // zoom in on start //  -851, 401, 1500
            anime({
                targets: this.camera.position,
                //x: startCameraPosition.x + 50,
                //y: startCameraPosition.y + 50,
                z: this.startCameraPosition.z - 200,
                duration: 1400,
                delay: 400,
                easing: 'easeOutSine',
                complete: () => {
                    this.allowControl = true;
                }
            });
        }
        // Methods will be component methods
        init() {
            //console.log("INIT: isMobile? " + isMobile);
            this.deviceSmall = isMobile;

            this.minRange = this.maxRange / 2;

            this.locations = [
                // -izquierda.oeste +derecha.este, -abajo (altura) +arriba, +atras.sur -adelante.norte
                { x: -1350, y: 300, z: 1100, text: '-' }, // initial position / no transition   
                // spring  
                /* 1 */ { x: -750, y: 50, z: 255, text: '-' },  // end kumamoto castle starting torii
                /* 2 */ { x: -100, y: 20, z: 120, text: '' }, // end under torii now near nara
                /* 3 */ { x: 260, y: 80, z: -80, text: '宮島' }, // at the buda
                
                /* 4 */ { x: 300, y: 100, z: -550, text: '-' }, // osaka
                /* 5 */ { x: 250, y: 30, z: -800, text: '大阪' }, // fuji
                
                /* 6 */ { x: 450, y: 150, z: -1400, text: '富士山' }, // pagoda
                /* 7 */ { x: 400, y: 100, z: -700, text: '-' }, // tokyo tower
                /* 8 */ { x: 500, y: 40, z: -1100, text: '-' }, // hokkaido
                /* 9 */ //{ x: -850, y: 400, z: 1500, text: '-' },  // go back to start 
            ];

            if (this.deviceSmall) {
                if (isTablet) {
                    this.locations = [
                        { x: -1350, y: 200, z: 1100, text: '-' }, // initial position / no transition   
                        // spring  
                        /* 1 */ { x: -750, y: 50, z: 255, text: '-' },  // kumamoto castle
                        /* 2 */ { x: 100, y: 5, z: 150, text: '' }, // under torii
                        /* 3 */ { x: 200, y: 40, z: 100, text: '宮島' }, // miyaima
                    
                        /* 4 */ { x: -300, y: 150, z: 300, text: '-' }, // osaka
                        /* 5 */ { x: 2, y: 30, z: -1, text: '大阪' }, // fuji
                    
                        /* 6 */ { x: 100, y: 150, z: -500, text: '富士山' }, // pagoda
                        /* 7 */ { x: 400, y: 100, z: -700, text: '-' }, // tokyo tower
                        /* 8 */ { x: 500, y: 40, z: -1100, text: '-' }, // hokkaido
                        /* 9 */ //{ x: -850, y: 400, z: 1500, text: '-' },  // go back to start 
                    ];
                } else {
                    this.locations = [
                        { x: -1350, y: 200, z: 1200, text: '-' }, // initial position / no transition   
                        // spring  
                        /* 1 */ { x: -750, y: 50, z: 255, text: '-' },  // kumamoto castle
                        /* 2 */ { x: 100, y: 10, z: 150, text: '' }, // under torii
                        /* 3 */ { x: 200, y: 70, z: 100, text: '宮島' }, // miyaima
                    
                        /* 4 */ { x: -300, y: 150, z: 300, text: '-' }, // osaka
                        /* 5 */ { x: 2, y: 30, z: -1, text: '大阪' }, // fuji
                    
                        /* 6 */ { x: 100, y: 150, z: -500, text: '富士山' }, // pagoda
                        /* 7 */ { x: 400, y: 100, z: -700, text: '-' }, // tokyo tower
                        /* 8 */ { x: 500, y: 40, z: -1100, text: '-' }, // hokkaido
                        /* 9 */ //{ x: -850, y: 400, z: 1500, text: '-' },  // go back to start 
                    ];
                }
            }

            this.waveSprites = [
                { x: 10, z: -900 },
                { x: 50, z: -600 },
                { x: -50, z: -200 },
            ];

            this.maxHeight = (this.divContainer.clientHeight || this.divContainer.offsetHeight) - window.innerHeight
            this.windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );

            document.body.appendChild( this.container );

            this.renderer = new THREE.WebGLRenderer( { antialias: true } );
            this.renderer.setPixelRatio( window.devicePixelRatio );
            this.renderer.setSize( window.innerWidth, window.innerHeight );
            //renderer.toneMapping = THREE.ACESFilmicToneMapping;
            //renderer.toneMappingExposure = 1;
            this.renderer.outputEncoding = THREE.sRGBEncoding;
            this.container.appendChild( this.renderer.domElement );

            this.effect = new OutlineEffect( this.renderer );

            if (this.deviceSmall) {
                this.camera = new THREE.PerspectiveCamera( 80, window.innerWidth / window.innerHeight, 1, 2000 );
            } else {
                this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 2500 );
            }
            
            this.camera.position.set( -1350, 301, 1100 );
            this.camera.rotation.y = -0.7; // look to the right

            if (this.deviceSmall) {
                //this.camera.position.y = 200;
                this.camera.rotation.y = -0.9;
            }

            this.scene = new THREE.Scene();
            this.scene.background = new THREE.Color( color_default );

            //this.addSnow();

            /* const textureLoader = new THREE.TextureLoader();
            this.spriteWave1 = textureLoader.load( "images/wave1.png" );
            const materialSprite = new THREE.SpriteMaterial( { map: this.spriteWave1, color: 0xffffff, fog: true } );
            
            this.waveSprites.forEach(wave => {       
                const sprite = new THREE.Sprite( materialSprite );               
                sprite.position.x = wave.x;
                sprite.position.y = 30;
                sprite.position.z = wave.z;
                sprite.scale.set( 30, 30, 30 );
                this.scene.add( sprite );
            }); */

            // TODO: remove, this is taking to much time
            try {
                /* var color = new THREE.Color( 0x006699 );
                this.textConfig.material = new THREE.MeshBasicMaterial( {
                    color: color,
                    side: THREE.DoubleSide
                }); */

                /* var loaderFont = new THREE.FontLoader();
                loaderFont.load( 'fonts/' + textConfig.fontName + '_' + textConfig.fontWeight + '.typeface.json',  ( response ) => {                 
                    textConfig.font = response;
                    
                    locations.forEach(element => {            
                        //lastCube = addCube(element.x, element.y, element.z);              
                        let text = buildTextMesh(element.text);
                        text.position.x = element.x;
                        text.position.y = 20;
                        text.position.z = element.z;
                        text.rotation.x = THREE.MathUtils.radToDeg(-9);
                        text.lookAt(camera.position);
                        scene.add(text);
                    });

                }); */
            } catch(e) {
                console.log("Error " + e);
            }
       
            this.addLights();
            this.addSun();
            this.loadCompressedModel();
            this.addEventHandlers();
        }

        loadCompressedModel() {
            let colors = new Uint8Array( 6 );
            for ( let c = 0; c <= colors.length; c ++ ) {
                colors[ c ] = ( c / colors.length ) * 256;
            }

            const gradientMap = new THREE.DataTexture( colors, colors.length, 1, THREE.LuminanceFormat );
            gradientMap.minFilter = THREE.NearestFilter;
            gradientMap.magFilter = THREE.NearestFilter;
            gradientMap.generateMipmaps = false;

            const manager = new THREE.LoadingManager();
            /* manager.onStart =  ( url, itemsLoaded, itemsTotal ) => {
                console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
            }; */

            manager.onProgress = ( url, itemsLoaded, itemsTotal ) => {
                const progressPercentage = Math.floor((itemsLoaded / itemsTotal) * 100);
                //console.log( 'Loading file: ' + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files. --> progressPercentage ' + progressPercentage );
                store.loaderMessage = 'Loading ' + progressPercentage + '%...';
            };
            manager.onLoad = () => {
                //console.log( 'Loading complete!');
                store.isLoadingRoute = false;
                this.isLoading = false;
            };
            manager.onError = ( url ) => {
                console.log( 'There was an error loading ' + url );
            };

            const loader = new GLTFLoader(manager);
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath( '/decoder/' );
            dracoLoader.setDecoderConfig({ type: 'js' });
            loader.setDRACOLoader( dracoLoader );

            loader.load( 'models/gltf/modelDraco.gltf', ( gltf: any ) =>{
            //const loader = new GLTFLoader().setPath( 'models/gltf/' );
            //loader.load( 'japan_map_version22.glb', ( gltf: any ) =>{

                gltf.scene.traverse((child: any) => {
                    
                    if (Object.prototype.hasOwnProperty.call(child, 'userData')) 
                    //if (child.hasOwnProperty('userData'))
                    {
                        if (child.userData.type === 'tree') 
                        {
                            console.log("FOUND A TREE");
                            this.trees.push(child);
                        } 
                        else if (child.userData.type === 'camerapov') 
                        {
                            this.cameraPOV.push(child);
                            console.log(child);
                            console.log(child.position);
                            console.log("FOUND A CAMERA VIEW POINT");
                        } 
                        else if (child.userData.type === 'island') 
                        {                       
                            this.islands.push(child);
                        }
                    }
                });

                const diffuseColor = new THREE.Color().setHSL( 5, 0.5, 0.5 + 0.1 ).multiplyScalar( 1 * 0.2 );
                const material = new THREE.MeshToonMaterial( {
                    color: diffuseColor,
                    gradientMap: gradientMap
                });

                //gltf.scene.position.y = 8;
                gltf.scene.material = material;
                gltf.scene.scale.set(80,80,80)

                if (this.scene) {
                    this.scene.add( gltf.scene );
                }
            });

        }
        addEventHandlers() {
            window.addEventListener( 'resize', this.onWindowResize, false );
            this.divContainer.addEventListener('wheel', this.onWheel, { passive: false });
            document.addEventListener('mousemove', event => {                     
                this.mouse.x = (event.clientX / this.windowHalf.x) * 130;
                this.mouse.y = (event.clientY / this.windowHalf.x) * 130;
            });
            document.addEventListener('touchstart', this.onDocumentTouchStart, false );
            document.addEventListener('touchmove', this.onDocumentTouchMove, false );
        }
        addLights() {
            // LIGHTS
            const hemiLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.6 );
            hemiLight.color.setHSL( 0.6, 1, 0.6 );
            hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
            hemiLight.position.set( 0, 150, 0 );
            this.scene.add( hemiLight );

            //const hemiLightHelper = new THREE.HemisphereLightHelper( hemiLight, 10 );
            //scene.add( hemiLightHelper );

            const dirLight = new THREE.DirectionalLight( 0xffffff, 1 );
            dirLight.color.setHSL( 0.1, 1, 0.95 );
            dirLight.position.set( - 1, 1.75, 1 );
            dirLight.position.multiplyScalar( 560 );
            this.scene.add( dirLight );

            dirLight.castShadow = true;
            dirLight.shadow.mapSize.width = 2048;
            dirLight.shadow.mapSize.height = 2048;

            const d = 50;

            dirLight.shadow.camera.left = - d;
            dirLight.shadow.camera.right = d;
            dirLight.shadow.camera.top = d;
            dirLight.shadow.camera.bottom = - d;

            dirLight.shadow.camera.far = 3500;
            dirLight.shadow.bias = - 0.0001;

            //const dirLightHelper = new THREE.DirectionalLightHelper( dirLight, 10 );
            //scene.add( dirLightHelper );
        }

        addSun() {
            var materialShaders: any = [];
            // SUN
            var sunGeom = new THREE.CircleBufferGeometry(300, 90);
            var sunMat = new THREE.MeshBasicMaterial({color: color_red, fog: false, transparent: true});
            sunMat.onBeforeCompile = shader => {
                shader.uniforms.time = {value: 0};
                shader.vertexShader =
                    `
                    varying vec2 vUv;
                ` + shader.vertexShader;
                shader.vertexShader = shader.vertexShader.replace(
                    `#include <begin_vertex>`,
                    `#include <begin_vertex>
                    vUv = uv;
                    `
                );
                shader.fragmentShader = `
                    varying vec2 vUv;
                ` + shader.fragmentShader;
                shader.fragmentShader = shader.fragmentShader.replace(
                `gl_FragColor = vec4( outgoingLight, diffuseColor.a );`,
                    `gl_FragColor = vec4( outgoingLight, diffuseColor.a * smoothstep(0.5, 0.7, vUv.y));`
                );
                
                materialShaders.push(shader);
            }
            this.sun = new THREE.Mesh(sunGeom, sunMat);
            this.sun.position.set(0, 0, -1200);
            this.sun.rotation.y = THREE.MathUtils.radToDeg(-50);
            this.scene.add(this.sun);
        }
        addSnow() {               
            let pointGeometry = new THREE.BufferGeometry();
            this.vertices = [];
            for (let i = 0; i < this.particleNum; i++) {
                const x = Math.floor(Math.random() * this.maxRange - this.minRange);
                const y = Math.floor(Math.random() * this.maxRange - this.minRange);
                const z = Math.floor(Math.random() * this.maxRange - this.minRange);
                //const particle = new THREE.Vector3(x, y, z);
                //vertices.push(particle);
                this.vertices.push(x, y, z);
                // const color = new THREE.Color(0xffffff);
                // pointGeometry.colors.push(color);
            }
            pointGeometry.setAttribute( 'position', new THREE.Float32BufferAttribute( this.vertices, 3 ) ); // itemSize = 3 because there are 3 values (components) per vertex

            //console.log("Particles position x: " + JSON.stringify(pointGeometry.getAttribute("position")));
            pointGeometry.translate(600, 10, -700);
            // pointGeometry.computeBoundingBox();

            let pointMaterial = new THREE.PointsMaterial({
                size: 7,
                color: 0xffffff,
                vertexColors: false,
                map: this.getTexture(),
                // blending: THREE.AdditiveBlending,
                transparent: true,
                //opacity: 0.8,
                fog: true,
                depthWrite: false
            });

            this.velocities = [];
            for (let i = 0; i < this.particleNum; i++) {
                let x = Math.floor(Math.random() * 6 - 3) * 0.1;
                let y = Math.floor(Math.random() * 10 + 3) * - 0.05;
                let z = Math.floor(Math.random() * 6 - 3) * 0.1;
                let particle = new THREE.Vector3(x, y, z);
                this.velocities.push(particle);
            }

            this.particles = new THREE.Points(pointGeometry, pointMaterial);
            this.particles.geometry.velocities = this.velocities;

            //this.scene.add(this.particles); // add the particles when needed
        }
        drawRadialGradation = (ctx, canvasRadius, canvasW, canvasH) => {
            ctx.save();
            const gradient = ctx.createRadialGradient(canvasRadius,canvasRadius,0,canvasRadius,canvasRadius,canvasRadius);
            gradient.addColorStop(0, 'rgba(255,255,255,1.0)');
            gradient.addColorStop(0.5, 'rgba(255,255,255,0.5)');
            gradient.addColorStop(1, 'rgba(255,255,255,0)');
            ctx.fillStyle = gradient;
            ctx.fillRect(0,0,canvasW,canvasH);
            ctx.restore();
        }
        getTexture = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const diameter = this.textureSize;
            canvas.width = diameter;
            canvas.height = diameter;
            const canvasRadius = diameter / 2;

            /* gradation circle
            ------------------------ */
            this.drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);
            
            /* snow crystal
            ------------------------ */
            // drawSnowCrystal(ctx, canvasRadius);

            const texture = new THREE.Texture(canvas);
            //texture.minFilter = THREE.NearestFilter;
            texture.type = THREE.FloatType;
            texture.needsUpdate = true;
            return texture;
        }
        trackScrollCheckpoint (season) {
            //Analytics.scroll();

            console.log("Registering Analytics Event " + season);
            if (this.isAnalyticsEnabled) {
                /* ga.addEvent('homepage_scroll', {
                    seasonVisited: season,
                    //scroll: scrollValue
                }) */
            }
        }

        initTimeline() {
            this.timeline = anime.timeline({
                autoplay: false,
                duration: 3000,
                easing: 'easeInOutQuad' // 'easeOutSine'
            });

            /* let timeline2 = anime.timeline({
                autoplay: false,
                duration: 4500,
                easing: 'easeOutSine',
                loop: true,
            }); */

            this.timelineRotation = anime.timeline({
                autoplay: false,
                duration: 2000,
                easing: 'easeOutSine',
                delay: 500
                //loop: true,
            });

            //let koinoboriAnim;
            /* timeline.add({
                targets: camera.position,
                x: locations[0].x,
                y: locations[0].y,
                z: locations[0].z,
                duration: 200,
                
                changeBegin: function(anim) {
                    console.log("Spotlight 1 - Spring visited! RESTARTED");
                
                },
                complete: function() {
                    console.log("Spotlight 1 - Spring visited!");
                },
                changeComplete: function() {
                    console.log("Spotlight 1 - Spring visited AGAIN!");

    
                },
            }) */
        
            //let koinoboriAnim;
            /*timeline.add({
                targets: '.cls-1',
                easing: 'easeInOutQuad',
                //duration: 3500,
                loop: true,
                d: [
                    //{ value: [wave1, wave2] },
                // { value: wave3 },
                //{ value: wave1 },
                { value: tail_up },
                //{ value: tail_center },
                { value: tail_down },
                //{ value: wave2 },
                ],
                //delay: (el, i) => 150 * (i+1),
                opacity: [0,1],
                begin: function(anim) {

                    anime({
                    targets: '.wave-top > path',
                    easing: 'linear',
                    duration: 7500,
                    loop: true,
                    d: [
                        { value: tail_up },
                        { value: tail_down },
                    ],
                    opacity: [0,1],
                    });

                },
                complete: function(anim) {
                    
                    anime({
                        targets: '.wave-top > path',
                        easing: 'linear',
                        duration: 1500,
                        opacity: 0,
                    });

                }
            }, 0);*/


            //let rot = 0.05 * ( 2 - camera.rotation.y );
            let rotationY = { y: -0.5 }; // this should be the same as the start rotation of the camera defined above
            if (this.timelineRotation != undefined) {
                this.timelineRotation.add({
                    targets: rotationY,
                    y: [-1, -0.9, -0.8, -0.1, -0.1, -0.2, -0.6, -0.7], //-0.8, -1, -1.5, -1.8, 
                    duration: 1200,
                    easing: 'easeInOutQuad',
                    update: () => {     
                        this.camera.rotation.y = rotationY.y;
                        //console.log("ROTATION - " + rotationY.y);
                    },
                    /* complete: function() {
                        console.log("ROTATION FINISHED - " + rotationY.y);
                    } */
                }, '-=450'); // start rotating earlier
            }

            // STOPS TRANSITION
            this.timeline
            .add({
                targets: this.camera.position,
                x: this.locations[1].x,
                y: this.locations[1].y,
                z: this.locations[1].z,
                duration: 200,
                complete: ()=> {
                    //console.log("Spotlight 1 - spring visited! locations[1]");
                    this.activeSection = 0;
                    this.trackScrollCheckpoint('spring[1]');
                }
            })
            .add({
                targets: this.camera.position,
                x: this.locations[2].x,
                y: this.locations[2].y,
                z: this.locations[2].z,
                duration: 200,
                complete: ()=> {
                    this.scene.add(this.particles);
                    this.isSnowActive = true;
                    //console.log("Spotlight 1 - spring visited! locations[2]");
                    this.activeSection = 1;
                    this.trackScrollCheckpoint('spring[2]');
                }
            })
            .add({
                targets: this.camera.position,
                x: this.locations[3].x,
                y: this.locations[3].y,
                z: this.locations[3].z,
                duration: 200,
                complete: ()=> {
                    //console.log("Spotlight 2 - Summer visited! locations[3]");
                    this.activeSection = 1;
                    this.trackScrollCheckpoint('summer[3]');
                }
            })
            .add({
                targets: this.camera.position,
                x: this.locations[4].x,
                y: this.locations[4].y,
                z: this.locations[4].z,
                duration: 200,
                complete: ()=> {
                    //console.log("Spotlight 1 - Winter visited! locations[4]");
                    this.activeSection = 2;
                    this.trackScrollCheckpoint('summer[4]');
                }
            })
            .add({
                targets: this.camera.position,
                x: this.locations[5].x,
                y: this.locations[5].y,
                z: this.locations[5].z,
                duration: 200,
                complete: ()=> {
                    //console.log("Spotlight 2 - Winter visited! locations[5]");
                    this.activeSection = 2;
                    this.trackScrollCheckpoint('winter[5]');
                }
            })
            .add({
                targets: this.camera.position,
                x: this.locations[6].x,
                y: this.locations[6].y,
                z: this.locations[6].z,
                duration: 200,
                complete: ()=> {
                    //console.log("Spotlight 3 - Winter visited! locations[6]");
                    this.activeSection = 2;
                    this.trackScrollCheckpoint('winter[6]');
                }
            })
            .add({
                targets: this.camera.position,
                x: this.locations[7].x,
                y: this.locations[7].y,
                z: this.locations[7].z,
                duration: 200,
                complete: ()=> {
                    //console.log("Spotlight 4 - Winter visited! locations[7]");
                    this.trackScrollCheckpoint('winter[7]');
                    // get ready if user goes to next page / we are using a modal now to signup
                }
            })
            .add({
                targets: this.camera.position,
                x: this.locations[8].x,
                y: this.locations[8].y,
                z: this.locations[8].z,
                duration: 200,
                complete: ()=> {
                    //console.log("Starting again checkpoint locations[8]");
                    this.trackScrollCheckpoint('start[8]');
                }
            });


            const environmentTransitionDuration = 800;

            // BACKGROUND SCENE TRANSITION
            // var value = new THREE.Color(0xFFE3EB);
            var springColor = new THREE.Color(color_dark_pink); // pink
            var summerColor = new THREE.Color(color_green); // green
            var autumnColor = new THREE.Color(color_yellow); // orange, red
            var winterColor = new THREE.Color(color_dark_pink); // gray, white
            var currentSeasonColor = springColor;

            this.timeline.add({
                targets: currentSeasonColor,
                r: [springColor.r, summerColor.r, autumnColor.r, winterColor.r],
                g: [springColor.g, summerColor.g, autumnColor.g, winterColor.g],
                b: [springColor.b, summerColor.b, autumnColor.b, winterColor.b],
                duration: environmentTransitionDuration,
                update: () => {
                    //renderer.setClearColor(currentSeasonColor);
                    this.scene.background = currentSeasonColor;
                }
            }, 0);

            // TREES COLOR TRANSITION - EVEN
            var springThreeColor1 = new THREE.Color(0xFF608B); // pink
            var summerThreeColor = new THREE.Color(0x90EDA4); // green
            var autumnThreeColor = new THREE.Color(0xF47941); // orange, red
            var winterThreeColor = new THREE.Color(0xFFFFFF); // gray, white
            var currentSeasonThreeColor = springThreeColor1;

            this.timeline.add({
                targets: currentSeasonThreeColor,
                r: [springThreeColor1.r, summerThreeColor.r, autumnThreeColor.r, winterThreeColor.r],
                g: [springThreeColor1.g, summerThreeColor.g, autumnThreeColor.g, winterThreeColor.g],
                b: [springThreeColor1.b, summerThreeColor.b, autumnThreeColor.b, winterThreeColor.b],
                duration: environmentTransitionDuration,
                update: () => {
                    
                    this.trees.forEach((element) => {              
                        element.traverse((child) => {
                            //console.log("TREE " + child.name + " // material: " + JSON.stringify(child.material)); // al: {"metadata":{"version":4.5,"type":"Material","generator":"Material.toJSON"},"uuid":"8420FF70-DC18-434A-A9C9-1AAF289653F1","type":"MeshStandardMaterial","name":"mat_leafes","color":16719169,"roughness":0.5,"metalness":0,"emissive":0,"side":2,"depthFunc":3,"depthTest":true,"depthWrite":true,"colorWrite":true,"stencilWrite":false,"stencilWriteMask":255,"stencilFunc":519,"stencilRef":0,"stencilFuncMask":255,"stencilFail":7680,"stencilZFail":7680,"stencilZPass":7680}
                            //if(child.name.indexOf('leaves0') !== -1) {
                            if(child.material && child.material.name == 'mat_leafes') {

                                child.material.color.setRGB(currentSeasonThreeColor.r, currentSeasonThreeColor.g, currentSeasonThreeColor.b); 
                                child.material.needsUpdate = true;
                            }
                        });
                    });

                }
            }, 0);

            // FLOOR TRANSITION
            var springFloorColor = new THREE.Color(color_pink2); // pink
            var summerFloorColor = new THREE.Color(color_green); // green
            var autumnFloorColor = new THREE.Color(color_yellow); // orange, red
            var winterFloorColor = new THREE.Color(color_dark_pink); // gray, white
            var currentSeasonFloorColor = springFloorColor;

            this.timeline.add({
                targets: currentSeasonFloorColor,
                r: [springFloorColor.r, summerFloorColor.r, autumnFloorColor.r, winterFloorColor.r],
                g: [springFloorColor.g, summerFloorColor.g, autumnFloorColor.g, winterFloorColor.g],
                b: [springFloorColor.b, summerFloorColor.b, autumnFloorColor.b, winterFloorColor.b],
                duration: environmentTransitionDuration,
                update: () => {              
                    this.islands.forEach(island => {
                        if(island && island.material) {
                            island.material.color.setRGB(currentSeasonFloorColor.r, currentSeasonFloorColor.g, currentSeasonFloorColor.b); 
                            island.material.needsUpdate = true;
                        }
                    });
                }
            }, 0);

            // SUN INTENSITY
            var springSunColor = new THREE.Color(color_red);
            var summerSunColor = new THREE.Color(0xffff78);
            var autumnSunColor = new THREE.Color(0xF07C47);
            var winterSunColor = new THREE.Color(0xF2F3CC);
            var currentSeasonSunColor = springSunColor;

            this.timeline.add({
                targets: currentSeasonSunColor,
                r: [springSunColor.r, summerSunColor.r, autumnSunColor.r, winterSunColor.r],
                g: [springSunColor.g, summerSunColor.g, autumnSunColor.g, winterSunColor.g],
                b: [springSunColor.b, summerSunColor.b, autumnSunColor.b, winterSunColor.b],
                duration: environmentTransitionDuration,
                update: () => {                       
                    this.sun.material.color.setRGB(currentSeasonSunColor.r, currentSeasonSunColor.g, currentSeasonSunColor.b); 
                    this.sun.material.needsUpdate = true;                       
                }
            }, 0);

            this.timeline.add({
                targets: ".first-pharagraph",
                opacity: [0, 1, 0],
                translateY: -25,
                duration: 300
            }, -50)
            .add({
                targets: ".second-pharagraph",
                opacity: [0, 1, 0],
                translateX: -115,
                duration: 300
            }, 200)
            .add({
                targets: ".third-pharagraph",
                opacity: [0, 1, 0],
                translateY: 115,
                duration: 300
            }, 500)
            .add({
                targets: ".fourth-pharagraph",
                opacity: [0, 1, 0],
                translateY: 115,
                duration: 300
            }, 900);
        }
        lerp(a, b, t) {
            return ((1 - t) * a + t * b);
        }
        onWindowResize() {
            const width = window.innerWidth;
            const height = window.innerHeight;
        
            this.windowHalf.set( width / 2, height / 2 );

            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize( window.innerWidth, window.innerHeight );
        }
        onDocumentTouchStart( event ) {
            /*if ( event.touches.length == 1 ) {
                event.preventDefault();

                mouse.x = event.touches[ 0 ].pageX - windowHalf.x;
                mouse.y = event.touches[ 0 ].pageY - windowHalf.y;
            }*/

            var t = (event.targetTouches) ? event.targetTouches[0] : event;
            this.touchStartY = t.pageY;
        }
        onDocumentTouchMove( event ) {
            /*if ( event.touches.length == 1 ) {
                event.preventDefault();

                mouse.x = event.touches[ 0 ].pageX - windowHalf.x;
                mouse.y = event.touches[ 0 ].pageY - windowHalf.y;
            }*/

            var evt = this._event;
            var t = (event.targetTouches) ? event.targetTouches[0] : event;
            // the multiply factor on mobile must be about 10x the factor applied on the wheel
            evt.deltaY = (t.pageY - this.touchStartY) * 5;
            this.touchStartY = t.pageY;
            this.simulateScroll()
        }
        onWheel (e) {
            // for embedded demo
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();

            var evt = this._event;
            evt.deltaY = e.wheelDeltaY || e.deltaY * -1; // returns a positive value when scrolling down, and a negative value when scrolling up, otherwise 0.
            // reduce by half the delta amount otherwise it scroll too fast
            evt.deltaY *= 0.6;

            this.simulateScroll();
        }
        simulateScroll () {
            var evt = this._event;
            // limit scroll top
            if ((evt.y + evt.deltaY) > 0 ) {
                evt.y = 0;
            // limit scroll bottom
            } else if ((-(evt.y + evt.deltaY)) >= this.maxHeight) {
                evt.y = -this.maxHeight;
            } else {
                evt.y += evt.deltaY;
            }
            this.scrollY = -evt.y
            //console.log(this.scrollY)
        }
        goToSection(/* sectionId */) {
            //console.log("Jumping to section: " + sectionId)
            /* this.timeline.seek(1500);
            this.timelineRotation.seek(1500); */

            this.percentage = 30
        }
        renderLoop() {
            if (!this.scene) return;
            //const delta = clock.getDelta();
            //var dtime	= Date.now() - startTime;
            const time = Date.now() * 0.00005;
            // easing with treshold on 0.08 (should be between .14 & .2 for smooth animations)
            
            if (this.deviceSmall) {
                this.percentage = this.lerp(this.percentage, this.scrollY, .1);
            } else {
                this.percentage = this.lerp(this.percentage, this.scrollY, .09);
            }
        
            if (this.allowControl) {
                if(this.timeline){
                    this.timeline.seek(this.percentage * (4500 / this.maxHeight))
                }
                if(this.timelineRotation){
                    this.timelineRotation.seek(this.percentage * (4500 / this.maxHeight))
                }

                // camera look
                this.target.x = ( 1 - this.mouse.x ) * 0.002; // 0.002;
                this.target.y = ( 1 - this.mouse.y ) * 0.002;

                this.camera.rotation.x += 0.05 * ( this.target.y - this.camera.rotation.x );
                this.camera.rotation.y += 0.05 * ( this.target.x - this.camera.rotation.y );
            }

            this.effect.render( this.scene, this.camera );

            // snow
            //const posArr = vertices;
            const velArr = this.velocities;

            // activate snow when getting close to Fuji
            if(this.isSnowActive) {
                for ( let i = 0; i < this.scene.children.length; i ++ ) {

                    const object = this.scene.children[ i ];

                    if ( object instanceof THREE.Points ) {
                        //object.rotation.z = time * ( i < 4 ? i + 1 : - ( i + 1 ) )
                        const velocity = velArr[i];
                        const velX = Math.sin(time * 0.001 * velocity.x) * 0.2;
                        const velZ = Math.cos(time * 0.0015 * velocity.z) * 0.2;
                        
                        object.position.x += velX;
                        object.position.y += velocity.y;
                        object.position.z += velZ;

                        if (object.position.y < -this.minRange ) {
                            object.position.y = this.minRange;
                        }
                    }
                }
            }

            //particles.geometry.verticesNeedUpdate = true;
            //currentCameraPosition = cameraControls.getPosition();
            this.currentCameraPosition = this.camera.position;
            this.currentCameraRotation = this.camera.rotation;
            //console.log(cameraControls.getPosition());
            // renderer.render( scene, camera );
        }

        animate() {
            // render the 3D scene
            this.renderLoop();
            // relaunch the 'timer' 
            this.animationFrameId = requestAnimationFrame( this.animate );
            //stats.update();
        }

        empty(elem) {
            //console.log(elem);
            while (elem.lastChild) {
                //console.log(elem.lastChild);
                elem.removeChild(elem.lastChild);
            }
        }
        goToDiscover() {
            this.$router.push({ path: `/lyrics` });
        }
        // destroyed () {
        beforeUnmount () { // TODO: change this for Destroy??
            document.body.className = '';

            window.removeEventListener('wheel', this.onWheel);
            window.removeEventListener( 'resize', this.onWindowResize );
            this.divContainer.removeEventListener('wheel', this.onWheel);
            /* document.addEventListener('mousemove'); */
            document.removeEventListener('touchstart', this.onDocumentTouchStart );
            document.removeEventListener('touchmove', this.onDocumentTouchMove );

            try {        
                this.timeline.pause();
                this.timeline = null;
                cancelAnimationFrame(this.animationFrameId);// Stop the animation
                this.renderer.domElement.addEventListener('dblclick', null, false); //remove listener to render
                this.scene = null;
                this.camera = null;

                this.empty(this.container);

                this.sun.material.dispose();
                //console.log("Scene Destroyed!!");
            } catch(e){
                console.log("ERROR OnDestroy " + e);
            }
        }
    }
