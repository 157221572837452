
import { Vue, Options } from 'vue-class-component';
import Footer from "@/lib/SiteLayout/Footer/footer.vue";
import SubscriptionPlans from "@/lib/SubscriptionPlans/index.vue";
//import service from "@/services/api";

@Options({
	components: {
		Footer,
		SubscriptionPlans
	},
})
export default class Features extends Vue {

	// translations
	public get titles_features() { return this.$t('titles.features') }
	public get titles_feature_1() { return this.$t('titles.features_1') }
	public get titles_feature_2() { return this.$t('titles.features_2') }
	public get titles_feature_3() { return this.$t('titles.features_3') }
	public get titles_feature_4() { return this.$t('titles.features_4') }
	public get titles_feature_5() { return this.$t('titles.features_5') }

	tabSelected

	selectFeature(i) {
		this.tabSelected = i
	}
}

