<template>
    <div class="logo">
        <a href="#" class="simple-text logo-mini">
        <div class="logo-image-small">
            <img src="/nitomelogo.png" class="object-center w-32">
        </div>
        </a>
    </div>
</template>

<style>
    .logo {
        /* padding: .6em; */
        z-index: 2;
        padding-left: 1em;
       /*  width: 50px;
        border-radius: 50%;
        flex-shrink: 0;
        padding: 0.3em;
        padding-bottom: 2px; */
    }
</style>