
    import { Vue, Options } from 'vue-class-component';
    import Footer from "@/lib/SiteLayout/Footer/footer.vue";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';

    @Options({
        components: {
            Footer, Disclaimer
        },
    }) 
    export default class DashboardIndex extends Vue {
        
        // translations
        public get titles_progress () { return this.$t('titles.progress') }
        public get titles_history () { return this.$t('titles.history') }
        public get titles_my_lyrics () { return this.$t('titles.my_lyrics') }
        public get titles_favorites () { return this.$t('titles.favorites') }
       
    }
