
    import { Vue, Options } from 'vue-class-component';
    import PercentageLetter from '@/lib/PercentageLetter/index.vue';
    import store from "@/stores";
    import Analytics from "@/services/analytics";
    
    @Options({
        components: {
            PercentageLetter
        },
    }) 
    export default class DashboardProgress extends Vue {
        
        // translations
        /* public get messages_disclaimer () { return this.$t('messages.disclaimer') }
        public get about () { return this.$t('page.home.about') }*/

        // if the api (like in this example) just have a simple numeric pagination
        page = 0;
        // but most likely, you'll have to store a token to fetch the next page
        nextUrl = '';
        // store all the data here.
        //data = [];
        // store the new batch of data here.
        newBatch = [];
        
        hiragana = {
            title: "hiragana",
            isActive: false,
            percentage: 20,
            items:[
                {id: 1, kana: 'あ', percentage: 5},
                {id: 2, kana: 'い', percentage: 10},
                {id: 3, kana: 'う', percentage: 15},
                {id: 4, kana: 'え', percentage: 20},
                {id: 5, kana: 'お', percentage: 25},
                {id: 6, kana: 'か', percentage: 30},
                {id: 7, kana: 'き', percentage: 35},
                {id: 5, kana: 'く', percentage: 40},
                {id: 6, kana: 'け', percentage: 45},
                {id: 7, kana: 'こ', percentage: 50},
                {id: 1, kana: 'さ', percentage: 55},
                {id: 2, kana: 'し', percentage: 60},
                {id: 3, kana: 'す', percentage: 65},
                {id: 4, kana: 'せ', percentage: 70},
                {id: 5, kana: 'そ', percentage: 75},
                {id: 6, kana: 'た', percentage: 80},
                {id: 7, kana: 'ち', percentage: 85},
                {id: 5, kana: 'つ', percentage: 90},
                {id: 6, kana: 'て', percentage: 95},
                {id: 7, kana: 'と', percentage: 100},
                {id: 6, kana: 'く', percentage: 15},
                {id: 7, kana: 'く', percentage: 25},
                {id: 5, kana: 'く', percentage: 50},
                {id: 6, kana: 'く', percentage: 75},
                {id: 6, kana: 'く', percentage: 95},
            ]
        };
        
        katakana = {
            title: "katakana",
            isActive: false,
            percentage: 10,
            items:[
                {id: 1, kana: 'ア', percentage: 95},
                {id: 2, kana: 'イ', percentage: 10},
                {id: 3, kana: 'ウ', percentage: 80},
                {id: 4, kana: 'エ', percentage: 0},
                {id: 5, kana: 'オ', percentage: 30},
                {id: 6, kana: 'カ', percentage: 40},
                {id: 7, kana: 'キ', percentage: 100},
                {id: 5, kana: 'ク', percentage: 30},
                {id: 6, kana: 'ケ', percentage: 40},
                {id: 7, kana: 'コ', percentage: 100},
                {id: 1, kana: '也', percentage: 95},
                {id: 2, kana: '丈', percentage: 10},
                {id: 3, kana: '九', percentage: 80},
                {id: 4, kana: '五', percentage: 0},
                {id: 5, kana: '亭', percentage: 30},
                {id: 6, kana: '亰', percentage: 40},
                {id: 7, kana: '代', percentage: 100},
                {id: 5, kana: '亭', percentage: 30},
                {id: 6, kana: '亰', percentage: 40},
                {id: 7, kana: '代', percentage: 100}
            ]
        };

        dataN5 = [
        {
            title: "N5", 
            isActive: true, 
            percentage: 50,
            items: [
                {id: 1, kanji: '也', level: '5', percentage: 75},
                {id: 2, kanji: '丈', level: '5', percentage: 10},
                {id: 3, kanji: '九', level: '5', percentage: 80},
                {id: 4, kanji: '五', level: '5', percentage: 0},
                {id: 5, kanji: '亭', level: '1', percentage: 30},
                {id: 6, kanji: '亰', level: '2', percentage: 40},
                {id: 7, kanji: '代', level: '3', percentage: 100},
                {id: 5, kanji: '亭', level: '1', percentage: 30},
                {id: 6, kanji: '亰', level: '2', percentage: 40},
                {id: 7, kanji: '代', level: '3', percentage: 100},
                {id: 1, kanji: '也', level: '5', percentage: 95},
                {id: 2, kanji: '丈', level: '5', percentage: 10},
                {id: 3, kanji: '九', level: '5', percentage: 80},
                {id: 4, kanji: '五', level: '5', percentage: 2},
                {id: 5, kanji: '亭', level: '1', percentage: 30},
                {id: 6, kanji: '亰', level: '2', percentage: 40},
                {id: 7, kanji: '代', level: '3', percentage: 100},
                {id: 5, kanji: '亭', level: '1', percentage: 30},
                {id: 6, kanji: '亰', level: '2', percentage: 40},
                {id: 7, kanji: '代', level: '3', percentage: 100}
            ]
        }, 
        {
            title: "N4", 
            isActive: false,
            percentage: 10,
            items:[
                {id: 1, kanji: '急', level: '5', percentage: 95},
                {id: 2, kanji: '世', level: '5', percentage: 10},
                {id: 3, kanji: '理', level: '5', percentage: 80},
                {id: 4, kanji: '茶', level: '5', percentage: 0},
                {id: 5, kanji: '少', level: '1', percentage: 30},
                {id: 6, kanji: '方', level: '2', percentage: 40},
                {id: 7, kanji: '早', level: '3', percentage: 100}
            ]
        }, 
        {
            title: "N3", 
            isActive: false,
            percentage: 5,
            items:[
                {id: 1, kanji: '也', level: '5', percentage: 95},
                {id: 2, kanji: '丈', level: '5', percentage: 10},
                {id: 3, kanji: '九', level: '5', percentage: 80},
                {id: 4, kanji: '五', level: '5', percentage: 0},
                {id: 5, kanji: '亭', level: '1', percentage: 30},
                {id: 6, kanji: '亰', level: '2', percentage: 40},
                {id: 7, kanji: '代', level: '3', percentage: 100}
            ]
        }, 
        {
            title: "N2", 
            isActive: false,
            percentage: 15,
            items:[
                {id: 1, kanji: '也', level: '5', percentage: 95},
                {id: 2, kanji: '丈', level: '5', percentage: 10},
                {id: 3, kanji: '九', level: '5', percentage: 80},
                {id: 4, kanji: '五', level: '5', percentage: 0},
                {id: 5, kanji: '亭', level: '1', percentage: 30},
                {id: 6, kanji: '亰', level: '2', percentage: 40},
                {id: 7, kanji: '代', level: '3', percentage: 100}
            ]
        },
        {
            title: "N1", 
            isActive: false,
            percentage: 0,
            items:[
                {id: 1, kanji: '也', level: '5', percentage: 95},
                {id: 2, kanji: '丈', level: '5', percentage: 10},
                {id: 3, kanji: '九', level: '5', percentage: 80},
                {id: 4, kanji: '五', level: '5', percentage: 0},
                {id: 5, kanji: '亭', level: '1', percentage: 30},
                {id: 6, kanji: '亰', level: '2', percentage: 40},
                {id: 7, kanji: '代', level: '3', percentage: 100}
            ]
        }
        ];

        ig;
        loading;
        itemCount = 10;

        async fetchData() {
            /* console.log("FETCHING DATA");
            const response = await fetch(`https://api.openbrewerydb.org/breweries?by_city=los_angeles&page=${this.page}`);
            this.newBatch = await response.json();
            console.log(this.newBatch) */
        }
        
        async created() {
            Analytics.visit(this.$route);
            // load first batch onMount
            await this.fetchData();
        }
        mounted(){
            setTimeout(() => {
                store.isLoadingRoute = false;
                store.isLoading = false;
            }, 1000);
        }

        
        //let items = getItems(0, 0, itemCount);
        getItems(nextGroupKey, nextKey, count) {
            const nextItems: any = [];
        
            for (let i = 0; i < count; ++i) {
                nextItems.push({ groupKey: nextGroupKey, key: nextKey + i });
            }
            return nextItems;
        }
        
        items = [{
            id: '123123',
            key: 1,
            name: 'Test'
        }];
        //let currentActiveTab = 0;
        setActive(i){
            this.dataN5[i].isActive = !this.dataN5[i].isActive;
            //currentActiveTab = currentActiveTab == i ? -1 : i;
        }
    }
