
    import { Vue, Options } from 'vue-class-component';
    import * as wanakana from 'wanakana';
    import { romajiAlphabet } from '../definitions';
    import gamestore from "@/stores/gameUtils";
    import Helpers from '@/services/helpers';
    import EventBus from '@/services/pubsub'
    import Analytics from '@/services/analytics';

    @Options({
        emits: ['completed']
    })
    export default class FourOptionsGrid extends Vue {
        
        nodes: any = [];
        coords = { left: -1000, bottom: -1000 }
        showPopup: boolean = false;      
        romajiNodes: any[] = [];
        currentCharacterIdPopup = -1
        meaning: string = ''

        mounted() {
            EventBus.addEventListener('waitinganswer', (/* e: any */) => {
                Helpers.lgi("WAITINGANSWER EVENT");
                this.showPopup = false;
                this.goToNextQuestion();
            });

            EventBus.addEventListener('rightAnswer', (/* e: any */) => {
                this.showPopup = false; // lets find out if theres more to complete on this line before showing up again the popup
                this.goToNextQuestion();
            });

            EventBus.addEventListener('skippedAnswer', (/* e: any */) => {
                this.showPopup = false;
                this.goToNextQuestion();
            });
            //this.goToNextQuestion();
        }

        selectOption(word) {
            Analytics.sendEvent("answering", {});

            this.$emit('completed', word);
        }

        // get coordinates on the queue for next toComplete here
        goToNextQuestion() {
            // only generate for kanas (kanji options come already from api)
            if (gamestore.isCurrentLineIncomplete()) {
                
                const toComplete = gamestore.getNextToComplete();
                if (toComplete.i == this.currentCharacterIdPopup) return;

                this.currentCharacterIdPopup = toComplete.i;
                this.meaning = toComplete.m;

                if (gamestore.currentLevel == 'veryeasy') 
                {
                    this.generateOptionsForAnswer(toComplete);
                }
                else
                {                           
                    Helpers.lgi("NEXT QUESTION: " + JSON.stringify(toComplete));
                    this.nodes = toComplete.o.slice(0, 3);
                    if (toComplete.t == 1) {
                        this.nodes.push(toComplete.f);
                    } else {
                        this.nodes.push(toComplete.c);
                    }
                    this.shuffle(this.nodes)
                    this.coords = toComplete.coordinates;
                    this.showPopup = true;
                }
            }
            /* if (toComplete && (wanakana.IsKana(toComplete.kanji) || wanakana.IsKana(toComplete.kana))) {            
            } else {
                this.nodes = [toComplete.kanji, ...toComplete.options.slice(0,3)]
            } */
        }

        generateOptionsForAnswer(elemt: any) {
            Helpers.lgi("GENERATING OPTIONS for: " + JSON.stringify(elemt));
            /* this.nodes = [
                "だ",
                "へ",
                "ん",
                "き"
            ]; */
        //}
        // get random kanas to complete the 3 options
        //getNextNodeOptions(elemt) {
            if (elemt.kana && elemt.kana != '') {
                const totalUniqueKana = elemt.kana.length;
                const kanaArr = elemt.kana.split("");
                
                this.nodes = kanaArr;

                console.log("---------- TOTAL KANA " + totalUniqueKana + " GETTING THE OTHER " + (4 - totalUniqueKana) + " // " + JSON.stringify(kanaArr));

                // run until we fill the array with all the 7 options, get 1 random similar kana per each individual response kana
                let currentKanaIndex = 0;
                while(this.nodes.length < 4) {
                    
                    console.log("CURRENT NODE LENGTH: " + this.nodes.length);

                    const kana = kanaArr[currentKanaIndex];

                    //kanaArr.forEach(kana => {
                        
                        console.log("KANA: " + kana);

                        const romaji = wanakana.toRomaji(kana);
                        let match;
                        // vowels, n or minis っ
                        if (romaji.length == 1) {
                            match = romajiAlphabet.find(x=> !this.romajiNodes.includes(x) && x.indexOf(romaji) !== -1);

                        } else {
                            // any other like ha, ri, mo etc
                            let vowel = romaji.match(/[aeiou]/ig).join("")[0];
                            console.log("---------- SEARCHING WORDS VOWEL " + vowel + " ----------");
                            match = romajiAlphabet.find(x=> !this.romajiNodes.includes(x) && x.indexOf(vowel) !== -1);

                        }

                        // convert to hiragana
                        if (match) {
                            console.log(" ---------- FOUND SIMILAR WORDS ----------");
                            console.log(match);

                            const hiragana = wanakana.toHiragana(match);
                            this.romajiNodes.push(match);
                            this.nodes.push(hiragana);
                        }

                        if (this.nodes.length == 4) {
                            break;
                        } else {
                            currentKanaIndex++;
                        }

                    //});

                    console.log("CURRENT NODES: " + this.nodes);
                }

            } /* else if (elemt.kanji && elemt.kanji != '') { // kanji
                const romaji = wanakana.toRomaji(elemt.kanji);
                // RIGHT KANJI ANSWER COMES ALREADY WITH THE OTHER 3 WRONG OPTIONS FOUND FROM DATABASE (using radical, similar onyomi and/or kunyomi reading and more advanced matching)

            } */
        }
        // TODO: finalize code to generate 4 options to select instead of writing manually or using the smart kana keyboard
        shuffle(array) {
            Helpers.lgi("SHUFFLING ARRAY " + array);
            let currentIndex = array.length,  randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex != 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }

            return array;
        }
    }
