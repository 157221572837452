
    import { ref } from 'vue'
    import { Vue, Options } from 'vue-class-component';
    import service from "@/services/api";
    import AudioLyricPlayer from '@/lib/LyricsPlayer/audioPlayer.vue';
    
    interface UserRegistration {
        name: string
        email: string
        password: string
        password_confirmation: string
        username: string
        ip: string
        captcha: string
    }

    @Options({
        props: {
            showModal: Boolean
        },
        components: {
            AudioLyricPlayer
        },
    }) 
    export default class SignupModal extends Vue {
         
        registerdata: UserRegistration = {
            name: '', email: '', ip: '', password: '', password_confirmation: '', username: '', captcha: ''
        };
        //let currentUserInput = '';
        usernameExists = false;
        currentStep = 0;
        isSigningUp = false;
        timer;
        line1; line2; line3; line4; line5; line6; line7;

        line0 = ref<InstanceType<typeof AudioLyricPlayer>>()

        // translations
        public get actions_goback () { return this.$t('actions.goback') }
        public get actions_continue () { return this.$t('actions.continue') }
        public get titles_create_account () { return this.$t('titles.create_account') }

        lyricsdata = {
            "audioId": "/audios/signup_welcome_nitome_takumi.mp3",
            "verses": [
                {
                    "id": 0,
                    "text": "Nitomeにようこそ", // he?
                    "romaji": "nitome ni youkoso",
                    "en": "Welcome to Nitome",
                    "es": "Bienvenido a Nitome",
                    "showRomaji": true
                }
            ]
        };

        lyricsdata2 = {
            "audioId": "/audios/signup_name_takumi.mp3",
            "verses": [           
                {
                    "id": 1,
                    "text": "あなたの名前は何ですか？",
                    "romaji": "anata no namae ha nandesuka?",
                    "en": "What is your name?",
                    "es": "Cual es su nombre?",
                    "showRomaji": true,
                    "spec": [
                        { "pos": 4, "kanji": "名", "furigana": "na" },
                        { "pos": 5, "kanji": "前", "furigana": "mae" },
                        { "pos": 7, "kanji": "何", "furigana": "nan" },
                    ]
                },
            ]
        };

        lyricsdata3 = {
            "audioId": "/audios/signup_email_takumi.mp3",
            "verses": [           
                {
                    "id": 2,
                    "text": "メールアドレス",
                    "romaji": "meeru aduroresu",
                    "en": "What is your email?",
                    "es": "Cual es su correo electronico?",
                    "showRomaji": true
                },
            ]
        };

        lyricsdata4 = {
            "audioId": "/audios/signup_username_takumi.mp3",
            "verses": [           
                {
                    "id": 3,
                    "text": "username",
                    "romaji": "Username",
                    "en": "Choose a username",
                    "es": "Ingrese su nombre de usuario",
                    "showRomaji": true
                },
            ]
        };

        lyricsdata5 = {
            "audioId": "/audios/signup_password_takumi.mp3",
            "verses": [           
                {
                    "id": 3,
                    "text": "パスワード",
                    "romaji": "Pasuwādo",
                    "en": "What is your password?",
                    "es": "Ingrese su contrase;a?",
                    "showRomaji": true
                },
            ]
        };

        lyricsdata6 = {
            "audioId": "/audios/signup_password_again_takumi.mp3",
            "verses": [           
                {
                    "id": 4,
                    "text": "パスワードを再入力",
                    "romaji": "Pasuwādo o sai nyūryoku",
                    "en": "Please repeat your password",
                    "es": "Vuelva a introducir su contrase;a",
                    "showRomaji": true,
                    "spec": [
                        { "pos": 6, "kanji": "再", "furigana": "sai" },
                        { "pos": 7, "kanji": "入", "furigana": "nyū" },
                        { "pos": 8, "kanji": "力", "furigana": "ryoku" }
                    ]
                },
            ]
        };

        lyricsdata7 = {
            "audioId": "/audios/signup_accept_terms_takumi.mp3",
            "verses": [           
                {
                    "id": 5,
                    "text": "利用規約とプライバシーポリシー に 同意し ます。",
                    "romaji": "Riyō kiyaku to puraibashīporishī ni dōi shimasu. (Riyō kiyaku)",
                    "en": "I agree with the Terms of use",
                    "es": "Acepto los terminos y condiciones",
                    "showRomaji": true,
                    "spec": [
                        { "pos": 0, "kanji": "利", "furigana": "Ri" },
                        { "pos": 1, "kanji": "用", "furigana": "yō" },
                        { "pos": 2, "kanji": "規", "furigana": "ki" },
                        { "pos": 3, "kanji": "約", "furigana": "yaku" },
                        { "pos": 18, "kanji": "同", "furigana": "dō" },
                        { "pos": 19, "kanji": "意", "furigana": "i" },
                        { "pos": 27, "kanji": "利", "furigana": "Ri" },
                        { "pos": 28, "kanji": "用", "furigana": "yō" },
                        { "pos": 29, "kanji": "規", "furigana": "ki" },
                        { "pos": 30, "kanji": "約", "furigana": "yaku" },
                    ]
                },
            ]
        };

        lyricsdata8 = {
            "audioId": "/audios/signup_success_confirm_email_takumi.mp3",
            "verses": [           
                {
                    "id": 5,
                    "text": "",
                    "romaji": "",
                    "en": "Signup successfully completed. Confirm your email.",
                    "es": "Registro exitoso. Revisa tu correo electronico.",
                    "showRomaji": true,
                    "spec": [
                        { "pos": 0, "kanji": "利", "furigana": "Ri" },
                        { "pos": 1, "kanji": "用", "furigana": "yō" },
                        { "pos": 2, "kanji": "規", "furigana": "ki" },
                        { "pos": 3, "kanji": "約", "furigana": "yaku" },
                        { "pos": 18, "kanji": "同", "furigana": "dō" },
                        { "pos": 19, "kanji": "意", "furigana": "i" },
                        { "pos": 27, "kanji": "利", "furigana": "Ri" },
                        { "pos": 28, "kanji": "用", "furigana": "yō" },
                        { "pos": 29, "kanji": "規", "furigana": "ki" },
                        { "pos": 30, "kanji": "約", "furigana": "yaku" },
                    ]
                },
            ]
        };

        // 登録 register
        // パスワードを再入力  :  repeat password
        // 利用規約とプライバシーポリシー に 同意し ます。(利用規約)  :  I agree with the Terms of use
        
        async created() {
            //this.line0.value?.play();

            var pubip = await service.getClientApi();
            //console.log(pubip);
            if (pubip) {
                this.registerdata.ip = pubip.data.ip;
                console.log("PUBLIC IP: " + this.registerdata.ip);
            }
        }

        prev () {
            if (this.currentStep > 0) {
                this.currentStep--;
            }
        }
        async next() {
            //console.log("playing " + this.currentStep);
            if (this.currentStep < 7){
                this.currentStep++;

                switch(this.currentStep) {
                    case 0: 
                        this.line0.value?.play();
                    break;
                    case 1: 
                        this.line1.play();
                    break;
                    case 2: 
                        this.line2.play();
                    break;
                    case 3: 
                        this.line3.play();
                    break;
                    case 4: 
                        this.line4.play();
                    break;
                    case 5: 
                        this.line5.play();
                    break;
                    case 6: 
                        this.line6.play();
                    break;
                /*  case 7: 
                        line7.play();
                    break; */
                    case 7: 
                        this.line7.play();
                        console.log("REGISTERING USER...POST TO API");
                        await this.register();
                    break;
                }

            }
        }

        onGameFinished() {

        }
        onReadingLineComplete(line) {
            console.log(line);
        }
        async debounce (v) {
            clearTimeout(this.timer);
            this.timer = setTimeout(async () => {
                this.registerdata.username = v;
                await this.checkIfUsernameExists();
            }, 500);
        }
        async checkIfUsernameExists() {
            this.usernameExists = (await service.fetchFromApi({url: "security/checkUsername/" + this.registerdata.username})).data;
        }
        async register() {
            this.isSigningUp = true;
            /* grecaptcha.ready(()=> {
            grecaptcha.execute('6Lc0wkEbAAAAAErGwgrpXWscZbNZGO4mwplEWTE3', {action: 'submit'})
                .then(async function(token) {
                // Add your logic to submit to your backend server here.
                console.log("RECAPTCHA TOKEN: " + JSON.stringify(token));
                this.registerdata.captcha = token;
                await postToApi("security/signup", { body: this.registerdata});
                this.isSigningUp = false;
            });
            }); */
        }
    }
