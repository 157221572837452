
    import { Vue, Options } from 'vue-class-component';
    import store from "@/stores";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";
    import service from "@/services/api";
    import LogoCmp from '@/lib/SiteLayout/Logo/logo.vue';
    import helpers from '@/services/helpers';
    //import { useReCaptcha } from 'vue-recaptcha-v3';

    @Options({
        components: {
            Disclaimer, LogoCmp
        },
    }) 
    export default class UnderConstruction extends Vue {
        
        data: any = { email: '' }

        // translations
        public get thanks_lmk () { return this.$t('messages.thanks_lmk') }
        public get sitedown () { return this.$t('messages.under_construction') }
        public get letmeknow () { return this.$t('actions.letmeknow') }
        public get letmeknowmessage () { return this.$t('messages.letmeknow') }
        public get labels_email () { return this.$t('labels.email') }
        public get check_email_invalid () { return this.$t('checks.email_invalid') }
        public get check_email_required () { return this.$t('checks.email_required') }
        public get check_email_length () { return this.$t('checks.email_length') }

        email: string = ''
        formValidations: any = { email: "" }
        isAlreadyWaiting: boolean = false;

        public get isFormValid (): boolean { return this.email !== '' && this.formValidations.email === '' }

        async created() {
            AnalyticsUtils.visit(this.$route);
            store.isLoadingRoute = false;

            this.isAlreadyWaiting = store.isUserWaiting() ?? false;

        }
        
        async accept() {
            //e.preventDefault();

            //console.log("this.isAlreadyWaiting: "+this.isAlreadyWaiting)
            if (this.isAlreadyWaiting === true) return;
            //const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            await this.$recaptchaLoaded()

            //await recaptchaLoaded()

            // Execute reCAPTCHA with action "login".

            const token = await this.$recaptcha('contact')
            //console.log("RECAPTCHA TOKEN: " + JSON.stringify(token));
            //this.captcha = token;
            //await postToApi("security/signup", { body: this.datasignup});
            
            let publicIp: string = '';
            var pubip = await service.getClientApi();
            if (pubip) {
                publicIp = pubip.data.ip;
                //console.log("PUBLIC IP: " + publicIp);
            }

            await service.postToApi("home/letmeknow", { body: { captcha: token, email: this.email, publicIp } } )

            store.userIsWaitingNotifSiteOnline(publicIp);

            this.isAlreadyWaiting = true;
        }

        checkEmail() {
            if (!this.email)
                this.formValidations.email = this.check_email_required;
            else if (!helpers.isValidEmail(this.email))
                this.formValidations.email = this.check_email_invalid;
            else if (this.email.length < 3 || this.email.length > 50)
                this.formValidations.email = this.check_email_length;
            else
                this.formValidations.email = ''; 
        }
    }
