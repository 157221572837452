import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7218a3b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "container",
  ref: "container",
  class: "webgl overflow-hidden"
}
const _hoisted_2 = {
  id: "containerCSS",
  ref: "containerCSS",
  class: "webgl overflow-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_UserLevelModal = _resolveComponent("UserLevelModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createVNode(_component_SearchBox, {
      results: _ctx.search_results,
      onOnfocus: _ctx.onInputSearchFocus,
      onTyping: _ctx.search,
      onSelected: _ctx.onItemSelected
    }, null, 8, ["results", "onOnfocus", "onTyping", "onSelected"]),
    _createElementVNode("div", _hoisted_2, null, 512),
    _createVNode(_component_UserLevelModal, {
      showModal: _ctx.showModal,
      onSaved: _ctx.saved
    }, null, 8, ["showModal", "onSaved"])
  ], 64))
}