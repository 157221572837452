import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto sm:px-3 md:px-44 lg:px-24 mt-10" }
const _hoisted_2 = { class: "grid grid-rows-3 grid-flow-col grid-cols-3 gap-4" }
const _hoisted_3 = { class: "row-span-3" }
const _hoisted_4 = { class: "flex flex-col p-4 overflow-hidden" }
const _hoisted_5 = { class: "mb-2 border-b-2 p-2" }
const _hoisted_6 = { class: "mb-2 border-b-2 p-2" }
const _hoisted_7 = { class: "mb-2 border-b-2 p-2" }
const _hoisted_8 = { class: "mb-2 border-b-2 p-2" }
const _hoisted_9 = { class: "col-span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Disclaimer = _resolveComponent("Disclaimer")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_router_link, { to: "/dashboard" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.titles_progress), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, { to: "/dashboard/history" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.titles_history), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, { to: "/dashboard/contributions" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.titles_my_lyrics), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_router_link, { to: "/dashboard/favorites" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.titles_favorites), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_router_view)
        ])
      ])
    ]),
    _createVNode(_component_Disclaimer),
    _createVNode(_component_Footer)
  ], 64))
}