import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-621d317a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  class: "questionBox",
  id: "app"
}
const _hoisted_3 = { class: "questionContainer" }
const _hoisted_4 = { class: "text-xl" }
const _hoisted_5 = { class: "text-base" }
const _hoisted_6 = { class: "page lg:p-8 page_active" }
const _hoisted_7 = { class: "med-text text-md focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 m-8" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "questionFooter" }
const _hoisted_10 = {
  class: "pagination flex justify-end",
  role: "navigation",
  "aria-label": "pagination"
}
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderLevelCards = _resolveComponent("SliderLevelCards")!
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event)),
    classes: "flex justify-center items-center",
    "content-class": "w-1/3 relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900"
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_Transition, {
            "enter-active-class": "animated zoomIn",
            "leave-active-class": "animated zoomOut",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("header", null, [
                  _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1),
                  _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.level_modal_subtitle), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_SliderLevelCards, { onSelected: _ctx.onSelectedLevel }, null, 8, ["onSelected"]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("textarea", {
                      value: _ctx.hard_topic,
                      placeholder: "(Optional) Is there any topic in specific you feel ists been difficult for you?",
                      class: "w-full focus:outline-none text-center",
                      rows: "3",
                      maxlength: "150"
                    }, null, 8, _hoisted_8)
                  ])
                ]),
                _createElementVNode("footer", _hoisted_9, [
                  _createElementVNode("nav", _hoisted_10, [
                    _createElementVNode("button", {
                      href: "javascript:void(0)",
                      class: "btn button order-last",
                      onClick: _cache[0] || (_cache[0] = async (e)=> {await _ctx.save()}),
                      disabled: !_ctx.selectedLevel
                    }, [
                      (_ctx.isLoading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(_ctx.actions_continue), 1)
                          ], 64))
                    ], 8, _hoisted_11)
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}