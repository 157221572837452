
    import { Vue, Options } from 'vue-class-component';
    import store from "@/stores";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";

    @Options({
        components: {
            Disclaimer
        },
    }) 
    export default class BackSoon extends Vue {
        
        // translations
        public get sitedown () { return this.$t('messages.sitedown') }
        public get retry () { return this.$t('actions.retry') }

        async created() {
            AnalyticsUtils.visit(this.$route);
            store.isLoadingRoute = false;
        }
        
        goHome() {
            this.$router.push({ path: `/`});
        }
    }
