
import { Vue, Options } from 'vue-class-component';
import service from "@/services/api";
import store from "@/stores";
import Analytics from "@/services/analytics";
import * as THREE from "three";
import {
    CSS3DRenderer,
    CSS3DObject,
} from "three/examples/jsm/renderers/CSS3DRenderer.js";
import { ref, toRaw } from 'vue';
import anime from 'animejs/lib/anime.es.js';
import helpers from '@/services/helpers';
import CameraControls from 'camera-controls';
import SearchBox from '@/lib/SearchBox/index.vue';
import UserLevelModal from '@/lib/Modals/userlevel_modal.vue';

CameraControls.install({ THREE: THREE });

@Options({
    components: {
        //Disclaimer, Footer//, 
        SearchBox, UserLevelModal
    },
})
export default class DiscoverLyricsAlt extends Vue {
    
    items: any = [];
    search_results: any = [];
    searchValue: any = '';
    timer: any;
    debounceGenreTimer: any;
    debounceTempoTimer: any;
    showSearchResults: any = false;
    isSearching: any = false;
    clock = new THREE.Clock();
    scene; 
    scene2;
    camera;
    renderer; rendererCSS;
    container: any = ref<HTMLDivElement | null>(null);
    containerCSS: any = ref<HTMLDivElement | null>(null);
    
    percentage = 0;
    animationFrameId: any;

    mouseDown = false;
    mouseX = 0;
    mouseY = 0;
    cameraControls
    pointLights: any = [];

    isFirstTime: boolean = true;
    showModal: boolean = false;
    //adsbygoogle: any;
    
    async created() {
        Analytics.visit(this.$route);

        /* console.log(this.adsbygoogle);
        if (this.adsbygoogle) {
            (this.adsbygoogle = window.adsbygoogle || []).push({});
        } */   
    }

    async mounted() {
        setTimeout(() => {
            store.isLoadingRoute = false;
        }, 2000);

        this.isSearching = true;
        const defaultHome = JSON.parse(await service.getHome());
        this.items = defaultHome;

        // defaults
        this.search_results.artists = defaultHome.defaultArtists;// default when popup is opened for the first time
        this.search_results.genres = defaultHome.genres;
        this.search_results.lyrics = defaultHome.topRecentlyAdded;

        this.init();
        this.animate();

        this.isSearching = false;
    }

    onInputSearchFocus(focused: boolean) {
        if (focused === true)
            this.cameraControls.dolly(  -40, true ) // zoom out
        else
            this.cameraControls.dolly(  40, true ) // zoom in/close up
    }

   /*  SKY_COLOR = 0x999999
    GROUND_COLOR = 0x242424
    SKY_SIZE = 1000000 */

   /*  addSkyGradient(scene) {
        const vertexShader = `
        varying vec3 vWorldPosition;
                void main() {
                    vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
                    vWorldPosition = worldPosition.xyz;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`
        const fragmentShader = `
        uniform vec3 topColor;
                uniform vec3 bottomColor;
                varying vec3 vWorldPosition;
                void main() {
                    float h = normalize( vWorldPosition).z;
                    gl_FragColor = vec4( mix( bottomColor, topColor, max( h, 0.0 ) ), 1.0 );
                }`
        const uniforms = {
            topColor: { value: new THREE.Color(this.SKY_COLOR) },
            bottomColor: { value: new THREE.Color(this.GROUND_COLOR) }
        }
        const skyGeo = new THREE.SphereGeometry(this.SKY_SIZE, 32, 15)
        const skyMat = new THREE.ShaderMaterial({
            uniforms,
            vertexShader,
            fragmentShader,
            side: THREE.BackSide
        })
        const sky = new THREE.Mesh(skyGeo, skyMat)
        scene.add(sky)
    } */

    init() {
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1500);
        this.camera.position.set(0, 75, 200);
        this.camera.rotation.x = -THREE.Math.degToRad(45);

        this.scene = new THREE.Scene();
        //this.scene.background = null; // new THREE.Color(0xececec);
        // this.scene.fog = new THREE.Fog(0xA6CDFB, 1, 1000);  
        this.scene2 = new THREE.Scene();
        //this.scene2.fog = new THREE.FogExp2( 0x6dd5fa, 0.015 );
        //this.scene2.background = null; //

        this.addGLRenderer();
        this.addCSSRenderer();

        //this.addWorldSphere(3000);

        // lights
        /* var light = new THREE.SpotLight( 16726440, .5 );
        light.angle = 0.50;
        light.decay = 1;
        light.position.set( -50.56, -21.69, 50.41 );
        this.scene.add( light );
        var sphere = new THREE.SphereGeometry( 0.1, 16, 8 );
        for (var i = 0; i <= 8; i++) {
            light = new THREE.PointLight( 16726440, .8, 10 );
            light.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 16726440 } ) ) );
            
            this.scene.add( light );
            this.pointLights.push(light);
        } */

        this.cameraControls = new CameraControls(this.camera, this.rendererCSS.domElement); // ** changed from renderer to cssRenderer/labelRenderer
        this.cameraControls.mouseButtons.left = CameraControls.ACTION['TRUCK']
        this.cameraControls.touches.one = CameraControls.ACTION['TOUCH_TRUCK']
        this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.NONE
        this.cameraControls.truckSpeed = 4;
        this.cameraControls.dollySpeed = 3;
        this.cameraControls.minDistance = 5;
        this.cameraControls.maxDistance = 600;  

        this.addDocuments();

        window.addEventListener("resize", this.onWindowResize);
        window.addEventListener("wheel", this.onMouseWheel, false);
        /* 
        window.addEventListener("mousedown", () => {
            window.addEventListener("mousemove", this.updateCamera);
        });
        window.addEventListener("mouseup", () => {
            window.removeEventListener("mousemove", this.updateCamera);
        }); */

        /*  window.addEventListener('mousedown', (evt) => {
             evt.preventDefault();
             this.mouseDown = true;
             this.mouseX = evt.clientX;
             this.mouseY = evt.clientY;
         }, false);
         window.addEventListener('mousemove', (evt) => {
             if (!this.mouseDown) {return} // is the button pressed?
             evt.preventDefault();
             var deltaX = evt.clientX - this.mouseX,
                 deltaY = evt.clientY - this.mouseY;
                 this.mouseX = evt.clientX;
                 this.mouseY = evt.clientY;
                 this.dragAction(deltaX, deltaY);
         }, false);
         window.addEventListener('mouseup', (evt) => {
             evt.preventDefault();
             this.mouseDown = false;
         }, false); */
        /* window.addEventListener('touchstart', this.onTouchDown.bind(this))
        window.addEventListener('touchmove', this.onTouchMove.bind(this))
        window.addEventListener('touchend', this.onTouchUp.bind(this)) */

        this.animateCameraIntro();
    }

    onMouseWheel(event) {
        var scroll = event.deltaY

        if (scroll < 0)
        {
            // console.log('scrolling up');
            this.cameraControls.dolly(  15, true )
        }
        else if (scroll > 0)
        {
            //console.log('scrolling down');
            this.cameraControls.dolly(  -15, true )
        }
    }
    dragAction(deltaX, deltaY) {
        /* object.rotation.y += deltaX / 100;
        object.rotation.x += deltaY / 100; */

        this.percentage = this.lerp(this.percentage, deltaY, .09);

        this.camera.position.x += this.lerp(this.camera.position.x, deltaX, .6); // deltaX  * 0.08 ;
        this.camera.position.y += deltaY * 0.08;
        //this.camera.position.lerp()
    }
    animateCameraIntro() {
        this.cameraControls.dolly( 60, true)

        // zoom in on start //  -851, 401, 1500
        /* anime({
            targets: this.camera.position,
            //x: startCameraPosition.x + 50,
            //y: startCameraPosition.y + 50,
            z: 140,
            duration: 1400,
            delay: 200,
            easing: 'easeOutSine',
            complete: () => {
                //this.allowControl = true;
            }
        }); */
    }

   /*  setLights() {
        // point light
        const pointlight = new THREE.PointLight(0xffffff, 0.75, 1000);
        this.scene.add(pointlight);
        pointlight.position.set(-439, 211, 38);

        // spot light
        const spotlight = new THREE.SpotLight(0xffffff, 1);
        this.scene.add(spotlight);
        spotlight.position.set(-179, 168, 168);

        // ambient light
        const ambientlight = new THREE.AmbientLight(0x101010); // soft white light
        this.scene.add(ambientlight);
    } */
    addDocuments() {
        console.log(this.items)
        for (var i = 0; i < this.items.topRecentlyAdded.length; i++) {

            var container = document.createElement('div');
            container.classList.add('noselect');
            container.id = this.items.topRecentlyAdded[i].id + '';
            container.style.width = '100px';
            container.style.height = '50px';
            container.style.background = 'white';
            container.style.borderRadius = '5px';

            var img = document.createElement('img');
            img.style.width = '100%';
            img.style['border-radius'] = '5px';
            //img.style.height = '100%';
            img.src = this.items.topRecentlyAdded[i].thumbnailAbsoluteUrl;

            var imgartist = document.createElement('img');
            imgartist.style.width = '15px';
            imgartist.style.height = '15px';
            imgartist.style['border-radius'] = '50%';
            //imgartist.style.height = '100%';
            imgartist.src = this.items.topRecentlyAdded[i].thumbnailAbsoluteUrl;
            imgartist.style.position = 'absolute';
            imgartist.style.bottom = '-23px';

            var artistname = document.createElement('small');
            artistname.style.position = 'absolute';
            artistname.style.bottom = '-20px';
            artistname.style.left = '25px';
            artistname.style.fontSize = '0.3em';
            artistname.textContent = this.items.topRecentlyAdded[i].bandName

            var title = document.createElement('h1');
            //element.style.width = '100px';
            //element.style.height = '80px';
            //element.style.opacity = '0.999';
            title.style.fontSize = '0.7em';
            // title.style.display = '';
            title.style.position = 'absolute';
            title.style.top = '-40px';
            /* title.style.background = new THREE.Color(
            Math.random() * 0.21568627451 + 0.462745098039,
            Math.random() * 0.21568627451 + 0.462745098039,
            Math.random() * 0.21568627451 + 0.462745098039,
            ).getStyle(); */
            title.textContent = this.items.topRecentlyAdded[i].songTitle
            //title.setAttribute('contenteditable', '')

            /* container.addEventListener("click", () => {
                console.log("CLICKED DOC!!!! " + i);
                this.zoomIn();

            }); */
            /* container.addEventListener("mouseover", () => {
                container.classList.add("hovered")
            }); */

            container.appendChild(title);
            container.appendChild(img);
            container.appendChild(imgartist);
            container.appendChild(artistname);

            var domObject = new CSS3DObject(container);
            /* domObject.position.x = Math.random() * 600 - 300;
            domObject.position.y = Math.random() * 600 - 300;
            domObject.position.z = Math.random() * 800 - 600;
            domObject.rotation.x = Math.random();
            domObject.rotation.y = Math.random();
            domObject.rotation.z = Math.random(); */
            domObject.rotation.x = -THREE.Math.degToRad(20);
            domObject.position.x = i * 140; // ---> to the right
            domObject.position.y = helpers.randomNumberBetween(-200, 500); // up/down 
            domObject.position.z = helpers.randomNumberBetween(0, -300); // closer or far away
            //domObject.scale.x = Math.random() + 0.5;
            //domObject.scale.y = Math.random() + 0.5;
            this.scene2.add(domObject);
        }
    }
    zoomIn() {
        anime({
            targets: this.camera.position,
            //x: startCameraPosition.x + 50,
            //y: startCameraPosition.y + 50,
            z: 0,
            duration: 1000,
            delay: 0,
            easing: 'easeOutSine',
            complete: () => {
                //this.allowControl = true;
            }
        });
    }
    onWindowResize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
    addGLRenderer() {
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        //this.renderer.setClearColor(0x000000, 0); // the default
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        //this.renderer.outputEncoding = THREE.sRGBEncoding;
        //this.renderer.outputEncoding = THREE.sRGBEncoding;
		//this.renderer.toneMapping = THREE.ReinhardToneMapping;
		//this.renderer.outputEncoding = THREE.sRGBEncoding;
		//this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.container.appendChild(this.renderer.domElement);

        //this.effect = new OutlineEffect( this.renderer );
    }
    addCSSRenderer() {
        this.rendererCSS = new CSS3DRenderer(/* { antialias: true, alpha: true } */);
        this.rendererCSS.setSize(window.innerWidth, window.innerHeight);
        this.rendererCSS.domElement.style.position = "absolute";
        this.rendererCSS.domElement.style.top = 0;
        this.containerCSS.appendChild(this.rendererCSS.domElement); 
    }
    addWorldSphere(r) {
        var geometry = new THREE.SphereGeometry(r, 50, 50);
        var material = new THREE.MeshBasicMaterial({color: 0xcdbcb4, wireframe: true, fog:false});
        var sphere = new THREE.Mesh(geometry, material);
        this.scene.add(sphere);
    }
    lerp(a, b, t) {
        return ((1 - t) * a + t * b);
    }
    animate() {
        this.animationFrameId = requestAnimationFrame(this.animate);
        this.update();
    }
    update() {
        const currentPos = this.cameraControls.getPosition();
        //console.log(currentPos)

        if (currentPos.x < 1000)
            this.cameraControls.truck(  0.2,  0, true)
        
        const delta = this.clock.getDelta();
        this.cameraControls.update(delta);


        /* var time = Date.now() * 0.0008 ;
        this.pointLights[0].position.x = Math.sin( time * 0.3  ) * 15;
        this.pointLights[0].position.y = Math.sin( time * 0.5  ) * 10;
        this.pointLights[0].position.z = Math.cos( time * 0.4  ) * 10;

            
        this.pointLights[1].position.x = Math.sin( time * 0.6  ) * 10;
        this.pointLights[1].position.y = Math.cos( time * 0.7 ) * 10;
        this.pointLights[1].position.z = Math.sin( time * 0.3 ) * 15;

        this.pointLights[2].position.x = Math.cos( time * 0.5  ) * 15;
        this.pointLights[2].position.y = Math.cos( time * 0.6 ) * 10;
        this.pointLights[2].position.z = Math.sin( time * 0.8 ) * 10;

        this.pointLights[3].position.x = Math.sin( time * 0.3  ) * 10;
        this.pointLights[3].position.y = Math.cos( time * 0.5 ) * 15;
        this.pointLights[3].position.z = Math.cos( time * 0.7 ) * 10;

        this.pointLights[4].position.x = Math.sin( time * 0.7  ) * 15;
        this.pointLights[4].position.y = Math.sin( time * 0.3 ) * 20;
        this.pointLights[4].position.z = Math.cos( time * 0.2 ) * 10;

        this.pointLights[5].position.x = Math.sin( time * 0.5  ) * 20;
        this.pointLights[5].position.y = Math.cos( time * 0.8 ) * 10;
        this.pointLights[5].position.z = Math.sin( time * 0.5 ) * 15;

        this.pointLights[6].position.x = Math.sin( time * 0.5  ) * 10;
        this.pointLights[6].position.y = Math.cos( time * 0.8 ) * 10;
        this.pointLights[6].position.z = Math.cos( time * 0.7 ) * 15;

        this.pointLights[7].position.x = Math.sin( time * 0.3  ) * 10;
        this.pointLights[7].position.y = Math.cos( time * 0.5 ) * 15;
        this.pointLights[7].position.z = Math.sin( time * 0.2 ) * 10;

        this.pointLights[8].position.x = Math.sin( time * 0.8  ) * 15;
        this.pointLights[8].position.y = Math.cos( time * 0.3 ) * 10;
        this.pointLights[8].position.z = Math.cos( time * 0.3 ) * 10; */

        //this.effect.render( this.scene, this.camera );

        this.renderer.render(toRaw(this.scene), this.camera);
        this.rendererCSS.render(toRaw(this.scene2), this.camera);
    }

    async search(searchValue: string) {
        this.showSearchResults = true;
        this.isSearching = true;

        if (!this.items || !this.items.genres) return;
        
        var result = { 
            criteria: searchValue,
            //genres: selectedGenres.join(","),
            //tempos: selectedTempos.join(",")
        };
        const res = JSON.parse(await service.postToApi("search/lyrics", { body: result } ))
        //const res = await service.postToApi({ url: "search/lyrics?criteria=" + this.searchValue + "&tempos=" + temposQueryString + "&genres=" + genresQueryString });
        //items.topPlayed = tops;
        this.search_results = res;
        this.isSearching = false;

        // TODO: add to the url the search and keywords to track with GA
    }

    reset() {
        if (this.items.genres) {
            this.items.genres.forEach((genre, i) => {
                this.items.genres[i].isSelected = false;
            });
        }

        this.searchValue = '';
        this.showSearchResults = false;
    }

    onItemSelected(event) {
        if (event)
            this.$router.push({ path: `/lyrics/select-mode/${event}` });
    }

    saved() {
        this.showModal = false;
    }

    unmounted(){
        //this.
        /* window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("wheel", this.onMouseWheel, false); */
    }
}
