export default {
  "meta": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice your Japanese skills and learn with song lyrics for free, kanji, katakana and hiragana, tons of vocabulary."])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitome App"])},
    "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anime music,learn,language,online,online,free,music,video,lyrics,song,karaoke,play,game,exercise,fill in,blanks,english,spanish,japanese,romaji,japan,kanji,hiragana,katakana,japanese lyrics,writing system"])}
  },
  "page": {
    "home": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nitome"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signup"])},
      "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signin"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    },
    "titles": {
      "main": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Learn Japanese the fun way"])])},
      "sitedown": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["We will be back shortly"])])},
      "lyrics": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Lyrics and Kanji"])])},
      "lyrics_search": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Search lyrics"])])},
      "lyrics_editor": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Lyrics Editor"])])},
      "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "home": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Your dashboard"])])},
      "features": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Features"])])},
      "about": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["About"])])}
    }
  },
  "messages": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Not Found"])},
    "startnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start now for free"])},
    "pha1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Nitome. A website to enjoy music as you study!"])},
    "subpha1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["are you learning Japanese or simply love Japan? you came to the right place."])},
    "pha2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singing along to Japanese songs can actually improve your reading speed. "])},
    "subpha2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyrics can get stuck in your head so easily. Lets take advantage of that!"])},
    "pha3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["listen, recognize, and mimick specific sounds"])},
    "subpha3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using music to help build your vocabulary and memorize words effectively."])},
    "pha4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level up your reading and listening skills"])},
    "subpha4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start listening now for free"])},
    "sitedown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The site is temporarily unavailable. We will be back soon..."])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is for educational and informational purposes only. We do not own this content. All credits go to its rightful owner. Fair Use: This tool is publicly available in our efforts to advance the Japanese Language learning. No copyright infringment is intended or implied."])},
    "lyrics_zyncronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyrics synchronized"])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found. Try another criteria"])},
    "startexploring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover lyrics"])},
    "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "contact_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch! Feel free to contact us. Any suggestions and feedback are welcome."])},
    "sponsor_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a Nitome Sponsor!"])},
    "sponsor_pha1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not a sponsor you have a limit on our site."])},
    "sponsor_pha2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't view more than 5 lyrics per hour."])},
    "sponsor_pha3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To watch more without limits, consider become a sponsor of the project."])},
    "sponsor_pha4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are already a sponsor, please login."])},
    "loading_lyrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading lyrics. We are almost there..."])},
    "just_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I just started learning or I have basic knowledge."])},
    "mid_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I've been studying for a few months. I know Katakana, Hiragana and some Kanjis."])},
    "adv_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I've been stydying for a few years."])},
    "hard_topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Optional) What have been your biggest challenges of learning Japanese?"])},
    "letmeknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email below and we'll let you know when we are back online"])},
    "join_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a class code to join a class."])},
    "under_construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site under construction"])},
    "thanks_lmk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you! We'll be back online soon."])}
  },
  "titles": {
    "select_marker_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the element type"])},
    "scene_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scene configuration"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "congratulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "my_lyrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My lyrics"])},
    "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "genres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genres"])},
    "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed"])},
    "review_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review items"])},
    "current_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would you consider you current Japanese level is?"])},
    "level_modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll improve your experience based on your selection."])},
    "lyrics_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyrics Manager"])}
  },
  "labels": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "strokes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strokes"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freq"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "band_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Band Name"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release Year"])},
    "youtube_video_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube Video Id"])},
    "youtube_video_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube Video Url"])},
    "input_youtube_url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy and paste the video url from youtube here..."])},
    "verse_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verse total count"])},
    "characters_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characters total count"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signup"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signin"])},
    "song_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song title"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "add_lyric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the lyrics here"])},
    "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
    "recently_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently added"])},
    "top_anime_openings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Anime Openings"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting"])},
    "last_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last played"])},
    "first_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First time"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menssage"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
    "enthusiast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthusiast"])},
    "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join a class"])}
  },
  "actions": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
    "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
    "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
    "maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximize"])},
    "minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signin"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signup"])},
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signout"])},
    "existent_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I already have an account"])},
    "send_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send for revision"])},
    "go_next_verse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to next verse"])},
    "go_prev_verse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to previous verse"])},
    "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
    "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
    "delete_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selected"])},
    "move_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move right"])},
    "move_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move left"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join with"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])},
    "karaoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karaoke"])},
    "veryeasy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very easy"])},
    "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy"])},
    "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficult"])},
    "challenging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenging"])},
    "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard"])},
    "veryhard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very hard"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "no_thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, thank you"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go home"])},
    "add_lyric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Lyric"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select difficulty"])},
    "select_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select plan"])},
    "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue free"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "letmeknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let me Know"])}
  },
  "tempo": {
    "very_slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["very slow"])},
    "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slow"])},
    "moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderate"])},
    "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fast"])},
    "very_fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["very fast"])}
  },
  "about": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Nitome"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know how easy it is to get overwhelmed studying Japanese everyday."])},
    "p1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this website you'll be able to practice Japanese through music with your favorite bands and songs. Search a specific song or simply explore new music."])},
    "p1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you join, you'll access a dashboard with statistics and monitor your progress."])},
    "p1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll practice grammar, spelling, and pronunciation."])},
    "p1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keep practicing and just have fun!"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the JLPT (Japanese Language Proficiency Test)?"])},
    "p2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The JLPT has five levels (N5-N1) in order to measure your Japanese-language proficiency, focuses on "])},
    "p2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reading and Listening"])},
    "p2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mainly. (doesnt measure speaking or writing proficiency directly)"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website offers the potential to enhance reading, listening and language learning in general, you can practice all of these 5 levels in an interactive way."])}
  },
  "checks": {
    "username_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This username is taken. Try again."])},
    "username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The username is required."])},
    "username_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The username should be between 5 and 15 characteres long."])},
    "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email is required."])},
    "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not valid."])},
    "pass_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password is required."])},
    "pass_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password should be between 6 and 12 characteres long."])}
  },
  "privacy": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitome es una plataforma web que pone gratuitamente a disposicion letras de canciones para facilitar el aprendizaje del idioma."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿QUÉ HACEMOS CON TU INFORMACIÓN?"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando utilizas nuestro sitio web, nosotros recolectamos la información personal que nos das tales como nombre y correo electrónico."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando navegas en nuestro sitio, también recibimos de manera automática la dirección de protocolo de internet de tu computadora (IP) con el fin de proporcionarnos información que nos ayuda a conocer acerca de su navegador y sistema operativo para brindar una mejor experiencia."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con tu permiso, podremos enviarte correos electrónicos acerca de novedades, nuevas características y otras actualizaciones."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSENTIMIENTO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo obtener mi consentimiento?"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si te pedimos tu información personal por una razón secundaria, como marketing, te pediremos directamente tu expreso consentimiento, o te daremos la oportunidad de negarte."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo anular mi consentimiento?"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si luego de haber aceptado cambias de opinión, puedes anular tu consentimiento para que te contactemos, por la recolección, uso o divulgación de tu información, en cualquier momento, escribenos a nitomeapp", "@", "gmail.com."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIVULGACIÓN"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podemos divulgar tu información personal si se nos requiere por ley o si violas nuestros Términos de Servicio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICIOS DE TERCERAS PARTES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En general, los proveedores de terceras partes utilizados por nosotros solo recopilaran, usarán y divulgarán tu información en la medida que sea necesaria para que les permita desempeñar los servicios que nos proveen."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin embargo, algunos proveedores de servicios de terceros, tales como pasarelas de pago y otros procesadores de transacciones de pago, tienen sus propias políticas de privacidad con respecto a la información que estamos obligados a proporcionarles para las transacciones relacionadas con las subscripciones."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para estos proveedores, te recomendamos que leas las políticas de privacidad para que puedas entender la manera en que tu información personal será manejada."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En particular, recuerda que algunos proveedores pueden estar ubicados o contar con instalaciones que se encuentran en una jurisdicción diferente a ti o nosotros. Asi que si deseas proceder con una transacción que involucra los servicios de un proveedor a terceros, tu información puede estar sujeta a las leyes de la jurisdicción (jurisdicciones) en que se encuentra el proveedor de servicios o sus instalaciones."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez que abandonas el sitio web o te rediriges a un sitio o aplicación de terceros, ya no estás siendo regulados por la presente Política de Privacidad o los Términos de Servicio de nuestra página web."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando haces clic en un enlace, puede que seas redirigido fuera de nuestro sitio. No somos responsables por las prácticas de privacidad de otros sitios y te recomendamos leer sus normas de privacidad."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In no case Nitome stores, modifies, partially or totally the videos transferred by YouTube. All the videos that you find on our site are provided directly by the YouTube video platform through its embeddable player."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGURIDAD"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para proteger tu información personal, tomamos precauciones razonables y seguimos las mejores prácticas de la industria para asegurarnos de que no haya pérdida de manera inapropiada, mal uso, acceso, divulgación, alteración o destrucción de la misma."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDAD DE CONSENTIMIENTO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al utilizar este sitio, declaras que tienes al menos la mayoría de edad en tu estado o provincia de residencia, y que nos has dado tu consentimiento para permitir que cualquiera de tus dependientes menores use este sitio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento, así que por favor revísala frecuentemente. Cambios y aclaraciones entrarán en vigencia inmediatamente después de su publicación en el sitio web. Si hacemos cambios materiales a esta política, notificaremos aquí que ha sido actualizada, por lo que cual estás enterado de qué información recopilamos, cómo y bajo qué circunstancias, si las hubiere, la utilizamos y/o divulgamos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PREGUNTAS E INFORMACIÓN DE CONTACTO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si quieres: acceder, corregir, enmendar o borrar cualquier información personal que poseamos sobre ti, registrar una queja, o simplemente quieres más información contactanos al correo electronico nitomeapp", "@", "gmail.com"])}
  ],
  "terms": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión: 1.0 - Octubre 2021"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÉRMINOS DE SERVICIO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMACIÓN GENERAL"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio web/app esta disponible en la direccion https://nitome.app. En todo el sitio, los términos “nosotros”, “nos” y “nuestro” se refieren a Nitome App. Nitome App. ofrece este sitio web/app, incluyendo toda la información, herramientas y servicios disponibles para ti en este sitio, el usuario, está condicionado a la aceptación de todos los términos, condiciones, políticas y notificaciones aquí establecidos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al visitar nuestro sitio y/o hacer uso de las funcionalidades, participas en nuestro “Servicio” y aceptas los siguientes términos y condiciones (“Términos de Servicio”, “Términos”), incluídos todos los términos y condiciones adicionales y las políticas a las que se hace referencia en el presente documento y/o disponible a través de hipervínculos. Estas Condiciones de Servicio se aplican a todos los usuarios del sitio, incluyendo sin limitación a usuarios que sean navegadores, y/o colaboradores de contenido."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, lee estos Términos de Servicio cuidadosamente antes de acceder o utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio, estás aceptando los Términos de Servicio. Si no estás de acuerdo con todos los términos y condiciones de este acuerdo, entonces no deberías acceder a la página web o usar cualquiera de los servicios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier función nueva o herramienta que se añadan al sitio web actual, también estarán sujetas a los Términos de Servicio. Puedes revisar la versión actualizada de los Términos de Servicio, en cualquier momento en esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los Términos de Servicio mediante la publicación de actualizaciones y/o cambios en nuestro sitio web. Es tu responsabilidad chequear esta página periódicamente para verificar cambios. Tu uso contínuo o el acceso al sitio web después de la publicación de cualquier cambio constituye la aceptación de dichos cambios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÉRMINOS DE SERVICIO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al utilizar este sitio, declaras que tienes al menos la mayoría de edad en tu estado o provincia de residencia, y que nos has dado tu consentimiento para permitir que cualquiera de tus dependientes menores use este sitio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes usar nuestros productos con ningún propósito ilegal o no autorizado tampoco puedes, en el uso del Servicio, violar cualquier ley en tu jurisdicción (incluyendo pero no limitado a las leyes de derecho de autor)."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No debes transmitir gusanos, virus o cualquier código de naturaleza destructiva."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El incumplimiento o violación de cualquiera de estos Términos darán lugar al cese inmediato de tus Servicios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONDICIONES GENERALES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos reservamos el derecho de rechazar la prestación de servicio a cualquier persona, por cualquier motivo y en cualquier momento."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entiendes que tu contenido (sin incluir la información de tu tarjeta de crédito), puede ser transferida sin encriptar e involucrar (a) transmisiones a través de varias redes; y (b) cambios para ajustarse o adaptarse a los requisitos técnicos de conexión de redes o dispositivos. La información de tarjetas de crédito está siempre encriptada durante la transferencia a través de las redes."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás de acuerdo con no reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del Servicio, uso del Servicio, o acceso al Servicio o cualquier contacto en el sitio web a través del cual se presta el servicio, sin el expreso permiso por escrito de nuestra parte."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los títulos utilizados en este acuerdo se incluyen solo por conveniencia y no limita o afecta a estos Términos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXACTITUD, EXHAUSTIVIDAD Y ACTUALIDAD DE LA INFORMACIÓN"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No nos hacemos responsables si la información disponible en este sitio no es exacta, completa o actual. El material en este sitio es provisto solo para información general y no debe confiarse en ella o utilizarse como la única base para la toma de decisiones sin consultar primeramente, información más precisa, completa u oportuna.  Cualquier dependencia en el materia de este sitio es bajo su propio riesgo."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio puede contener cierta información histórica. La información histórica, no es necesariamente actual y es provista únicamente para tu referencia. Nos reservamos el derecho de modificar los contenidos de este sitio en cualquier momento, pero no tenemos obligación de actualizar cualquier información en nuestro sitio.  Aceptas que es tu responsabilidad de monitorear los cambios en nuestro sitio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MODIFICACIONES AL SERVICIO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte del contenido) en cualquier momento sin aviso previo."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No seremos responsables ante ti o alguna tercera parte por cualquier modificación, suspensión o discontinuidad del Servicio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HERRAMIENTAS OPCIONALES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es posible que te proporcionamos acceso a herramientas de terceros a los cuales no monitoreamos y sobre los que no tenemos control ni entrada."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconoces y aceptas que proporcionamos acceso a este tipo de herramientas 'tal cual' y 'según disponibilidad' sin garantías, representaciones o condiciones de ningún tipo y sin ningún respaldo. No tendremos responsabilidad alguna derivada de o relacionada con tu uso de herramientas proporcionadas por terceras partes."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier uso que hagas de las herramientas opcionales que se ofrecen a través del sitio bajo tu propio riesgo y discreción y debes asegurarte de estar familiarizado y aprobar los términos bajo los cuales estas herramientas son proporcionadas por el o los proveedores de terceros."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También es posible que, en el futuro, te ofrezcamos nuevos servicios y/o características a través del sitio web (incluyendo el lanzamiento de nuevas herramientas y recursos). Estas nuevas características y/o servicios también estarán sujetos a estos Términos de Servicio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENLACES DE TERCERAS PARTES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierto contenido, productos y servicios disponibles vía nuestro Servicio puede incluir material de terceras partes."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlaces de terceras partes en este sitio pueden redireccionarse a sitios web de terceras partes que no están afiliadas con nosotros. No nos responsabilizamos de examinar o evaluar el contenido o exactitud y no garantizamos ni tendremos ninguna obligación o responsabilidad por cualquier material de terceros o sitios web, o de cualquier material, productos o servicios de terceros."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No nos hacemos responsables de cualquier daño o daños relacionados con la adquisición o utilización de bienes, servicios, recursos, contenidos, o cualquier otra transacción realizadas en conexión con sitios web de terceros. Por favor revisa cuidadosamente las políticas y prácticas de terceros y asegúrate de entenderlas antes de participar en cualquier transacción.  Quejas, reclamos, inquietudes o preguntas con respecto a productos de terceros deben ser dirigidas a la tercera parte."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMENTARIOS DE USUARIO, CAPTACIÓN Y OTROS ENVÍOS"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si, a pedido nuestro, envías ciertas presentaciones específicas (por ejemplo la participación en concursos) o sin un pedido de nuestra parte envías ideas creativas, sugerencias, proposiciones, planes, u otros materiales, ya sea en línea, por email, por correo postal, o de otra manera (colectivamente, 'comentarios'), aceptas que podamos, en cualquier momento, sin restricción, editar, copiar, publicar, distribuir, traducir o utilizar por cualquier medio comentarios que nos hayas enviado. No tenemos ni tendremos ninguna obligación (1) de mantener ningún comentario confidencialmente; (2) de pagar compensación por comentarios; o (3) de responder a comentarios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros podemos, pero no tenemos obligación de, monitorear, editar o remover contenido que consideremos sea ilegítimo, ofensivo, amenazante, calumnioso, difamatorio, pornográfico, obsceno u objetable o viole la propiedad intelectual de cualquiera de las partes o los Términos de Servicio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas que tus comentarios no violará los derechos de terceras partes, incluyendo derechos de autor, marca, privacidad, personalidad u otro derechos personal o de propiedad. Asimismo, aceptas que tus comentarios no contienen material difamatorio o ilegal, abusivo u obsceno, o contienen virus informáticos u otro malware que pudiera, de alguna manera, afectar el funcionamiento del Servicio o de cualquier sitio web relacionado.  No puedes utilizar una dirección de correo electrónico falsa, usar otra identidad que no sea legítima, o engañar a terceras partes o a nosotros en cuanto al origen de tus comentarios.  Tu eres el único responsable por los comentarios que haces y su precisión.  No nos hacemos responsables y no asumimos ninguna obligación con respecto a los comentarios publicados por ti o cualquier tercer parte."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMACIÓN PERSONAL"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu presentación de información personal a través del sitio se rige por nuestra Política de Privacidad. Para ver nuestra Política de Privacidad acceda a https://nitomeapp.com/es/privacy."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERRORES, INEXACTITUDES Y OMISIONES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De vez en cuando puede haber información en nuestro sitio o en el Servicio que contiene errores tipográficos, inexactitudes u omisiones que puedan estar relacionadas con las letras, promociones, ofertas etc. Nos reservamos el derecho de corregir los errores, inexactitudes u omisiones y de cambiar o actualizar la información o cancelar pedidos si alguna información en el Servicio o en cualquier sitio web relacionado es inexacta en cualquier momento sin previo aviso (incluso después de que hayas enviado tu orden) ."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No asumimos ninguna obligación de actualizar, corregir o aclarar la información en el Servicio o en cualquier sitio web relacionado, incluyendo, sin limitación, la información de precios, excepto cuando sea requerido por la ley. Ninguna especificación actualizada o fecha de actualización aplicada en el Servicio o en cualquier sitio web relacionado, debe ser tomada para indicar que toda la información en el Servicio o en cualquier sitio web relacionado ha sido modificado o actualizado."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USOS PROHIBIDOS"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En adición a otras prohibiciones como se establece en los Términos de Servicio, se prohibe el uso del sitio o su contenido: (a) para ningún propósito ilegal; (b) para pedirle a otros que realicen o participen en actos ilícitos; (c) para violar cualquier regulación, reglas, leyes internacionales, federales, provinciales o estatales, u ordenanzas locales; (d) para infringir o violar el derecho de propiedad intelectual nuestro o de terceras partes; (e) para acosar, abusar, insultar, dañar, difamar, calumniar, desprestigiar, intimidar o discriminar por razones de género, orientación sexual, religión, etnia, raza, edad, nacionalidad o discapacidad; (f) para presentar información falsa o engañosa; (g) para cargar o transmitir virus o cualquier otro tipo de código malicioso que sea o pueda ser utilizado en cualquier forma que pueda comprometer la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, otros sitios o Internet; (h) para recopilar o rastrear información personal de otros; (i) para generar spam, phish, pharm, pretext, spider, crawl, or scrape; (j) para cualquier propósito obsceno o inmoral; o (k) para interferir con o burlar los elementos de seguridad del Servicio o cualquier sitio web relacionado otros sitios o Internet. Nos reservamos el derecho de suspender el uso del Servicio o de cualquier sitio web relacionado por violar cualquiera de los ítems de los usos prohibidos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXCLUSIÓN DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No garantizamos ni aseguramos que el uso de nuestro servicio será ininterrumpido, puntual, seguro o libre de errores."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No garantizamos que los resultados que se puedan obtener del uso del servicio serán exactos o confiables."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas que de vez en cuando podemos quitar el servicio por períodos de tiempo indefinidos o cancelar el servicio en cualquier momento sin previo aviso."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas expresamente que el uso de, o la posibilidad de utilizar, el servicio es bajo tu propio riesgo.  El servicio y todos los productos y servicios proporcionados a través del servicio son (salvo lo expresamente manifestado por nosotros) proporcionados 'tal cual' y 'según esté disponible' para su uso, sin ningún tipo de representación, garantías o condiciones de ningún tipo, ya sea expresa o implícita, incluidas todas las garantías o condiciones implícitas de comercialización, calidad comercializable, la aptitud para un propósito particular, durabilidad, título y no infracción."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ningún caso Nitome App., nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, internos, proveedores, prestadores de servicios o licenciantes serán responsables por cualquier daño, pérdida, reclamo, o daños directos, indirectos, incidentales, punitivos, especiales o consecuentes de cualquier tipo, incluyendo, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo, o cualquier daño similar, ya sea basado en contrato, agravio (incluyendo negligencia), responsabilidad estricta o de otra manera, como consecuencia del uso de cualquiera de los servicios o productos adquiridos mediante el servicio, o por cualquier otro reclamo relacionado de alguna manera con el uso del servicio o cualquier producto, incluyendo pero no limitado, a cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurridos como resultados de la utilización del servicio o cualquier contenido (o producto) publicado, transmitido, o que se pongan a disposición a través del servicio, incluso si se avisa de su posibilidad.  Debido a que algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños consecuenciales o incidentales, en tales estados o jurisdicciones, nuestra responsabilidad se limitará en la medida máxima permitida por la ley."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDEMNIZACIÓN"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas indemnizar, defender y mantener indemne Nitome App. y nuestras matrices, subsidiarias, afiliados, socios, funcionarios, directores, agentes, internos y empleados, de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, hechos por cualquier tercero a causa o como resultado de tu incumplimiento de las Condiciones de Servicio o de los documentos que incorporan como referencia, o la violación de cualquier ley o de los derechos de u tercero."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIVISIBILIDAD"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el caso de que se determine que cualquier disposición de estas Condiciones de Servicio sea ilegal, nula o inejecutable, dicha disposición será, no obstante, efectiva a obtener la máxima medida permitida por la ley aplicable, y la parte no exigible se considerará separada de estos Términos de Servicio, dicha determinación no afectará la validez de aplicabilidad de las demás disposiciones restantes."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESCISIÓN"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las obligaciones y responsabilidades de las partes que hayan incurrido con anterioridad a la fecha de terminación sobrevivirán a la terminación de este acuerdo a todos los efectos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas Condiciones de servicio son efectivos a menos que y hasta que sea terminado por ti o nosotros. Puedes terminar estos Términos de Servicio en cualquier momento por avisarnos que ya no deseas utilizar nuestros servicios, o cuando dejes de usar nuestro sitio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si a nuestro juicio, fallas, o se sospecha que haz fallado, en el cumplimiento de cualquier término o disposición de estas Condiciones de Servicio, también podemos terminar este acuerdo en cualquier momento sin previo aviso, y seguirás siendo responsable de todos los montos adeudados hasta incluída la fecha de terminación; y/o en consecuencia podemos negar el acceso a nuestros servicios (o cualquier parte del mismo)."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACUERDO COMPLETO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra falla para ejercer o hacer valer cualquier derecho o disposiciôn de estas Condiciones de Servicio no constituirá una renuncia a tal derecho o disposición."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas Condiciones del servicio y las políticas o reglas de operación publicadas por nosotros en este sitio o con respecto al servicio constituyen el acuerdo completo y el entendimiento entre tú y nosotros y rigen el uso del Servicio y reemplaza cualquier acuerdo, comunicaciones y propuestas anteriores o contemporáneas, ya sea oral o escrita, entre tu y nosotros (incluyendo, pero no limitado a, cualquier versión previa de los Términos de Servicio)."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier ambigüedad en la interpretación de estas Condiciones del servicio no se interpretarán en contra del grupo de redacción."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMBIOS EN LOS TÉRMINOS DE SERVICIO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes revisar la versión más actualizada de los Términos de Servicio en cualquier momento en esta página."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos reservamos el derecho, a nuestra sola discreción, de actualizar, modificar o reemplazar cualquier parte de estas Condiciones del servicio mediante la publicación de las actualizaciones y los cambios en nuestro sitio web. Es tu responsabilidad revisar nuestro sitio web periódicamente para verificar los cambios. El uso contínuo de o el acceso a nuestro sitio Web o el Servicio después de la publicación de cualquier cambio en estas Condiciones de servicio implica la aceptación de dichos cambios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMACIÓN DE CONTACTO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas acerca de los Términos de Servicio deben ser enviadas a nitomeapp", "@", "gmail.com."])}
  ]
}