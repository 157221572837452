
import { Vue, Options } from 'vue-class-component';

@Options({
	//name: 'signin-modal',
	/* props: {
		showModal: Boolean
	}, */
	/* components: {
		Swiper,
		SwiperSlide,
	}, */
})
export default class SubscriptionPlans extends Vue {

	// translations
	public get actions_continue_free() { return this.$t('actions.continue_free') }
	public get actions_select_plan() { return this.$t('actions.select_plan') }
	public get labels_recommended() { return this.$t('labels.recommended') }
	public get labels_yearly() { return this.$t('labels.yearly') }
	public get labels_monthly() { return this.$t('labels.monthly') }

	selectPlan() {
		
	}
}
