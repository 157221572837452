export default {
  "meta": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practica Japonés y aprende con letras de canciones de tus artistas y bandas favoritos. Kanji, Katanaka, Hiragana y mucho vocabulario."])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitome App"])},
    "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["musica anime,aprender,idioma,online,en línea,gratis,música,vídeo,letra,canción,karaoke,jugar,juego,ejercicio,rellenar,espacios,blancos,huecos,inglés,español,japonés,romaji,japanese,aprender japones,japón,kanji,hiragana,katakana,letras en japones,writing system"])}
  },
  "page": {
    "home": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nitome"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de"])},
      "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
      "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])}
    },
    "titles": {
      "main": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Aprende Japonés de la forma divertida"])])},
      "sitedown": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Estaremos de vuelta en un momento para que sigas disfrutando de tus letras favoritas"])])},
      "lyrics": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Letras y Kanjis"])])},
      "lyrics_search": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Buscar letras"])])},
      "lyrics_editor": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Editor de letras"])])},
      "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevas"])},
      "home": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Dashboard"])])},
      "features": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Características"])])},
      "about": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nitome"]), _normalize(["Acerca de"])])}
    }
  },
  "messages": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 No Encontrado"])},
    "startnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar gratis ahora"])},
    "pha1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a Nitome. Una web para disfrutar tu musica favorita mientras estudias."])},
    "subpha1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estas aprendiendo Japones or simplemente amas Japon? viniste al lugar indicado"])},
    "pha2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantar canciones en Japones puede mejorar tu velocidad de lectura"])},
    "subpha2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovechemos que las letras pueden quedar en tu cabeza facilmente"])},
    "pha3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["escucha, reconoce e imita sonidos especificos"])},
    "subpha3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usando musica para ayudar construir tu vocabulario y memorizar palabras efectivamente"])},
    "pha4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sube de nivel tus habilidades de lectura y escucha"])},
    "subpha4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empieza a escuchar ahora gratis"])},
    "sitedown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sitio esta temporalmente deshabilitado. Volveremos pronto."])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio es solo para fines educativos e informativos. No somos propietarios de este contenido. Todos los créditos van a su legítimo propietario. Uso legítimo: esta herramienta está disponible públicamente en nuestros esfuerzos por promover el aprendizaje del idioma japonés. No se pretende ninguna infracción de derechos de autor."])},
    "lyrics_zyncronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letra sincronizada"])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron resultados. Intenta con otro criterio"])},
    "startexploring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar letras"])},
    "contact_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos. Cualquier sugerencia es bienvenida"])},
    "sponsor_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se un Sponsor de Nitome!"])},
    "sponsor_pha1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no eres sponsor tienes limites en nuestro sitio."])},
    "sponsor_pha2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes reproducir mas de 5 letras por hora."])},
    "sponsor_pha3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para seguir usando nuestro sitio sin limites, considere ser nuestro sponsor del proyecto."])},
    "sponsor_pha4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si ya eres un sponsor, ingresa con tus credenciales por favor."])},
    "loading_lyrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparando la letra. Espera un momento..."])},
    "just_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empece recientemente o tengo conocimiento muy basico."])},
    "mid_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He estado estudiando por algunos meses. Se los alfabetos y algunos Kanjis."])},
    "adv_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He estado estudiando por algunos años."])},
    "hard_topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Opcional) ¿Cuales han sido tus mayores retos al aprender Japones?"])},
    "letmeknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejanos tu correo electronico y te avisaremos cuando estemos en linea de nuevo."])},
    "join_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa el codigo para unirse a la clase."])},
    "under_construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio en construccion."])},
    "thanks_lmk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias. Estaremos en linea pronto."])}
  },
  "titles": {
    "select_marker_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el tipo de elemento"])},
    "scene_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración del escenario"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "congratulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felicidades"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial"])},
    "my_lyrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis letras"])},
    "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoritos"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
    "genres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generos"])},
    "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad"])},
    "review_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items a repasar"])},
    "current_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cual considerarias es tu nivel actual de Japones?"])},
    "level_modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mejoraremos tu experiencia segun tu seleccion."])},
    "lyrics_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador de Letras"])}
  },
  "labels": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grado"])},
    "strokes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trazos"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freq"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "band_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banda/Artista"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año lanzamiento"])},
    "youtube_video_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id Video Youtube"])},
    "youtube_video_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url del Video en Youtube"])},
    "input_youtube_url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia y pega la url del video en youtube aqui..."])},
    "verse_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de versos"])},
    "characters_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de caracteres"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])},
    "song_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título de la canción"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Contraseña"])},
    "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvidé mi contrase;a"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pais"])},
    "add_lyric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega la letra aqui"])},
    "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensual"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado"])},
    "recently_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregados recientemente"])},
    "top_anime_openings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Openings de Anime"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Políticas de Privacidad"])},
    "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redireccionando"])},
    "last_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitado por ultima vez"])},
    "first_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primera vez"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulo"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principiante"])},
    "enthusiast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entusiasta"])},
    "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzado"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse a una clase"])}
  },
  "actions": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saltar"])},
    "goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir atrás"])},
    "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproducir"])},
    "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detener"])},
    "maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximizar"])},
    "minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimizar"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesion"])},
    "existent_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tengo una cuenta"])},
    "send_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar para revisión"])},
    "go_next_verse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al siguiente verso"])},
    "go_prev_verse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al verso anterior"])},
    "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
    "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar"])},
    "delete_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar seleccionado"])},
    "move_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover a la derecha"])},
    "move_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover a la izquierda"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuir"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse con"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubrir"])},
    "karaoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karaoke"])},
    "veryeasy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy facil"])},
    "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facil"])},
    "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dificil"])},
    "challenging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retador"])},
    "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duro"])},
    "veryhard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy duro"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar"])},
    "no_thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, gracias"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a Inicio"])},
    "add_lyric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Letra"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reintentar"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la dificultad"])},
    "select_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar plan"])},
    "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar gratis"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar filtros"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "letmeknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avisame"])}
  },
  "tempo": {
    "very_slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["muy lento"])},
    "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lento"])},
    "moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderado"])},
    "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rapido"])},
    "very_fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["muy rapido"])}
  },
  "about": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de Nitome"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabemos que facil es sentirse abrumado estudiando Japones todos los dias."])},
    "p1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta web podras practicar Japones a traves de la musica con tus bandas y canciones favoritas. Busca una cancion en especifico o simplemente explora."])},
    "p1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si te unes, tendras acceso a un dashboard con estadisticas y podras monitorear tu progreso. Ademas desbloquearas mas funcionalidades y beneficios."])},
    "p1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practicaras gramatica, ortografía y pronunciacion."])},
    "p1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue practicando y diviertete"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué es el JLPT? (Japanese Language Proficiency Test)?"])},
    "p2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El JLPT tiene 5 niveles (N5-N1) con el objetivo de medir tu proficiencia en el lenguaje Japones, se centra en "])},
    "p2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectura y escucha"])},
    "p2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["principalmente. (no mide el habla ni escritura directamente)"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio web te ofrece el potencial de mejorar tu lectura, escucha y facilitarte el aprendizaje, puedes practicar cualquiera de estos niveles interactivamente."])}
  },
  "checks": {
    "username_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este nombre de usuario ya existe. Intenta uno diferente."])},
    "username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre de usuario es obligatorio."])},
    "username_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre de usuario debe ser entre 5 y 15 caracteres."])},
    "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correo electronico es obligatorio."])},
    "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correo electronico no tiene el formato correcto."])},
    "email_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correo electronico debe ser entre 3 y 50 caracteres."])},
    "pass_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contrase;a es obligatoria."])},
    "pass_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contrase;a debe ser entre 6 y 12 caracteres."])}
  },
  "privacy": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitome es una plataforma web que pone gratuitamente a disposicion letras de canciones para facilitar el aprendizaje del idioma."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿QUÉ HACEMOS CON TU INFORMACIÓN?"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando utilizas nuestro sitio web, nosotros recolectamos la información personal que nos das tales como nombre y correo electrónico."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando navegas en nuestro sitio, también recibimos de manera automática la dirección de protocolo de internet de tu computadora (IP) con el fin de proporcionarnos información que nos ayuda a conocer acerca de su navegador y sistema operativo para brindar una mejor experiencia."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con tu permiso, podremos enviarte correos electrónicos acerca de novedades, nuevas características y otras actualizaciones."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSENTIMIENTO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo obtener mi consentimiento?"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si te pedimos tu información personal por una razón secundaria, como marketing, te pediremos directamente tu expreso consentimiento, o te daremos la oportunidad de negarte."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo anular mi consentimiento?"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si luego de haber aceptado cambias de opinión, puedes anular tu consentimiento para que te contactemos, por la recolección, uso o divulgación de tu información, en cualquier momento, escribenos a nitomeapp", "@", "gmail.com."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIVULGACIÓN"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podemos divulgar tu información personal si se nos requiere por ley o si violas nuestros Términos de Servicio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICIOS DE TERCERAS PARTES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En general, los proveedores de terceras partes utilizados por nosotros solo recopilaran, usarán y divulgarán tu información en la medida que sea necesaria para que les permita desempeñar los servicios que nos proveen."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin embargo, algunos proveedores de servicios de terceros, tales como pasarelas de pago y otros procesadores de transacciones de pago, tienen sus propias políticas de privacidad con respecto a la información que estamos obligados a proporcionarles para las transacciones relacionadas con las subscripciones."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para estos proveedores, te recomendamos que leas las políticas de privacidad para que puedas entender la manera en que tu información personal será manejada."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En particular, recuerda que algunos proveedores pueden estar ubicados o contar con instalaciones que se encuentran en una jurisdicción diferente a ti o nosotros. Asi que si deseas proceder con una transacción que involucra los servicios de un proveedor a terceros, tu información puede estar sujeta a las leyes de la jurisdicción (jurisdicciones) en que se encuentra el proveedor de servicios o sus instalaciones."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez que abandonas el sitio web o te rediriges a un sitio o aplicación de terceros, ya no estás siendo regulados por la presente Política de Privacidad o los Términos de Servicio de nuestra página web."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando haces clic en un enlace, puede que seas redirigido fuera de nuestro sitio. No somos responsables por las prácticas de privacidad de otros sitios y te recomendamos leer sus normas de privacidad."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ningun caso almacenamos, modificamos, parcial o totalmente los videos transferidos por Youtube. Todos los videos sin excepcion que veras en nuestra plataforma son proveidos por la platagorma de videos de Youtube a traves de su reproductor."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGURIDAD"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para proteger tu información personal, tomamos precauciones razonables y seguimos las mejores prácticas de la industria para asegurarnos de que no haya pérdida de manera inapropiada, mal uso, acceso, divulgación, alteración o destrucción de la misma."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDAD DE CONSENTIMIENTO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al utilizar este sitio, declaras que tienes al menos la mayoría de edad en tu estado o provincia de residencia, y que nos has dado tu consentimiento para permitir que cualquiera de tus dependientes menores use este sitio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento, así que por favor revísala frecuentemente. Cambios y aclaraciones entrarán en vigencia inmediatamente después de su publicación en el sitio web. Si hacemos cambios materiales a esta política, notificaremos aquí que ha sido actualizada, por lo que cual estás enterado de qué información recopilamos, cómo y bajo qué circunstancias, si las hubiere, la utilizamos y/o divulgamos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PREGUNTAS E INFORMACIÓN DE CONTACTO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si quieres: acceder, corregir, enmendar o borrar cualquier información personal que poseamos sobre ti, registrar una queja, o simplemente quieres más información contactanos al correo electronico nitomeapp", "@", "gmail.com"])}
  ],
  "terms": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión: 1.0 - Octubre 2022"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÉRMINOS DE SERVICIO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMACIÓN GENERAL"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio web/app esta disponible en la direccion https://nitome.app. En todo el sitio, los términos “nosotros”, “nos” y “nuestro” se refieren a Nitome App. Nitome App. ofrece este sitio web/app, incluyendo toda la información, herramientas y servicios disponibles para ti en este sitio, el usuario, está condicionado a la aceptación de todos los términos, condiciones, políticas y notificaciones aquí establecidos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al visitar nuestro sitio y/o hacer uso de las funcionalidades, participas en nuestro “Servicio” y aceptas los siguientes términos y condiciones (“Términos de Servicio”, “Términos”), incluídos todos los términos y condiciones adicionales y las políticas a las que se hace referencia en el presente documento y/o disponible a través de hipervínculos. Estas Condiciones de Servicio se aplican a todos los usuarios del sitio, incluyendo sin limitación a usuarios que sean navegadores, y/o colaboradores de contenido."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, lee estos Términos de Servicio cuidadosamente antes de acceder o utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio, estás aceptando los Términos de Servicio. Si no estás de acuerdo con todos los términos y condiciones de este acuerdo, entonces no deberías acceder a la página web o usar cualquiera de los servicios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier función nueva o herramienta que se añadan al sitio web actual, también estarán sujetas a los Términos de Servicio. Puedes revisar la versión actualizada de los Términos de Servicio, en cualquier momento en esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los Términos de Servicio mediante la publicación de actualizaciones y/o cambios en nuestro sitio web. Es tu responsabilidad chequear esta página periódicamente para verificar cambios. Tu uso contínuo o el acceso al sitio web después de la publicación de cualquier cambio constituye la aceptación de dichos cambios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÉRMINOS DE SERVICIO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al utilizar este sitio, declaras que tienes al menos la mayoría de edad en tu estado o provincia de residencia, y que nos has dado tu consentimiento para permitir que cualquiera de tus dependientes menores use este sitio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes usar nuestros productos con ningún propósito ilegal o no autorizado tampoco puedes, en el uso del Servicio, violar cualquier ley en tu jurisdicción (incluyendo pero no limitado a las leyes de derecho de autor)."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No debes transmitir gusanos, virus o cualquier código de naturaleza destructiva."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El incumplimiento o violación de cualquiera de estos Términos darán lugar al cese inmediato de tus Servicios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONDICIONES GENERALES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos reservamos el derecho de rechazar la prestación de servicio a cualquier persona, por cualquier motivo y en cualquier momento."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entiendes que tu contenido, puede ser transferida sin encriptar e involucrar (a) transmisiones a través de varias redes; y (b) cambios para ajustarse o adaptarse a los requisitos técnicos de conexión de redes o dispositivos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás de acuerdo con no reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del Servicio, uso del Servicio, o acceso al Servicio o cualquier contacto en el sitio web a través del cual se presta el servicio, sin el expreso permiso por escrito de nuestra parte."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los títulos utilizados en este acuerdo se incluyen solo por conveniencia y no limita o afecta a estos Términos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXACTITUD, EXHAUSTIVIDAD Y ACTUALIDAD DE LA INFORMACIÓN"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No nos hacemos responsables si la información disponible en este sitio no es exacta, completa o actual. El material en este sitio es provisto solo para información general y no debe confiarse en ella o utilizarse como la única base para la toma de decisiones sin consultar primeramente, información más precisa, completa u oportuna. Cualquier dependencia en el materia de este sitio es bajo su propio riesgo."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio puede contener cierta información histórica. La información histórica, no es necesariamente actual y es provista únicamente para tu referencia. Nos reservamos el derecho de modificar los contenidos de este sitio en cualquier momento, pero no tenemos obligación de actualizar cualquier información en nuestro sitio. Aceptas que es tu responsabilidad de monitorear los cambios en nuestro sitio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MODIFICACIONES AL SERVICIO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte del contenido) en cualquier momento sin aviso previo."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No seremos responsables ante ti o alguna tercera parte por cualquier modificación, suspensión o discontinuidad del Servicio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HERRAMIENTAS OPCIONALES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es posible que te proporcionamos acceso a herramientas de terceros a los cuales no monitoreamos y sobre los que no tenemos control ni entrada."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconoces y aceptas que proporcionamos acceso a este tipo de herramientas 'tal cual' y 'según disponibilidad' sin garantías, representaciones o condiciones de ningún tipo y sin ningún respaldo. No tendremos responsabilidad alguna derivada de o relacionada con tu uso de herramientas proporcionadas por terceras partes."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier uso que hagas de las herramientas opcionales que se ofrecen a través del sitio bajo tu propio riesgo y discreción y debes asegurarte de estar familiarizado y aprobar los términos bajo los cuales estas herramientas son proporcionadas por el o los proveedores de terceros."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También es posible que, en el futuro, te ofrezcamos nuevos servicios y/o características a través del sitio web (incluyendo el lanzamiento de nuevas herramientas y recursos). Estas nuevas características y/o servicios también estarán sujetos a estos Términos de Servicio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENLACES DE TERCERAS PARTES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierto contenido, productos y servicios disponibles vía nuestro Servicio puede incluir material de terceras partes."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlaces de terceras partes en este sitio pueden redireccionarse a sitios web de terceras partes que no están afiliadas con nosotros. No nos responsabilizamos de examinar o evaluar el contenido o exactitud y no garantizamos ni tendremos ninguna obligación o responsabilidad por cualquier material de terceros o sitios web, o de cualquier material, productos o servicios de terceros."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No nos hacemos responsables de cualquier daño o daños relacionados con la adquisición o utilización de bienes, servicios, recursos, contenidos, o cualquier otra transacción realizadas en conexión con sitios web de terceros. Por favor revisa cuidadosamente las políticas y prácticas de terceros y asegúrate de entenderlas antes de participar en cualquier transacción. Quejas, reclamos, inquietudes o preguntas con respecto a productos de terceros deben ser dirigidas a la tercera parte."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMENTARIOS DE USUARIO, CAPTACIÓN Y OTROS ENVÍOS"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si, a pedido nuestro, envías ciertas presentaciones específicas (por ejemplo la participación en concursos) o sin un pedido de nuestra parte envías ideas creativas, sugerencias, proposiciones, planes, u otros materiales, ya sea en línea, por email, por correo postal, o de otra manera (colectivamente, 'comentarios'), aceptas que podamos, en cualquier momento, sin restricción, editar, copiar, publicar, distribuir, traducir o utilizar por cualquier medio comentarios que nos hayas enviado. No tenemos ni tendremos ninguna obligación (1) de mantener ningún comentario confidencialmente; (2) de pagar compensación por comentarios; o (3) de responder a comentarios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros podemos, pero no tenemos obligación de, monitorear, editar o remover contenido que consideremos sea ilegítimo, ofensivo, amenazante, calumnioso, difamatorio, pornográfico, obsceno u objetable o viole la propiedad intelectual de cualquiera de las partes o los Términos de Servicio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas que tus comentarios no violará los derechos de terceras partes, incluyendo derechos de autor, marca, privacidad, personalidad u otro derechos personal o de propiedad. Asimismo, aceptas que tus comentarios no contienen material difamatorio o ilegal, abusivo u obsceno, o contienen virus informáticos u otro malware que pudiera, de alguna manera, afectar el funcionamiento del Servicio o de cualquier sitio web relacionado. No puedes utilizar una dirección de correo electrónico falsa, usar otra identidad que no sea legítima, o engañar a terceras partes o a nosotros en cuanto al origen de tus comentarios. Tu eres el único responsable por los comentarios que haces y su precisión. No nos hacemos responsables y no asumimos ninguna obligación con respecto a los comentarios publicados por ti o cualquier tercer parte."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMACIÓN PERSONAL"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu presentación de información personal a través del sitio se rige por nuestra Política de Privacidad. Para ver nuestra Política de Privacidad acceda a https://nitomeapp.com/es/privacy."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERRORES, INEXACTITUDES Y OMISIONES"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De vez en cuando puede haber información en nuestro sitio o en el Servicio que contiene errores tipográficos, inexactitudes u omisiones que puedan estar relacionadas con las letras, promociones, ofertas etc. Nos reservamos el derecho de corregir los errores, inexactitudes u omisiones y de cambiar o actualizar la información o cancelar pedidos si alguna información en el Servicio o en cualquier sitio web relacionado es inexacta en cualquier momento sin previo aviso (incluso después de que hayas enviado tu orden) ."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No asumimos ninguna obligación de actualizar, corregir o aclarar la información en el Servicio o en cualquier sitio web relacionado, incluyendo, sin limitación, la información de precios, excepto cuando sea requerido por la ley. Ninguna especificación actualizada o fecha de actualización aplicada en el Servicio o en cualquier sitio web relacionado, debe ser tomada para indicar que toda la información en el Servicio o en cualquier sitio web relacionado ha sido modificado o actualizado."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USOS PROHIBIDOS"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En adición a otras prohibiciones como se establece en los Términos de Servicio, se prohibe el uso del sitio o su contenido: (a) para ningún propósito ilegal; (b) para pedirle a otros que realicen o participen en actos ilícitos; (c) para violar cualquier regulación, reglas, leyes internacionales, federales, provinciales o estatales, u ordenanzas locales; (d) para infringir o violar el derecho de propiedad intelectual nuestro o de terceras partes; (e) para acosar, abusar, insultar, dañar, difamar, calumniar, desprestigiar, intimidar o discriminar por razones de género, orientación sexual, religión, etnia, raza, edad, nacionalidad o discapacidad; (f) para presentar información falsa o engañosa; (g) para cargar o transmitir virus o cualquier otro tipo de código malicioso que sea o pueda ser utilizado en cualquier forma que pueda comprometer la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, otros sitios o Internet; (h) para recopilar o rastrear información personal de otros; (i) para generar spam, phish, pharm, pretext, spider, crawl, or scrape; (j) para cualquier propósito obsceno o inmoral; o (k) para interferir con o burlar los elementos de seguridad del Servicio o cualquier sitio web relacionado otros sitios o Internet. Nos reservamos el derecho de suspender el uso del Servicio o de cualquier sitio web relacionado por violar cualquiera de los ítems de los usos prohibidos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXCLUSIÓN DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No garantizamos ni aseguramos que el uso de nuestro servicio será ininterrumpido, puntual, seguro o libre de errores."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No garantizamos que los resultados que se puedan obtener del uso del servicio serán exactos o confiables."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas que de vez en cuando podemos quitar el servicio por períodos de tiempo indefinidos o cancelar el servicio en cualquier momento sin previo aviso."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas expresamente que el uso de, o la posibilidad de utilizar, el servicio es bajo tu propio riesgo. El servicio y todos los productos y servicios proporcionados a través del servicio son (salvo lo expresamente manifestado por nosotros) proporcionados 'tal cual' y 'según esté disponible' para su uso, sin ningún tipo de representación, garantías o condiciones de ningún tipo, ya sea expresa o implícita, incluidas todas las garantías o condiciones implícitas de comercialización, calidad comercializable, la aptitud para un propósito particular, durabilidad, título y no infracción."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ningún caso Nitome App., nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, internos, proveedores, prestadores de servicios o licenciantes serán responsables por cualquier daño, pérdida, reclamo, o daños directos, indirectos, incidentales, punitivos, especiales o consecuentes de cualquier tipo, incluyendo, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo, o cualquier daño similar, ya sea basado en contrato, agravio (incluyendo negligencia), responsabilidad estricta o de otra manera, como consecuencia del uso de cualquiera de los servicios o productos adquiridos mediante el servicio, o por cualquier otro reclamo relacionado de alguna manera con el uso del servicio o cualquier producto, incluyendo pero no limitado, a cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurridos como resultados de la utilización del servicio o cualquier contenido (o producto) publicado, transmitido, o que se pongan a disposición a través del servicio, incluso si se avisa de su posibilidad.  Debido a que algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños consecuenciales o incidentales, en tales estados o jurisdicciones, nuestra responsabilidad se limitará en la medida máxima permitida por la ley."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDEMNIZACIÓN"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas indemnizar, defender y mantener indemne Nitome App. y nuestras matrices, subsidiarias, afiliados, socios, funcionarios, directores, agentes, internos y empleados, de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, hechos por cualquier tercero a causa o como resultado de tu incumplimiento de las Condiciones de Servicio o de los documentos que incorporan como referencia, o la violación de cualquier ley o de los derechos de u tercero."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIVISIBILIDAD"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el caso de que se determine que cualquier disposición de estas Condiciones de Servicio sea ilegal, nula o inejecutable, dicha disposición será, no obstante, efectiva a obtener la máxima medida permitida por la ley aplicable, y la parte no exigible se considerará separada de estos Términos de Servicio, dicha determinación no afectará la validez de aplicabilidad de las demás disposiciones restantes."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESCISIÓN"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las obligaciones y responsabilidades de las partes que hayan incurrido con anterioridad a la fecha de terminación sobrevivirán a la terminación de este acuerdo a todos los efectos."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas Condiciones de servicio son efectivos a menos que y hasta que sea terminado por ti o nosotros. Puedes terminar estos Términos de Servicio en cualquier momento por avisarnos que ya no deseas utilizar nuestros servicios, o cuando dejes de usar nuestro sitio."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si a nuestro juicio, fallas, o se sospecha que haz fallado, en el cumplimiento de cualquier término o disposición de estas Condiciones de Servicio, también podemos terminar este acuerdo en cualquier momento sin previo aviso, y seguirás siendo responsable de todos los montos adeudados hasta incluída la fecha de terminación; y/o en consecuencia podemos negar el acceso a nuestros servicios (o cualquier parte del mismo)."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACUERDO COMPLETO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra falla para ejercer o hacer valer cualquier derecho o disposiciôn de estas Condiciones de Servicio no constituirá una renuncia a tal derecho o disposición."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas Condiciones del servicio y las políticas o reglas de operación publicadas por nosotros en este sitio o con respecto al servicio constituyen el acuerdo completo y el entendimiento entre tú y nosotros y rigen el uso del Servicio y reemplaza cualquier acuerdo, comunicaciones y propuestas anteriores o contemporáneas, ya sea oral o escrita, entre tu y nosotros (incluyendo, pero no limitado a, cualquier versión previa de los Términos de Servicio)."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier ambigüedad en la interpretación de estas Condiciones del servicio no se interpretarán en contra del grupo de redacción."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMBIOS EN LOS TÉRMINOS DE SERVICIO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes revisar la versión más actualizada de los Términos de Servicio en cualquier momento en esta página."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos reservamos el derecho, a nuestra sola discreción, de actualizar, modificar o reemplazar cualquier parte de estas Condiciones del servicio mediante la publicación de las actualizaciones y los cambios en nuestro sitio web. Es tu responsabilidad revisar nuestro sitio web periódicamente para verificar los cambios. El uso contínuo de o el acceso a nuestro sitio Web o el Servicio después de la publicación de cualquier cambio en estas Condiciones de servicio implica la aceptación de dichos cambios."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMACIÓN DE CONTACTO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas acerca de los Términos de Servicio deben ser enviadas a nitomeapp", "@", "gmail.com."])}
  ]
}