import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto about-section" }
const _hoisted_2 = { class: "bg-white p-4 m-4 md:p-8 md:m-8 lg:p-24 lg:m-24" }
const _hoisted_3 = { class: "flex justify-center items-center p-4" }
const _hoisted_4 = {
  class: "w-full max-w-xs flex flex-col py-5 px-8",
  action: ""
}
const _hoisted_5 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}
const _hoisted_6 = { class: "text-red-600" }
const _hoisted_7 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}
const _hoisted_8 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}
const _hoisted_9 = { class: "text-red-600" }
const _hoisted_10 = {
  class: "text-gray-700 font-bold py-2",
  for: ""
}
const _hoisted_11 = { class: "text-red-600" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclaimer = _resolveComponent("Disclaimer")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("form", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.labels_email), 1),
            _withDirectives(_createElementVNode("input", {
              class: "text-gray-700 shadow border border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3 rounded-full",
              type: "email",
              placeholder: "Required",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.datasignup.email) = $event)),
              required: "",
              minlength: "3",
              onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkEmail && _ctx.checkEmail(...args)))
            }, null, 544), [
              [_vModelText, _ctx.datasignup.email]
            ]),
            _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.formValidations.email), 1),
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.labels_firstname), 1),
            _withDirectives(_createElementVNode("input", {
              class: "text-gray-700 shadow border border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3 rounded-full",
              type: "text",
              placeholder: "Optional",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.datasignup.firstname) = $event))
            }, null, 512), [
              [_vModelText, _ctx.datasignup.firstname]
            ]),
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.labels_username), 1),
            _withDirectives(_createElementVNode("input", {
              class: "text-gray-700 shadow border border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3 rounded-full",
              type: "text",
              placeholder: "Username",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.datasignup.username) = $event)),
              required: "",
              minlength: "3",
              onKeyup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.checkUsername && _ctx.checkUsername(...args)))
            }, null, 544), [
              [_vModelText, _ctx.datasignup.username]
            ]),
            _createElementVNode("small", _hoisted_9, _toDisplayString(_ctx.formValidations.username), 1),
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.labels_password), 1),
            _withDirectives(_createElementVNode("input", {
              class: "text-gray-700 shadow border border-gray-300 mb-3 py-1 px-3 rounded-full focus:outline-none focus:shadow-outline",
              type: "password",
              placeholder: "********",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.datasignup.password) = $event)),
              required: "",
              minlength: "6",
              onKeyup: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.checkPassword && _ctx.checkPassword(...args)))
            }, null, 544), [
              [_vModelText, _ctx.datasignup.password]
            ]),
            _createElementVNode("small", _hoisted_11, _toDisplayString(_ctx.formValidations.password), 1),
            _createElementVNode("button", {
              class: "btn navbar-toggler link-medium mt-4 mb-2",
              onClick: _cache[7] || (_cache[7] = async ()=> await _ctx.signup()),
              disabled: !_ctx.isFormValid
            }, _toDisplayString(_ctx.actions_signup), 9, _hoisted_12)
          ])
        ])
      ]),
      _createVNode(_component_Disclaimer)
    ]),
    _createVNode(_component_Footer)
  ], 64))
}