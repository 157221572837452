
    import { Options, Vue } from 'vue-class-component';
    import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
    import "swiper/swiper.scss";

    @Options({
        components: {
            Swiper, SwiperSlide
        },
        emits: ['selected']
    })
    export default class SliderLevelCards extends Vue {

        items: any[] = [];

        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints={
            // when window width is >= 320px
            320: {
                slidesPerView: 3,
                spaceBetween: 8
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3,
                spaceBetween: 10
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 10
            },
            1020: {
                slidesPerView: 3,
                spaceBetween: 10
            }
        }

        mounted() {
            this.items = [
                {id: 1, level: this.$t('labels.beginner'), description: this.$t('messages.just_started') },
                {id: 2, level: this.$t('labels.enthusiast'), description: this.$t('messages.mid_level') },
                {id: 3, level: this.$t('labels.advanced'), description: this.$t('messages.adv_level') }
            ];
        }

        goToLyric(item) {
            this.items.forEach(el => el.isSelected = false);
            item.isSelected = true;
            this.$emit('selected', item.id);
        }
    }
