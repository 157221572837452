
    import { Vue, Options } from 'vue-class-component';
    import service from "@/services/api";
    import store from "@/stores";
    import Disclaimer from '@/lib/SiteLayout/Disclaimer/index.vue';
    import AnalyticsUtils from "@/services/analytics";

    @Options({
        components: {
            Disclaimer
        },
    }) 
    export default class Redirect extends Vue {

        //public get redirect () { return this.$t('label.redirect') }
        // translations
        public get labels_redirecting () { return this.$t('labels.redirecting') }
        public get message_redirecting () { return this.$t('message.redirecting') }

        async created() {
            AnalyticsUtils.visit(this.$route);
                   
            const code = this.$route.query.code;

            // validate the received code and get token on the backend.
            //console.log("OAuth CODE: " + code);

            if (code) {
                // save token
                const token = JSON.parse(await service.fetchFromApi({url: "oauth/validatecode?code=" + code}));
                console.log("OAuth TOKEN: " + JSON.stringify(token));

                // check if this user is an active patreon, otherwise redirect to the memberships selection.
                // TODO: figure it out how to refresh token with new subscriber claim after successful patreon subscription
                store.setNewToken(token);

               /*  store.rawData = data;
                this.securityData = data;
                localStorage.setItem("ntmData", JSON.stringify(this.securityData));
                this.decodeToken(); */
                
                //store.decodeToken();

                // This is what we get back from Patreon after become-patreon flow is complete, get the locked content url and redirect
                // this process is happening in the Header.public.vue join link
                const base64EncodedState = this.$route.query.state;
                console.log(base64EncodedState);

                if (base64EncodedState) {
                    // When you receive it back, decode it in reverse of the below order - urldecode, base64_decode, json_decode (as array)
                    let state_parameters = decodeURIComponent( base64EncodedState + '' ); // eyJmaW5hbF9yZWRpcmVjdCI6Imh0dHA6XC9cL215ZG9tYWluLmNvbVwvbG9ja2VkLWNvbnRlbnQifQ==
                    const base64DecodedState = atob( state_parameters );
                    const jsonEncodedState = JSON.parse( base64DecodedState );
                    console.log(jsonEncodedState); // {"final_redirect":"http:\/\/mydomain.com\/locked-content"}

                    // TODO: redirect here...
                    
                    this.$router.push({ path: jsonEncodedState.final_redirect });

                }
            }

            setTimeout(() => {
                store.isLoadingRoute = false;
            }, 500);
        }
    }
