import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0de30b5f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container centered" }
const _hoisted_2 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclaimer = _resolveComponent("Disclaimer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.messages_waiting_countdown), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.messages_sponsor_title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.sponsor_pha1), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.sponsor_pha2), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.sponsor_pha3), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.sponsor_pha4), 1),
      _createElementVNode("button", {
        class: "btn navbar-toggler btn-medium mt-4",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToDiscover && _ctx.goToDiscover(...args)))
      }, _toDisplayString(_ctx.actions_retry), 1)
    ]),
    _createVNode(_component_Disclaimer)
  ], 64))
}