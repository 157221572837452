
import { Vue, Options } from 'vue-class-component';
import CardGrid from '@/lib/CardGrid/card_grid.vue';
import store from "@/stores";
import service from "@/services/api";
import Analytics from "@/services/analytics";

@Options({
    components: {
        CardGrid
    },
})
export default class DashboardHistory extends Vue {

    items

    created() {
        Analytics.visit(this.$route);
    }
    
    async mounted() {

        this.items = JSON.parse(await service.fetchFromApi({ url: "dashboard/getHistory" }));

        setTimeout(() => {
            store.isLoadingRoute = false;
            store.isLoading = false;
        }, 1000);
    }
}
