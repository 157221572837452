
    import { Vue } from 'vue-class-component';
    import service from "@/services/api";

    export default class Contact extends Vue {
        
        data: any = {}

        // translations
        public get title_contact () { return this.$t('titles.contact') }
        public get labels_subject () { return this.$t('labels.subject') }
        public get labels_email () { return this.$t('labels.email') }
        public get labels_body () { return this.$t('labels.message') }
        public get actions_send () { return this.$t('actions.send') }
        public get messages_contact () { return this.$t('messages.contact_form') }

        async sendForm() {

            await service.postToApi("lyrics/endPlaySession", { body: this.data } )
            this.data = {}

        }
    }
